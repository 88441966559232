import { Injectable } from '@angular/core';
import { Installation } from '../db/installation';
import { WSService } from 'projects/core/src/include/service'
import { Observable } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
import { Filter, FilterAdvanced, Ordering } from 'projects/core/src/include/structures';

@Injectable({
  providedIn: 'root'
})

export class InstallationsService extends WSService<Installation> {

  constructor() {super("installations") }

  getSN(sn:string):Observable<Installation[]>{
    return Globals.ws.requestUrl<Installation[]>("installations","getItems",["(p.sn='"+sn+"' OR p.matricola='"+sn+"' OR p.code='"+sn+"') AND p.status=1"]);
  }

  getItemsByIDS(ids:any[]):Observable<Installation[]>{
    return Globals.ws.requestUrl<Installation[]>("installations","getItems",["p.id IN ("+ids.join(",")+")"]);
  }

  getItemsByAddress(id_address:number):Observable<Installation[]>{
    return Globals.ws.requestUrl<Installation[]>("installations","getItemsByAddress",[id_address.toString()]);
  }

  getItemsByLocation(LatNW,LatSE,LonNW,LonSE,filter:Filter[]=[],ordering:Ordering[]=[],filter_advanced:FilterAdvanced[]=[]):Observable<Installation[]>{
    let w=this.getSQLWhere(filter,ordering,filter_advanced);
    return Globals.ws.requestUrl<Installation[]>("installations","getItemsByLocation",[LatNW.toString(),LatSE.toString(),LonNW.toString(),LonSE.toString(),w[0]],true,0,20);
  
  }

  linkQRCodeZip(filename:string):Observable<object>{
    return Globals.ws.requestUrl<object>("installations","linkQRCodeZip",[filename]);
    
  }

  getInstallationByShop(id_shop:number,id_address:number):Observable<Installation[]>{
    return Globals.ws.requestUrl<Installation[]>("installations","getInstallationByShop",[id_shop.toString(),id_address.toString()]);
  
  }

  getInstallationByCategory(id_category:number):Observable<Installation[]>{
    return Globals.ws.requestUrl<Installation[]>("installations","getInstallationByCategory",[id_category.toString()]);
  
  }

  moveInstallation(id_installation,from,to, causal,note):Observable<boolean>{
    return Globals.ws.requestUrl<boolean>("installations","moveInstallation",[id_installation.toString(),from.toString(),to.toString(),causal.toString(),note]);
  }

  saveHistory(item,oncomplete){
    return Globals.ws.send("installations","saveHistory",item,[],oncomplete);
  }
  deleteHistory(id):Observable<boolean>{
    return Globals.ws.requestUrl<boolean>("installations","deleteHistory",[id.toString()]);
 
  }
  /*
  addInstallationToShop(id_installation,id_shop):Observable<boolean>{
    return Globals.ws.requestUrl<boolean>("installations","addInstallationToShop",[id_installation.toString(),id_shop.toString()]);

  }*/

  getActivitiesByProduct(id_activity_type):Observable<any>{
    return Globals.ws.requestUrl<boolean>("installations","getActivitiesByProduct",[id_activity_type]);
 
  }

  prepareImportvalue(value,id_customfield,oncomplete){
    Globals.ws.send("installations","prepareImportvalue",[value],[id_customfield],oncomplete);
 
  }

  updateImportvalue(value,id_customfield,oncomplete){
    Globals.ws.send("installations","updateImportvalue",[value],[id_customfield],oncomplete);
 
  }

  getAnalytics():Observable<any>{
    return Globals.ws.requestUrl<any>("installations","getAnalytics",[]);
 
  }
  
}
