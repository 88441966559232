import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldsComponent } from './fields.component';
import { FormsModule } from '@angular/forms';
import { FieldComponent } from './field/field.component';
import { UploadFileModule } from 'projects/core/src/common/upload-file/upload-file.module';
import { TakephotoModule } from 'projects/core/src/common/takephoto/takephoto.module';



@NgModule({
  declarations: [
    FieldsComponent,
    FieldComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    UploadFileModule,
    TakephotoModule
  ],
  exports:[
    FieldsComponent,
    FieldComponent
  ]
})
export class FieldsModule { }
