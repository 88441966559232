<app-windowlist [model]="this">
    <div class="card p-2">
        <table class="table">
            <thead>
                <th style="border:none"><i class="text-light fas fa-6x fa-chart-line"></i></th>
                <th style="border:none" class="text-center pb-0" *ngFor="let t of list['types']">
                    <h4 *ngIf="t.total>799" class="bg-primary text-center" style="margin: 0 auto;max-width:60px;height:400px">{{t.total}}</h4>
                    <h4 *ngIf="t.total>49 && t.total<800" class="bg-primary text-center" style="margin: 0 auto;max-width:60px;height:{{t.total/2}}px">{{t.total}}</h4>
                    <h4 *ngIf="t.total<50" class="text-center text-primary" style="margin: 0 auto;">{{t.total}}</h4>
                </th>
            </thead>
            <thead class="bg-light">
                <th>Categoria</th>
                <th class="text-center" *ngFor="let t of list['types']">{{t.name}}</th>
            </thead>
            <tr *ngFor="let r2 of list['categories']">
                <td>
                    <ng-container *ngIf="r2.category">
                        {{r2.category}}
                    </ng-container>
                    <ng-container *ngIf="!r2.category">
                        senza categoria
                    </ng-container>
                </td>
                <td class="text-center" *ngFor="let v of r2.values">{{v.total}}</td>
            </tr>
        </table>
    </div>
</app-windowlist>