<app-windowlist [model]="this">
    <div class="card">
            <form>
            <table class="table table-sm table-striped text-nowrap" >
                <thead>
                    <th>Nome</th>
                    <th>Oggetto</th>
                    <th><button (click)="add()"  title="Aggiungi" data-toggle="modal" data-target="#modalEdit"  class="btn btn-success btn-xs float-right mr-1"><i class="fas fa-plus"></i></button></th>
                </thead>
                <tbody>

                    <!-- elemento ripetuto -->
                    <tr *ngFor="let record of list">
                        <td>{{record.name}}</td>
                        <td><small><i>{{record.subject}}</i></small></td>
                        <td class="pr-2">
                            <button  type="button"  title="Elimina" class="btn btn-danger btn-xs float-right ml-1" (click)="confirm_delete(record.id)"><i class="fas fa-trash"></i></button>
                            <button type="button" class="btn btn-secondary btn-xs float-right ml-1" data-toggle="modal" data-target="#modalEdit" (click)="setRecord(record)"><i class="fas fa-pencil-alt"></i></button>
                        </td>
                    </tr>

                </tbody>
            </table>
            </form>
            <div class="card-footer clearfix py-1">
            <small class="mr-1">Visualizzati <b class="text-primary" style="font-size: 110%;">{{recordcount}}</b> elementi</small>
        </div>
    </div>
</app-windowlist>

<div class="modal  fade" id="modalEdit" tabindex="-1" role="dialog" >
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content ">
            <div class="modal-header">
                <b>Impostazione email</b>
            </div>
            <div class="modal-body" *ngIf="selectedRecord">
                <div class="row">
                    <label class="col-sm-2 col-form-label">Nome</label>
                    <div class="col-sm-10">
                        <input type="text" [readonly] class="form-control form-control-sm" name="name"  [(ngModel)]="selectedRecord.name" >
                    </div>
                    <label class="col-sm-2 col-form-label">Da</label>
                    <div class="input-group col-sm-10">
                        <input type="email" class="form-control form-control-sm" name="address_from"  [(ngModel)]="selectedRecord.address_from" >
                    </div>
                    <label class="col-sm-2 col-form-label">CC</label>
                    <div class="input-group col-sm-10">
                        <input type="email" class="form-control form-control-sm" name="address_cc"  [(ngModel)]="selectedRecord.address_cc" >
                    </div>
                    <label class="col-sm-2 col-form-label">Oggetto</label>
                    <div class="input-group col-sm-10">
                        <input type="text" class="form-control form-control-sm" name="subject"  [(ngModel)]="selectedRecord.subject" >
                    </div>
                    <label class="col-md-2 col-form-label">Messaggio</label>
                    <div class="input-group col-md-10">
                        <ckeditor *ngIf="editorOn" [config]="{allowedContent:true}" tagName="textarea" [editor]="Editor" [(ngModel)]="selectedRecord.body"></ckeditor>
                        <textarea *ngIf="!editorOn" rows="14" class="form-control" name="body" [(ngModel)]="selectedRecord.body" ></textarea>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-10"><button class="btn btn-secondary btn-xs mt-1" [checked]="editorOn" (click)="editorOn=!editorOn"><i class="fas fa-code mr-1"></i>Editor</button></div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal" (click)="confirmRecord()"><i class="fas fa-save mr-1"></i>Conferma</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"><i class="fas fa-times mr-2"></i>Annulla</button>
            </div>
        </div>
    </div>
</div>