import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'projects/core/src/authGuard';
import { ShopsComponent } from './shops.component';
import { ShopsDetailsComponent } from './shops-details/shops-details.component';


const routes: Routes = [
  { path: '', component: ShopsComponent,canActivate:[AuthGuard] },
  {path: 'shops-details/:id', component: ShopsDetailsComponent,canActivate:[AuthGuard]},
  {path: 'shops-details/:id/:id_address', component: ShopsDetailsComponent,canActivate:[AuthGuard]},];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopsRoutingModule { }
