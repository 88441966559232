import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Model } from 'projects/core/src/include/model';
import { Form, FormField } from 'projects/core/src/include/structures';
import { FormsService } from 'projects/core/src/lib/forms.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {arrayMoveImmutable} from 'array-move';
import { TemplatesService } from 'projects/core/src/lib/templates.service';
import { NotificationService } from 'projects/c1-backend/src/app/services/notification.service';
@Component({
  selector: 'lib-form-detail',
  templateUrl: './form-detail.component.html',
  styleUrls: ['./form-detail.component.css']
})
export class FormSettingDetailComponent extends Model<Form> implements OnInit {
  
  public EditorHeader = ClassicEditor;
  public EditorFooter = ClassicEditor;
  editorOnHeader:boolean=true;
  editorOnFooter:boolean=true;

  templates:any[]=[];
  notifications:any[]=[];
  constructor(
    private route1: ActivatedRoute,
    private formsService: FormsService,
    private templatesService:TemplatesService,
    private notificationService:NotificationService

    ) {
      super(route1,formsService); 
    
    
  }

  tabsection=0;

  ngOnInit(): void {

    this.title = 'Form Dettaglio';
    
    super.ngOnInit.apply(this, arguments); 

    this.templatesService.getAllTemplates().subscribe((items)=>{
      this.templates=items;
    })

    this.notificationService.getAllNotifications().subscribe((items)=>{
      this.notifications=items;
    })

    this.getItem();
  }


  addField(){
    if(!this.record.fields){
      this.record.fields=[];
    }
    let f=new FormField();
    f.id_parent=this.tabsection;

    this.record.fields.push(f);
    if(this.record.sections[this.tabsection].fields==null || this.record.sections[this.tabsection].fields == undefined){
      this.record.sections[this.tabsection].fields=[];
    }
    this.record.sections[this.tabsection].fields.push(f);
    


  }

  moveField(field,direction="up"){

    for(let i=0;i<this.record.fields.length;i++){
     
      if(field==this.record.fields[i]){
        if(direction=="up"){
          if(i>0)
            this.record.fields = arrayMoveImmutable(this.record.fields, i, i-1);
            
        }
        else
          if(i<this.record.fields.length-1)
            this.record.fields = arrayMoveImmutable(this.record.fields, i, i+1);
        
        return;
      }
      
    }

  }


  removeField(field){
    for(let i=0;i<this.record.fields.length;i++){
     
      if(field==this.record.fields[i]){
        this.record.fields.splice(i,1);
        return;
      }
    }
  }

  array_move(arr, old_index, new_index) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
  }
  
  
  addSection(){
    if(!this.record.sections){
      this.record.sections=[];
    }

    if(!this.record.fields){
      this.record.fields=[];
    }
    
    let name_section=prompt("Inserisci il nome della sezione","");

    let s:FormField=new FormField();
    s.id=0;
    s.type="section";
    s.name=name_section;
    s.label=name_section;
    
    this.record.fields.push(s);
    this.record.sections.push(s);
    

  }

  moveToSection(r){
    for(let section of this.record.sections){
      if(section.id==r.id_parent){
        section.fields.push(r);
      }
    }

    for(let section of this.record.sections){
      for(let i=0;i<section.fields.length;i++){
        if(section.fields[i]==r && section.id!=r.id_parent){
          section.fields.splice(i,1);
          return;
        }
      }
    }
    
  }
}
