import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Observable, Observer } from 'rxjs';
import { MethodPayment } from '../db/methodpayment';

@Injectable({
  providedIn: 'root'
})
export class MethodpaymentsService extends WSService<MethodPayment> {

  public static methods=[];

  constructor() { super("methodpayments")}

  getMethods():Observable<any[]>{
    
    
    if(MethodpaymentsService.methods.length==0){
      Globals.ws.requestUrl("methodpayments","getItems",[],true,0,999999).subscribe((items:any)=>{
        MethodpaymentsService.methods=items;
      });
      return Globals.ws.requestUrl("methodpayments","getItems",[]);

    }else{

      function sequenceSubscriber(observer: Observer<any[]>) {
        observer.next(MethodpaymentsService.methods);
        observer.complete();
        return {unsubscribe() {}};
      }
      
        return new Observable(sequenceSubscriber);
    }
      
    }

    

    //return Globals.ws.requestUrl("methodpayments","getItems",[]);
  
}
