<app-windowlist [model]="this">
    <div class="row my-1">
        <div class="col-lg-2 pr-md-0">
            <small>
                <app-filterdate [date_type]="100" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="updateList()"></app-filterdate>
            </small>
        </div>
        <div class="col-lg-10 pr-md-0">
            <div class="card card-outline">
                <div class="p-0">
                    <div class="row">
                        <div class="col-10"></div>
                        <div class="col-2" *ngIf="mode!='modal'">
                            <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="far fa-trash-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  title="Rirpistina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-undo"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  title="Aggiungi" (click)="openDetail(null,{'name':'sn','value':sn})" class="btn btn-success btn-xs float-right m-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                        </div>
                    </div>
                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>
                        <!-- <br><a type="button" (click)="" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                            <thead class="bg-light">
                                <th>
                                    <div #checkboxselectall class="icheck-primary d-inline mr-3">
                                        <input type="checkbox" title="Seleziona tutti"
                                        (change)="onChangeAll($event.target.checked)" />
                                    </div>
                                    Data
                                    <button type="button" class="btn btn-tool" (click)="switchOrdering('i.date')">
                                        <i class="fas fa-sort {{classOrdering('i.date')}}"  ></i>
                                    </button>
                                </th>
                                <th>Km</th>
                                <th class="text-right">Rifornimento</th>
                                <th class="text-right">Costo</th>
                                <th>Utente</th>
                                <th></th>
                                
                            </thead>
                            <tbody>
                            
                                <!-- elemento ripetuto -->
                                <tr *ngFor="let record of list">
                                    <td>
                                        <div class="icheck-primary d-inline mr-3">
                                            <input type="checkbox" #checkrecord id_record="{{record.id}}"
                                            (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                            />
                                        </div>
                                        {{record.date | date: "d/M/Y h:m a"}}
                                    </td>
                                    <td>
                                        <b>{{ record.km }}</b>
                                    </td>
                                    <td class="text-right">
                                        {{ record.refueling }}
                                    </td>
                                    <td class="text-right">
                                        {{record.price | currency:" &euro;"}}
                                    </td>
                                    <td>
                                        <small>{{record.username}}</small>
                                        <br/>
                                        <small>{{record.shopname}}</small>
                                    </td>
                                    <td>
                                        <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1">
                                            <i class="fas fa-pencil-alt"></i></button>
                                        <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right m-1" (click)="selectRecord(record)">
                                            <span class="d-none d-sm-inline-block">Seleziona&ensp;</span><i class="fas fa-chevron-right"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td><strong>TOTALE</strong></td>
                                    <td>{{total_km}}</td>
                                    <td class="text-right"><strong>{{total_refueling}}</strong></td>
                                    <td class="text-right"><strong>{{total_price | currency:" &euro;"}}</strong></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="2"><strong>MEDIA</strong></td>
                                    <td class="text-right"><strong>{{avg_refueling}}</strong></td>
                                    <td class="text-right"><strong>{{avg_price | currency:" &euro;"}}</strong></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>