import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlarmsService } from 'projects/core/src/lib/alarms.service';

@Component({
  selector: 'app-alarm',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.css']
})
export class AlarmComponent implements OnInit {
  @ViewChild("modalOverview")
  modalOverview:TemplateRef<any>;
  modalOverviewRef;

  @Input()
  showButton=true;

  @Output()
  onLoaded:EventEmitter<any>=new EventEmitter();

  @Output()
  count:EventEmitter<any>=new EventEmitter();

  list=[];
  alarmSelected;
  constructor(
    private alarmService:AlarmsService,
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    this.alarmService.getAlarms().subscribe((items)=>{
      this.list=items;
      this.onLoaded.emit();
      this.count.emit(this.list.length);
    })
  }

  openModalOverview(alarm){
    this.alarmSelected=alarm;
    this.modalOverviewRef=this.modalService.open(this.modalOverview,{size:"xl"});
  }
  closeModalOverview(){
    this.modalOverviewRef.close("success");
  }

}
