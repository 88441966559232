import { Injectable } from '@angular/core';
import {Card} from '../../../../../modules/src/app/bustickets/card';
import { Observable, of } from 'rxjs';
import { WSService } from 'projects/core/src/include/service';
import { Globals } from 'projects/core/src/globals';

@Injectable({
  providedIn: 'root'
})

export class CardsService extends WSService<Card> {

  constructor() {super("cards") }

  getCards():Observable<Card[]>{
    return Globals.ws.requestUrl<Card[]>("cards","getItems",[]);
  }

  getDefault():Observable<Card[]>{
    return Globals.ws.requestUrl<Card[]>("cards","getItems",["c.default=1"]);
  }

  setDefault(id:number):Observable<boolean>{
    return Globals.ws.requestUrl<boolean>("cards","setDefault",[id.toString()]);
  }
}

