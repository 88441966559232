import { Injectable } from '@angular/core';
import { WSService } from 'projects/core/src/include/service';
import { Project } from '../db/project';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService extends WSService<Project> {

  constructor() { super("project")};
}
