import { Input, Component, OnInit,ViewChild,TemplateRef} from '@angular/core';
import { Model } from 'projects/core/src/include/model';
import { ActivatedRoute } from '@angular/router';
import { DocumentDetail, Documents, Document, DocumentMode, DocumentFile } from '../../../db/documents';
import { DocumentsService } from '../../../services/documents.service';
import { AddressesService } from '../../../services//addresses.service';
import { Product} from '../../../db/product';
import { AddressComponent } from '../../address/address.component';
import { Globals } from 'projects/core/src/globals';
import { InventoryMode } from '../../../db/inventories';
import { AddressDetailsComponent } from '../../address/address-details/address-details.component';
import { DocumentlistComponent } from '../documentlist/documentlist.component';
import { MethodpaymentsService } from '../../../services/methodpayments.service';
import { ShopsService } from '../../../services/shops.service';
import { CashFlowDetailsComponent } from '../../cash-flow/cash-flow-details/cash-flow-details.component';
import { CashFlowComponent } from '../../cash-flow/cash-flow-list/cash-flow-list.component';
import { CashflowService } from '../../../services/cashflow.service';
import { ToastMode, User } from 'projects/core/src/include/structures';
import { UserService } from 'projects/core/src/lib/user.service';
import { ShopsComponent } from '../../shops/shops.component';
import { formatDate } from '@angular/common';
import { FinancialAccountsService } from '../../../services/financialaccounts.service';
import { Helper } from 'projects/core/src/helper';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ReceiptComponent } from '../../../modules/receipt/receipt.component';
import { VerifyFiscalCodeService } from 'projects/core/src/lib/verify-fiscal-code.service';
import { VerifyVATNumberService } from '../../../services/verify-vatnumber.service';
import { round } from 'mathjs';
import { TaxesService } from '../../../services/taxes.service';
import { Tax } from '../../../db/tax';
import { DeadlineDetailsComponent } from '../../deadlines/deadline-details/deadline-details.component';



export interface Total{
  netrows:number;
  discountrows:number;
  taxrows:number;
  grossrows:number;
  net:number;
  tax:number;
  gross:number;
  packages:number;
  weight:number;
  taxPercentual:number;
  welfarefund:number;
  taxWelfarefund:number;
  withholdingtax:number;
  
}



@Component({
  selector: 'app-documents-details',
  templateUrl: './documents-details.component.html',
  styleUrls: ['./documents-details.component.sass']
})

export class DocumentsDetailsComponent extends Model<Documents>  implements OnInit {


  @ViewChild("cashflow")
  cashflow:CashFlowComponent;


  @ViewChild("modalCausals")
  modalCausals:TemplateRef<any>;
  modalCausalsRef;

  @ViewChild("modalBanks")
  modalBanks:TemplateRef<any>;
  modalBanksRef;


  modulename="documents";
  @Input()
  id_address:number=0;

  @Input()
  id_type:number=1;

  @Input()
  id_document_linked=0;

  @Input()
  type_document:number=0;

  @Input()
  referenceprefix:string="";

  @Input()
  origin:string="";

  @Input()
  details=[];

  @Input()
  description:string="";
  @Input()
  private:number=0;

  @Input()
  autoUnload=true;

  @Input()
  checkPayment=true;

  @Input()
  id_documentrule:number=0;


  @Input()
  id_table:number=0;

  @Input()
  table:string="";


  @Input()
  referencedocument:any;

  @Input()
  methodpayment:string="";

  @Input()
  unloadAfterSave=true;

  @Input()
  state:number=1;

  @Input()
  paid:number=0;


  @Input()
  document_precompiled:Documents;

  get user(){
    return Globals.user;
  }

  detail:DocumentDetail;
  total:Total;

  tax_code_error:string="";
  vat_number_error:string="";
  sdi_message=[];
  shops=[];
  users:User[];
  taxes:Tax[]=[];
  addressDisabled=true;
  id_documentsrule_default=0;
  sectionals=[];
  enablewelfarefund=false;
  taxWelfarefundPercentual:number=0;
  get welfarefundPercentual(){
    return parseFloat(this.getValueFromSpecialProduct("##WELFAREFUND"));
  };

  set welfarefundPercentual(value){
    this.setValueFromSpecialProduct("##WELFAREFUND",value)
  }

  get withholdingtaxPercentual(){
    return parseFloat(this.getValueFromSpecialProduct("##WITHHOLDINGTAX",this.module.getParam("percentualwithholdingtax",0)));
  };

  set withholdingtaxPercentual(value){
    this.setValueFromSpecialProduct("##WITHHOLDINGTAX",value)
  }



  get iswelfarefund(){
    let v=this.getValueFromSpecialProduct("##WELFAREFUND");
    return v==null?false:true;
  }

  get iswithholdingtax(){
    let v=this.getValueFromSpecialProduct("##WITHHOLDINGTAX");
    v==null?this.record['withholdingtaxenable']=false:this.record['withholdingtaxenable']=true;
    return v==null?false:true;
  }

  @ViewChild('modalSelectDocument')
  modalSelectDocument:TemplateRef<any>;
  modalSelectDocumentRef:NgbModalRef;

  @ViewChild('modalEditAddress')
  modalEditAddress:TemplateRef<any>;
  modalEditAddressRef:NgbModalRef;


  listDescription=[];
  banks=[];
  rules=[];
  rule:any;
  methodpayments=[];
  financialaccounts=[];
  detailForm;
  field_required=['date','id_address'];
  id_inventory:number=0; //inventario dell'utente corrente

  idDocumentDetailSelected=[];

  

  get previousImport():boolean{
    return (this.record.details && this.record.details.length==0 && this.record.amount>0?true:false);
  }

  get IDAddress(){
    return this.id_type==DocumentMode.buy?this.record.id_address:0;
  }

  get titleWindow(){

    if(!this.record)
      return "";

    let ref =  "n. " + this.record.reference;
    if(this.record.id_type==1 && this.record.referenceprefix)
      ref +=  "/" + this.record.referenceprefix;
    ref +=  " del " + formatDate(this.record.date,'dd-MM-yyyy',"it-IT")    
    if(this.record.id_type==2)
      ref += " [IN INGRESSO]";
    if(this.record.id_type==1)
      ref +=  " [IN USCITA]";

    switch(parseInt(this.record.typedocument.toString())){
      case 1:
        return "Fattura " + ref;
      case 2:
        return "Fattura Accompagnatoria " + ref;
      case 3:
        return "Nota di Credito " + ref;
      case 4:
        return "Preventivo " + ref;
      case 5:
        return "DDT " + ref;
      case 6:
        return "Scontrino " + ref;
      case 7:
        return "Ordine Cliente " + ref;
      case 8:
        return "Ordine Fornitore " + ref
      case 9:
        return "Non Fiscale " + ref
      case 10:
        return "Trasferimento " + ref
      case 11:
        return "Documento Amministrativo " + ref
      case 12:
        return "Fattura di Acconto " + ref
      case 13:
        return "Inventario " + ref
    }
    return "Documento";
  }

  
 
  constructor(
    private route1: ActivatedRoute,
    private invoicesService: DocumentsService,
    public addressesService:AddressesService,
    private userService:UserService,
    private methodpaymentsService:MethodpaymentsService,
    private documentsService:DocumentsService,
    private shopsService:ShopsService,
    private cashflowService:CashflowService,
    private financialAccountsService:FinancialAccountsService,
    private modalService:NgbModal,
    private verifyFiscalCodeService:VerifyFiscalCodeService,
    private verifyVATNumberService:VerifyVATNumberService,
    private taxesService:TaxesService

    ) {
      super(route1,invoicesService); 
      this.total={} as Total;
      this.total.netrows=0;
      this.total.discountrows=0;
      this.total.taxrows=0;
      this.total.grossrows=0;
      this.total.net=0;
      this.total.gross=0;
      this.total.tax=0;
      this.total.packages=0;
      this.total.weight=0;
      this.total.welfarefund=0;
      
      this.id_inventory=Globals.user.getParam("id_inventory",0);
     
  }



  ngOnInit(): void {
    super.ngOnInit.apply(this, arguments);

    if (this.id_type==1) {
      this.record=new Documents(this.referenceprefix?this.referenceprefix:
        Globals.user['shop']?Globals.user['shop'].getParam("referenceprefix"):this.module.getParam("referenceprefix")
        );
    } else {
      this.record=new Documents();
    }

    //carica i parametri
    if(this.methodpayment=="")
      this.methodpayment=this.module.getParam("default_methodpayment","Contanti")
    this.banks=this.module.getParam("banks");
    this.sectionals=this.module.getParam("sectionals");
    
    this.taxWelfarefundPercentual=this.module.getParam("percentualtaxwelfarefund");
    this.withholdingtaxPercentual=this.module.getParam("percentualwithholdingtax",20);

    this.enablewelfarefund=this.module.getParam("enablewelfarefund",false);

    if(this.mode!="modal")
      this.route1.params.subscribe((params) =>{

      this.id = params.id;
      if(params.id_address>0)
        this.id_address=params.id_address;
        if(params.id_type>0)
        this.id_type=parseInt(params.id_type);
      
      } );

    const default_address=Globals.parameters.get("general").getParam("default_address")
    
    this.taxesService.getTaxes().subscribe((items)=>{
      this.taxes=items;
    })

    this.shopsService.getItemsByAddress(default_address).subscribe((items)=>{
      this.shops=items;
    });

    this.userService.getUsers().subscribe((items)=>{
      this.users=items;
    });

    this.financialAccountsService.getAccounts().subscribe((items_c)=>{
      this.financialaccounts=items_c;
    });

    this.listDescription=this.module.getParam('causals');
    
    const default_causal_sell=this.module.getParam("default_causal_sell","");
    const default_causal_buy=this.module.getParam("default_causal_buy","");
      

   this.afterGetItem=(()=>{
   // this.listDescription=this.module.getParam(this.id_type==DocumentMode.sell?"causals_sell":"causals_buy");
    

    this.record.id_document_linked=this.id_document_linked;
    if(this.record.id==0 ){  //Nuovo documento

      //cerca l'anagrafica corretta
      if(this.id_address>0 ){
        this.addressesService.getItem(this.id_address).subscribe((item)=>{
          this.OnSelectAddress(item);
        });
      }

      this.record.id=0;
      this.record.typedocument=this.type_document==0?1:this.type_document;
      //this.record.date=Helper.convertDateControl();
      this.record.id_type=this.id_type;
      this.record.private=this.private;
      this.record.cashflow=[];
      this.record.state=this.state;
      this.record.weight=0;
      this.record.packages=0;
      this.record.vector="";
      this.record.shop_start=Globals.user['shop']?Globals.user['shop']:null;
      this.record.id_shop_start=this.record.shop_start?this.record.shop_start.id:0;
      this.record.methodpayment=this.methodpayment;

      if(this.record.methodpayment=="Bonifico"){
        this.record.bank=this.module.getParam("default_bank","");
        this.record.iban=this.module.getParam("default_iban","");
        
      }


      this.record.origin=this.origin;
      this.record.created_from=Globals.user['shop']?Globals.user['shop']['id']:null;
      this.record.id_user=Globals.user.id;
      this.record.paid=this.paid;
      if(this.details.length>0){

        this.record.details=this.details;

        //verifica se nei details è impostato l'id_tax
        for(let i=0;i<this.record.details.length;i++){
          this.record.details[i].id_tax=0;
          if(this.record.details[i].id_tax==0 || this.record.details[i].id_tax==undefined || this.record.details[i].id_tax==null){
            //cerca l'id corretto
            for(let t of this.taxes){
              if(t.tax==this.record.details[i].tax && this.record.details[i].id_tax==0){
                this.record.details[i].id_tax=t.id;
                break;
              }
            }
          }
        }
      }
      
      //se anagrafica è Pubblica Amministrazione assegna documentsrule a 4 (Split Payment)
      if(this.record.addressItem && this.record.addressItem.role=='PA')
        this.id_documentrule=4;

      if(this.id_documentrule>0)
        this.record.id_documentsrule=this.id_documentrule;
      
      if(this.id_table)
        this.record.id_table=this.id_table;

      if(this.table)
        this.record.table=this.table;
  
      if(this.referencedocument)
        this.record.referencedocument=this.referencedocument;
      
        //this.record.documentlinked=[];
      //cerca la causale di default
      
      this.record.description=this.description?this.description:(this.id_type==2?default_causal_buy:default_causal_sell);
      //se è abilitata la cassa previdenziale
      if(this.enablewelfarefund){
        this.addWelfareFund();
        
      }
      
      this.getNextNumeration();
      if(this.type_document==0)
        this.openModalSelectDocument();
    }else{

      this.getRules(()=>{
        
      });

      try{
        this.record.tags=JSON.parse(this.record.tags);
        if(!Array.isArray(this.record.tags))
          this.record.tags=[];
      }catch{
        this.record.tags=[];
      }
      
      this.id_type=this.record.id_type;
      try{
        this.sdi_message=JSON.parse(this.record.sdi_message);
      }catch{
        this.sdi_message=[];
      }

      try{
        this.record.managementvalues=JSON.parse(this.record.managementvalues);
      }catch{
        this.record.managementvalues=[];
      }
    }

    this.calculateTotal(!this.previousImport);
    this.selectBank();


 
   });

   this.afterSave=(()=>{
    if(this.id>0 && this.checkPayment){

      //Chiede se creare la scadenza per la ritenuta d'acconto      
      // if(this.record.id_type==2 && this.iswithholdingtax){
      //   if(this.deadlines){
      //     Globals.modal.showConfirm("Ritenuta d'acconto","Creare una scadenza per il pagamento della ritenuta d'acconto?",()=>{
      //       let date_schedule = new Date(this.record.date);            
      //       date_schedule.setMonth(date_schedule.getMonth() + 1);
      //       date_schedule.setDate(16);            
      //       this.addDeadline(date_schedule,"Ritenuta d'acconto doc. "+this.record.reference+this.record.referenceprefix,this.record.methodpayment,this.total.withholdingtax,0);
      //       return false;
      //     },"Sì, crea","No. Grazie");
      //   }
      // }
      
      if(this.total.gross - this.record.paid > 0.05){
        //chiede se registrare un pagamento     
        if( this.record.typedocument==1 || 
            this.record.typedocument==2 || 
            this.record.typedocument==3 || 
            this.record.typedocument==6 ||         
            this.record.typedocument==9 || 
           this.record.typedocument==11 || 
           this.record.typedocument==12 ){
          Globals.modal.showConfirm("Registrare il pagamento?","Risulta da pagare la somma di "+(this.record.total_pay - this.record.paid).toFixed(2)+" Euro",()=>{
              setTimeout(()=>{this.addPayment(true);},500);
              return false;
          },"Sì, registra","No. Grazie")
        }
        //chiede se stampare lo scontrino
        if(this.record.typedocument==6){
          Globals.modal.showConfirm("Stampare lo scontrino?","Totale: "+this.total.gross.toFixed(2)+" Euro",()=>{
            Globals.modal.showModal(ReceiptComponent,[
              {"name":"mode","value":"modal"},
              {"name":"document","value":this.record},
            ],(instance)=>{

            })
          },"Sì, stampa","No. Grazie")
        }
      }    

    }
    return true;
   });
   
   this.methodpaymentsService.getMethods().subscribe((items_m)=>{
    this.methodpayments=items_m;
  
      this.getRules(()=>{

        if(this.document_precompiled){
          Object.assign(this.record,this.document_precompiled);
          if(!this.record.addressItem && this.record.id_address>0 ){
            this.addressesService.getItem(this.record.id_address).subscribe((item)=>{
              this.OnSelectAddress(item);
            });
          }
          this.checkPayment=false;
          this.getNextNumeration(true);
          this.calculateTotal(!this.previousImport);
          this.selectBank();
        }else{
          this.getItem();
        }

        
      });
        
    });
    
   this.afterSelectionRecord=((r,table)=>{
    this.record.name=this.record.addressItem.name;
    this.record.address=this.record.addressItem.address;
    this.record.city=this.record.addressItem.city;
    this.record.country=this.record.addressItem.country;

    this.record.zip=this.record.addressItem.zip;
    this.record.vat_number=this.record.addressItem.vat_number;
    this.record.tax_code=this.record.addressItem.tax_code;
    this.record.sdi=this.record.addressItem.sdi;
    this.record.pec=this.record.addressItem.pec;
    
    this.record.name_destination=this.record.addressItem.name;
    this.record.address_destination=this.record.addressItem.address;
    this.record.city_destination=this.record.addressItem.city;
    this.record.country_destination=this.record.addressItem.country;
    this.record.zip_destination=this.record.addressItem.zip;
   });

   
  }

  
  getNextNumeration(checkNew=false){
    if(checkNew){
      if(this.id>0)
        return false;
    }
    this.record.getNextNumeration();

    //verifica se si è scelto il trasferimento o l'inventario
    if(this.record.typedocument==10 || this.record.typedocument==13){
      //prendi l'anagrafica di default
      const id_address_default=Globals.parameters.get("general").getParam("default_address",0);
      if(id_address_default>0){
        this.addressesService.getItem(id_address_default).subscribe((item)=>{
          this.OnSelectAddress(item);
        })
        
      }
    }
    if(this.record.typedocument==10){
      if(this.field_required.indexOf("id_shop_start")==-1){
        this.field_required.push("id_shop_start");
      }

      if(this.field_required.indexOf("id_shop_end")==-1){
        this.field_required.push("id_shop_end");
      }

    }else{
      if(this.field_required.indexOf("id_shop_start")>-1){
        this.field_required.splice(this.field_required.indexOf("id_shop_start"),1);
      }

      if(this.field_required.indexOf("id_shop_end")>-1){
        this.field_required.splice(this.field_required.indexOf("id_shop_end"),1);
      }
    }


  }

  calculateGrossDetail(){
    
    this.detail.gross=Math.round(
      this.detail.netprice*
      this.detail.quantity*
      (1+this.detail.tax/100)*
      (1-this.detail.discount1/100)*
      (1-this.detail.discount2/100)*
      (1-this.detail.discount3/100)*
      100)/100;
  }

  getRules(oncomplete=null){
    this.documentsService.getRules(this.record.typedocument).subscribe((items_r)=>{
      this.rules=items_r;
      if(this.rules.length>0)
        if(this.record.id_documentsrule==0){
          this.record.id_documentsrule=this.rules[0].id;
          this.id_documentsrule_default=this.record.id_documentsrule;
        }
        if(oncomplete)
          oncomplete();
    });

  }

  calculateTotalWeightAndPackages(){
    this.total.packages=0
    this.total.weight=0;
    for(let d of this.record.details){
      this.total.packages+=parseInt(d.quantity.toString());
      this.total.weight+=(d.product.weight?parseFloat(d.product.weight.toString()):0)*d.quantity;
    }
    this.record.packages=this.total.packages;
    this.record.weight=this.total.weight;
  }
  
  calculateTotal(updateTotal:boolean=true){
    this.total={} as Total;
    this.total.net=0;
    this.total.netrows=0;
    this.total.discountrows=0;
    this.total.taxrows=0;
    this.total.gross=0;
    this.total.tax=0;
    this.total.welfarefund=0;
    //this.total.weight=0;


    //calcolo il valore totale dei beni
    for(let d of this.record.details){
      //escludi i beni che iniziano con un carattere speciale ##
      if(d.description && d.description.substring(0,2)=="##")
        continue;
      
      this.total.netrows=this.total.netrows+(d.netprice*d.quantity);
      this.total.discountrows=this.total.discountrows+(d.netprice*d.quantity)*(Helper.getEquivalentDiscount(d.discount1,d.discount2,d.discount3)/100);
      
      this.total.taxrows=this.total.taxrows+((d.netprice*d.quantity)*(1-Helper.getEquivalentDiscount(d.discount1,d.discount2,d.discount3)/100))*(d.tax/100);
      this.total.grossrows=this.total.net+this.total.taxrows+this.total.discountrows;
      d.gross=(d.netprice*d.quantity)*(1+d.tax/100)*(1-(Helper.getEquivalentDiscount(d.discount1,d.discount2,d.discount3)/100));
        //this.total.weight=this.total.weight+(d.weight?parseFloat(d.weight.toString()):0)*d.quantity;
      
    }

    //calcolo i costi e gli sconti aggiuntivi
    let aux_cost=parseFloat(this.record.shipping_cost.toString())+parseFloat(this.record.packaging_cost.toString())-parseFloat(this.record.discount.toString());
    
    //calcolo la cassa previdenziale
    
    if(this.enablewelfarefund && this.iswelfarefund){
      this.total.welfarefund=(this.total.netrows-this.total.discountrows)*(this.welfarefundPercentual/100);
      this.total.taxWelfarefund=this.total.welfarefund*(this.taxWelfarefundPercentual/100);
    }else{
      this.total.welfarefund=0;
      this.total.taxWelfarefund=0;
    }

    



    //calcolo i totali
    this.total.net=this.total.netrows-this.total.discountrows+aux_cost+this.total.welfarefund;
    this.total.tax=this.total.taxrows+this.total.taxWelfarefund;
    this.total.gross=this.total.net+this.total.tax;
    this.total.taxPercentual=round((this.total.gross-this.total.net)/this.total.net*100);
    
    //calcolo ritenuta d'acconto

    if(this.iswithholdingtax){
      this.total.withholdingtax=(this.total.netrows)*(this.withholdingtaxPercentual/100);
    }else{
      this.total.withholdingtax=0;
    }
    
    
    //aggiorna i valori dei beni speciali    
    this.updateSpecialProduct();

    //this.record.weight=this.total.weight;
    if(updateTotal)
      this.record.amount=this.total.net;

    let total_linked=0;
    for(let l of this.record.documentlinked){
      if(l.typedocument==3){ //NOTA DI CREDITO
        total_linked=total_linked+l.total;
      }
    }

    this.record.total_pay=this.total.gross-total_linked-this.total.withholdingtax;
  }
  
  selectBank(){
    for(let b of this.banks){
      if(b.name==this.record.bank)
        this.record.iban=b.iban;
    }
  }

  getProduct(product:Product){

    let id_type_inventory=this.record.id_type==DocumentMode.buy?InventoryMode.load:InventoryMode.unload;


    this.detail.product=product;
    this.detail.code=product.codeinternal;
    this.detail.id_product=product.id;
    this.detail.description=product.brand+" "+product.name;
    this.detail.netprice=product.getPrice(this.detail.quantity,id_type_inventory);
    if(id_type_inventory==InventoryMode.load){
      //calcolo lo sconto equivalente
      //this.detail.discount=product.getEquivalentDiscountCost();
      this.detail.discount1=product.discountcost1;
      this.detail.discount2=product.discountcost2;
      this.detail.discount3=product.discountcost3;
      
    }

    this.detail.tax=product.tax;
    this.detail.gross=product.grossprice;
    this.detail.unit=product.unit;
    this.detail.weight=product.weight;
    this.detail.sn=product.sn;


    if(this.detail.product.has_serials)
      this.detail.quantity=1;
    this.calculateGrossDetail();

  }

  getStateProduct(){
    
  }

  

  addAddress(){
    Globals.modal.showModal(AddressDetailsComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance!=null){
        this.record.addressItem=instance['record'];
        this.record.id_address=this.record.addressItem.id;
      }
    })
  }

  addDocument(){
    let doc:Document={} as Document;
    doc.id_document=0;
    doc.name="";
    doc.type="shippingdocuments";
    doc.reference="";
    
    this.record.documentlinked.push(doc);
  }


  createDocument(){
    //salva il progetto
    this.save(false,()=>{
      Globals.navigation.onNavigate(["/shippingdocuments/shippingdocuments-details/",0,0,this.id]);

    });
    
    

  }

  linkDocument(){
    Globals.modal.showModal(DocumentlistComponent,[
      {"name":"mode","value":"modal"},
      {"name":"type","value":this.id_type}
      
    ],(instance)=>{
      if(instance!=null){
        this.record.documentlinked.push(instance['recordSelected']);
      }
    });
  }

  //rimuove il collegamento ad un documento
  removeDocument(doc:Document){
    for(let i=0;i<this.record.documentlinked.length;i++){
      if(this.record.documentlinked[i].id_document==doc.id_document){
        this.record.documentlinked[i]['removed']=true;

        return;
      }
    }
  }

  openDocument(doc:any){
    let d=new Documents();
    Object.assign(d,doc);

    Globals.modal.showModal(DocumentsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":d.id}
      
    ],(instance)=>{

    });

  }

  openLastOrderSupplier(id_supplier){
    this.documentsService.getLastOrderSupplier(id_supplier).subscribe((item)=>{
      if(item){
        Globals.modal.showModal(DocumentsDetailsComponent,[{"name":"mode","value":"modal"},{"name":"id","value":item['id']}],()=>{});
      }
    });
  }


  selectDocument(doc:Document){

    
  }



  save(reloadItem:boolean=true,oncomplete:Function=null){

    // VERIFICHE PER FATTTURAZIONE ELETTRONICA

      // sostituisci caratteri non ammessi
      if(this.record.description2) {
        this.record.description2 = this.record.description2.replace(/–/g,"-");
        this.record.description2 = this.record.description2.replace(/’/g,"'");
        this.record.description2 = this.record.description2.replace(/\t/g," ");
        this.record.description2 = this.record.description2.replace(/[^\w\sáéíóúàèìòù!"£$%&/(){}=?^'°+@#,.;:-]/gi,"");
      }

      // verifica e corregge i dati di intestazione per
        if(this.record.id_type==1 && (this.record.typedocument==1 || this.record.typedocument==2 ) || this.record.typedocument==3 || this.record.typedocument==12){
          //fatture estere
          if(this.record.nation != "IT") {
            if(!this.record.vat_number) {
              let messaggio = "Essendo un documento verso cliente privato estero, ho reimpostato correttamente i campi Codice Fiscale, Partita IVA, CAP, SdI, PEC.";
              Globals.modal.showConfirm("Correzione automatica",messaggio,()=>{},"Sì. Ho capito","");
              this.record.zip = "00000";
              this.record.tax_code = "XXXXXXXXXXX";
              this.record.vat_number = "XXXXXXXXXXX";
              this.record.pec = "";
              this.record.sdi = "XXXXXXX";
            }
            this.record.country = "";
          } else {
            //fatture italiane
            let errore = ""
            if(!this.record.vat_number && (!this.record.sdi || this.record.sdi == "XXXXXXX") && !this.record.pec) {
              this.record.sdi = "0000000";
            }
            if(!this.record.address) {
              errore += "non è stato inserito l'indirizzo di residenza, ";
            }
            if(!this.record.city) {
              errore  += "• non è stato inserito il comune di residenza&#10;";
            }
            if(!this.record.zip || this.record.zip.length != 5) {
              errore += "non è stato inserito il CAP oppure non è corretto, ";
            }
            if(!this.record.tax_code && !this.record.vat_number) {
              errore += "non è stato inserito il Codice Fiscale o la Partita IVA,";
            }
            if(this.record.tax_code && !this.verifyFiscalCodeService.validate(this.record.tax_code)){
              errore +="Codice Fiscale errato, "
            }
            if(this.record.vat_number && !this.verifyVATNumberService.validate(this.record.vat_number)){
              errore +="Partita IVA errata, "
            }
            if((this.record.sdi.length <= 5 || this.record.sdi.length >= 8 ) && !this.record.pec) {
              errore += "non è stata indicata una PEC e il codice SdI è assente o non è corretto, ";
            }
            if(errore !="") {
              let messaggio = "ATTENZIONE! Sono stati rilevati i seguenti problemi nel documento: "+errore+" il documento è stato comunque salvato.";          
              Globals.modal.showConfirm("Verifica automatica",messaggio,()=>{},"","Sì. Ho capito");
            }
          }
        }
    // FINE VERIFICHE

    if(this.record.id==0){
      this.record.id_user=Globals.user.id;
    }

    //verifica se si sta salvando un nuovo record
    let newRecord:Boolean=this.record.id==0?true:false;

      

    super.save.apply(this,[reloadItem,()=>{
      /*
      //effettua lo scarico dei beni
      if(newRecord){

        let typemessage=this.record.id_type==DocumentMode.sell?"lo scarico":"il carico";
        if(this.autoUnload){
          if(confirm("Effettuare "+typemessage+" magazzino di tutti i beni del documento ?")){
              for(let p of this.record.details){
                p['type']=this.record.id_type==DocumentMode.sell?InventoryMode.unload:InventoryMode.load;
                p['id_table']=this.id;
                p['table']="documents";
              }
              this.documentsService.inventoryAction(this.documentsService.convertToInventoryList(this.record.details));

          }
        }
      }*/
      oncomplete();
    }]);
    if(this.record.id_type==DocumentMode.sell){
      //this.createOrderSuppler(()=>{});
    }
    
    


  }


  selectAddress(){
    Globals.modal.showModal(AddressComponent,[
      {"name":"mode","value":"modal"},
      {"name":"titleModal","value":"Seleziona un'anagrafica"},
      //[Obsolete] {"name":"id_type","value":(this.id_type==1?parseInt(this.module.getParam("id_type_client")):parseInt(this.module.getParam("id_type_supplier")))}
    ],(instance)=>{
      let r=instance['recordSelected'];

      this.OnSelectAddress(r);

    });
  }

  OnSelectAddress(r){
    if(r){
      this.record.addressItem=r;
      this.record.id_address=r.id;
      this.record.name=r['name'];
      this.record.address=r['address'];
      this.record.city=r['city'];
      this.record.zip=r['zip'];
      this.record.country=r['country'];
      this.record.nation=r['nation'];
      this.record.vat_number=r['vat_number'];
      this.record.tax_code=r['tax_code'];
      this.record.sdi=r['sdi'];
      this.record.pec=r['pec'];
      this.record.name_destination=r['name'];
      this.record.address_destination=r['address'];
      this.record.city_destination=r['city'];
      this.record.zip_destination=r['zip'];
      this.record.country_destination=r['country'];
      this.record.id_financialaccount=r['id_financialaccount'];

      //verifica se ci sono parametri di default
      try{
        if(typeof(r.params)=="string")
          r.params=JSON.parse(r.params)
      }catch{

      }finally{
        if(!r.params)
          r.params={}
      }


      if(r.params['default_methodpayment']){
        this.record.methodpayment=r.params['default_methodpayment']
      }

      if(r.params['default_document_causal']){
        this.record.description=r.params['default_document_causal']
      }

    }
  }

  OnViewAddress(r){
    Globals.modal.showModal(AddressDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":r.id}
    ],(instance)=>{
      if(instance!=null)
        this.OnSelectAddress(instance['record']);
    });
  }


  createOrderSuppler(complete:Function){
    //prendi tutte le voci che non sono disponibili
    let items=this.record.details.filter(x=>x['no_avaible']==true);
    if(items.length>0){
      

      this.documentsService.createOrderSupplier(items,this.id_inventory,()=>{
        complete();
      },false);
    }else{
      complete();
    }
  }

  //verifica, nel caso di acquisto, che tutti i prodotti fanno parte dello stesso fornitore
  checkSupplier(){
    if(this.id_type==DocumentMode.buy){
      if(this.record.id_address==0 || this.record.id_address==undefined){
        if(this.record.details.length>0){
          if(this.record.details[0].product){
            if(this.record.details[0].product.id_supplier>0){
              this.addressesService.getItem(this.record.details[0].product.id_supplier).subscribe((item)=>{
                this.OnSelectAddress(item);
              }); 
              
            }
          }
        }
      }
    }
  }


  moveIntoAnotherDocument(){

    Globals.modal.showModal(DocumentlistComponent,[{"name":"mode","value":"modal"},{"name":"type","value":this.id_type}],(instance)=>{
      if(instance!=null){
        Globals.modal.showConfirm("Confermi il trasferimento delle voci?","ATTENZIONE: Questa operazione è irreversibile!",()=>{
          let document=instance['recordSelected'];
          this.documentsService.moveDetails(this.idDocumentDetailSelected.join(","),document.id).subscribe((result)=>{
            this.getItem();
          });
        },"Sì. Confermo","No. Annulla");
      }
    });

  }

  setRule(event){

    //trova la regola nell'elenco
    for(let r of this.rules){
      if(parseInt(r.id)==parseInt(event.target.value.split(":")[1])){
        this.record.documentsrule=r;
        this.checkRule();
        return;
      }
    }

    
  }

  checkRule(){
    
    let rule=this.record.documentsrule;

    //verifica se bisogna modificare la tassazione per ogni voce di bene
    if(rule){
      if(rule['enable_tax']){
        for(let i=0;i<this.record.details.length;i++){
          this.record.details[i].tax=rule['tax'];
        }
      }
    }

    if(this.record.id_documentsrule==0)
      this.record.id_documentsrule=this.id_documentsrule_default;
  }


  /*
  SendToSDI(){
    if(confirm("Invio il documento allo SDI?")){
      Globals.message.showToaster("Rinvio documento allo SdI in corso...",ToastMode.WARNING);
   
      Globals.setLoading(true);
      this.save(true,()=>{
        this.invoicesService.sendEInvoice(this.record.id).subscribe((result)=>{
          Globals.message.showToaster("Documento "+this.record.reference+this.record.referenceprefix+" rinviato allo SDI",ToastMode.SUCCESS);
          Globals.setLoading(false);
           this.getItem();
          });
      });
    }
  }
  */


  addPayment(afterCloseWindow=false){
    let ref = "n. " + this.record.reference;
    if ( this.record.referenceprefix) ref += '/' + this.record.referenceprefix;
    ref += ' del ' + formatDate(this.record.date,'dd-MM-yyyy',"it-IT");
    Globals.modal.showModal(CashFlowDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"description","value":this.record.id_type==1?'Incasso doc. '+ref:'Pagamento doc. '+ref},
      {"name":"id_table","value":this.record.id},
      {"name":"table","value":"documents"},
      {"name":"id_address","value":this.record.id_address},
      {"name":"amount","value":((this.record.total_pay - this.record.paid)/(1+(this.total.taxPercentual/100))).toFixed(2)},
      {"name":"tax","value":this.total.taxPercentual},
      {"name":"paid","value":(this.record.total_pay - this.record.paid).toFixed(2)},
      {"name":"id_type","value":this.record.id_type},
      {"name":"reference","value":this.record},
      {"name":"methodpayment","value":this.record.methodpayment},
      {"name":"id_financialaccount","value":this.record.id_financialaccount}
    ],(instance)=>{
      this.cashflow.updateList();
      if(afterCloseWindow)
        this.onBack("success");
    });
  }

  addDeadlineWithholdingtax(){
    let date_schedule = new Date(this.record.date);            
    date_schedule.setMonth(date_schedule.getMonth() + 1);
    date_schedule.setDate(16);
    this.addDeadline(date_schedule,"Ritenuta d'acconto doc. "+this.record.reference+this.record.referenceprefix,this.record.methodpayment,this.total.withholdingtax,0);
  }

  LinkPayment(){
    Globals.modal.showModal(CashFlowComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance!=null){
        let d=instance['recordSelected'];

        Globals.modal.showConfirm("","Attribuire il pagamento a questo documento?",()=>{
          d.id_table=this.record.id;
          d.table="documents";
          this.cashflowService.save(d,()=>{
            this.cashflow.updateList();
          });
        },"Assegna","Annulla");
        
      }
      
    });
  }


  changePaid(){
    if(this.cashflow)
      this.record.paid=Math.abs(this.cashflow.total_paid);
  }


  checkStatusSdiInvoice(){
    Globals.modal.showConfirm("Verifica stato SdI","Prima di verificare, il documento verrà salvato.",()=>{
      this.save(true,()=>{
        Globals.setLoading(true);
        Globals.message.showToaster("Verifica stato SdI in corso...",ToastMode.WARNING);
        this.invoicesService.checkStatusSdi(this.record.id,this.record.sdi_idinvoice).subscribe((item)=>{
          Globals.setLoading(false);
          Globals.message.showToaster("Stato SdI verificato",ToastMode.SUCCESS);
       
          if(item){
            this.record.sdi_message=item.sdi_message;
            this.record.sdi_status=item.sdi_status;
          }
        });
      })
    },"Verifica","Annulla");
  }


  decodeSDIErrorMessage(){
    let messages=[];
    try{
      messages=JSON.parse(this.record.sdi_message);
      if(!Array.isArray(messages))
        messages=[];
    }catch(e){
      messages=[];
    }

    return messages;
  }


  selectShop(is_shop_start=true){
    Globals.modal.showModal(ShopsComponent,[
      {"name":"mode","value":"modal"}
    ],(instance)=>{
      if(instance){
        if(is_shop_start){
          this.record.shop_start=instance['recordSelected'];
          this.record.id_shop_start=this.record.shop_start.id;
        }else{
          this.record.shop_end=instance['recordSelected'];
          this.record.id_shop_end=this.record.shop_end.id;
        }
    }
    });
  }


  downloadFileSDI(type="in"){
    Globals.setLoading(true);
    
    if(type=="in"){
      this.invoicesService.resaveInBoxDownloadedFile(this.record.sdi_idinvoice,this.record.id).subscribe((item) => 
      {
        Globals.setLoading(false);
        this.getItem();
      });

    }else{
      this.invoicesService.resaveOutBoxDownloadedFile(this.record.sdi_idinvoice,this.record.id).subscribe((item) => 
      {
        Globals.setLoading(false);
        this.getItem();
      });

    }
  }


  downloadFileSDIOutBox(){
    Globals.setLoading(true);
    
    this.invoicesService.resaveOutBoxDownloadedFile(this.record.sdi_idinvoice,this.record.id).subscribe((item) => 
    {
      Globals.setLoading(false);
      this.getItem();
    });
    
  }


  addManagementValues(){
    let m={};
    m['key']="NOTA";
    m['value']="";
    if(this.record.managementvalues==null)
      this.record.managementvalues=[];
    this.record.managementvalues.push(m);
  }


  deleteManagementValues(i){
    this.record.managementvalues.splice(i,1);
  }


  connectReference(event){
    if(event){
      this.record.table=event.table_reference;
      this.record.id_table=event.id;
      this.record.referencedocument=event;
    }else{
      this.record.table="";
      this.record.id_table=0;
      this.record.referencedocument=null;
    }
  }
  

  confirmDocument(){
    let description=''
    if (this.record.enable_inventory==1) 
      description='Confermando verranno effettuate le movimentazioni di magazzino (carico/scarico).';
    if (this.record.enable_inventory==0) 
      description='ATTENZIONE: hai richiesto di NON effettuare movimentazioni di magazzino (carico/scarico)!';

    Globals.modal.showConfirm("Vuoi confermare il documento?",description,()=>{
      this.record.state=2;
      this.saveAndClose();
    },"Confermo","Annulla");

  }


  openModalSelectDocument(){
    this.modalSelectDocumentRef=this.modalService.open(this.modalSelectDocument);
  }


  closeModalSelectDocument(){
    this.modalSelectDocumentRef.close("success");
  }


  selectTypeDocument(typedocument){
    this.record.typedocument=typedocument;
    this.getNextNumeration();
    const id_address_default=Globals.parameters.get("general").getParam("default_address",0);
    if(typedocument==6){
      
      if(id_address_default>0){
        this.addressesService.getItem(id_address_default).subscribe((item)=>{
          this.OnSelectAddress(item);
        })
      }

    }else{
      if(this.id_address==0){
        if(this.isModuleEnabled('address')){
          this.selectAddress();
        }else{
          this.addressesService.getItem(id_address_default).subscribe((item)=>{
            this.OnSelectAddress(item);
          })
        }
      }
    }

  
    this.record.enable_inventory=this.checkDocumentEnableInventory();
    this.closeModalSelectDocument();
  }


  checkDocumentEnableInventory(){
    if((this.record.typedocument==1 || this.record.typedocument==2 || this.record.typedocument==3 || this.record.typedocument==5 || this.record.typedocument==6 || this.record.typedocument==9 || this.record.typedocument==13) && this.record.state!=2){
      if(this.record.table!="booking")  
        return 1;
    }
    return 0;
    
  }


  openModalEditAddress(){
    Globals.modal.showConfirm("Modifica","",()=>{
      this.OnViewAddress(this.record.addressItem);
    },"l'anagrafica del cliente","solo il documento");
    this.addressDisabled=false;
  }


  /**
   * Aggiorna i beni "speciali" ( che iniziano con ##)
   */
  updateSpecialProduct(){
    for(let d of this.record.details){
      if(d.description && d.description.substring(0,2)=="##"){
        if (d.description.includes("##WELFAREFUND")){
            d.netprice=this.total.welfarefund;
            if(d.netprice>0){
              d.quantity=1;
            }
        }

        if (d.description.includes("##WITHHOLDINGTAX")){
          d.netprice=this.total.withholdingtax;
          if(d.netprice>0){
            d.quantity=1;
          }
        }

      }
    }
  }


  getValueFromSpecialProduct(specialproductname,defaultvalue=null){
    for(let d of this.record.details){
      if(d.description && d.description.includes(specialproductname)){
        return d.description.replace(specialproductname,"");
      }
    }

    return defaultvalue;
  }

  setValueFromSpecialProduct(specialproductname,value){
    for(let d of this.record.details){
      if(d.description && d.description.includes(specialproductname)){
        d.description=specialproductname+String(value);
        return;
      }
    }
  }


  removeWelfareFund(){
    for(let i=0;i<this.record.details.length;i++){
      if(this.record.details[i].description && this.record.details[i].description.includes("##WELFAREFUND")){
        this.record.details.splice(i,1);
        this.calculateTotal();
        return;
      }
    }
    
  }


  addWelfareFund(){
    let dwelfarefund=new DocumentDetail();
    dwelfarefund.description="##WELFAREFUND"+this.module.getParam("percentualwelfarefund","4");
    dwelfarefund.tax=this.taxWelfarefundPercentual;
    this.record.details.push(dwelfarefund);
    this.calculateTotal();
  }


  removeWithHoldingTax(){
    for(let i=0;i<this.record.details.length;i++){
      if(this.record.details[i].description && this.record.details[i].description.includes("##WITHHOLDINGTAX")){
        this.record.details.splice(i,1);
        this.calculateTotal();
        return;
      }
    }
    
  }

  addWithHoldingTax(){
    let dwelfarefund=new DocumentDetail();
    dwelfarefund.description="##WITHHOLDINGTAX"+this.withholdingtaxPercentual;
    dwelfarefund.tax=0;
    this.record.details.push(dwelfarefund);
    this.calculateTotal();
  }

  withholdingtaxSwitchEnabled(){
    if(this.iswithholdingtax){
        this.removeWithHoldingTax();
    }else{
      this.addWithHoldingTax();
    }
  }

  welfareFundSwitchEnabled(){
    if(this.enablewelfarefund){
      if(this.iswelfarefund){
          this.removeWelfareFund();
      }else{
        this.addWelfareFund();
      }
    }
  }


  addDeadline(date,description='',methodpayment='',amount=0,tax=0){

    try {
      Globals.modal.showModal(DeadlineDetailsComponent,[
        {"name":"mode","value":"modal"},
        {"name":"reference","value":this.record},
        {"name":"table","value":"documents"},
        {"name":"amount","value":amount},
        {"name":"tax","value":tax},
        {"name":"date","value":Helper.convertDateControl(date)},
        {"name":"methodpayment","value":methodpayment},
        {"name":"description","value":description}],()=>{        
      },"xl");
    }
    catch (e)
    {
      console.log(e);
      Globals.message.showToaster("Errore nella creazione della scadenza",ToastMode.DANGER);
    }

  }
  

  verifyBillingAddress(r){
    let message:any=[];
  
    if(this.record.id_type==1 && (this.record.typedocument==1 || this.record.typedocument==2 ) || this.record.typedocument==3 || this.record.typedocument==12){

      if(r.nation && r.nation == 'IT'){
        if(!r.tax_code && !r.vat_number){
          message.push('codice fiscale e partita IVA assenti');
        }
        if(r.tax_code && !this.verifyFiscalCodeService.validate(r.tax_code)){
          message.push('codice fiscale errato');
        }
        if(r.vat_number && !this.verifyVATNumberService.validate(r.vat_number)){
          message.push('partita IVA errata');
        }
        if(!r.address || !r.city || !r.country || r.zip.toString().length < 5){
          message.push('indirizzo di fatturazione incompleto');
        }
        if(!r.pec && r.sdi.toString().length < 6){
          message.push('codice SdI o PEC errati o mancanti');
        }
      }
    
      if(r.nation && r.nation != 'IT'){
        if(r.tax_code!='XXXXXXXXXXX' || r.vat_number!='XXXXXXXXXXX'){
          message.push('codice fiscale o partita IVA non corretti');
        }  
        if(!r.address || !r.city){
          message.push('indirizzo di fatturazione incompleto');
        }
        if(r.pec || r.sdi!='XXXXXXX'){
          message.push('codice SdI o PEC errati');
        }
      }
    
      if(!r.methodpayment || !r.paymentschedule){
        message.push('metodo di pagamento o data di scadenza del pagamento assenti');
      }

    }
    
    return message;
  }


  openModalCausals(){
    this.modalCausalsRef=this.modalService.open(this.modalCausals);
  }

  closeModalCausals(){
    this.module.save();   
    this.modalCausalsRef.close("success");
  }

  deleteCausal(causal){
    for(let i=0;i<this.listDescription.length;i++){
      if(this.listDescription[i]==causal){
        this.listDescription.splice(i,1);
        return;
      }
    }
  }

  addCausal(){
    let c:any={};
    c.description="";
    this.listDescription.push(c);
  }


  openModalBanks(){
    this.modalBanksRef=this.modalService.open(this.modalBanks);
  }

  closeModalBanks(){
    this.module.save();   
    this.modalBanksRef.close("success");
  }

  deleteBank(bank){
    for(let i=0;i<this.banks.length;i++){
      if(this.banks[i]==bank){
        this.banks.splice(i,1);
        return;
      }
    }
  }

  addBank(){
    let c:any={};
    c.name="";
    c.iban="";
    this.banks.push(c);
  }

  searchShop(){
    let id_address=this.record.id_address;
    Globals.modal.showModal(ShopsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id_address","value":id_address}
    ],(instance)=>{
      if(instance){
        let record=instance['recordSelected'];
        this.record.name_destination=record.name;
        this.record.address_destination=record.address;
        this.record.city_destination=record.city;
        this.record.country_destination=record.country;
        this.record.zip_destination=record.zip;
      }
    })
  }


}
