import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { Helper } from '../helper';
import { WSService } from '../include/service'
import { Action } from '../include/structures';
import { CustomfieldsService } from './customfields.service';
import { CustomfunctionsService } from './customfunctions.service';


@Injectable({
  providedIn: 'root'
})
export class ActionsService extends WSService<Action> {

  static actions=[];
  constructor() { super("actions")};

  getRule(table:string,record:any,oncomplete:Function,action_request="INSERT"){

    let onExit=true;
    

    //verifica se ci sono delle regole per il record da imporre
    //Globals.ws.requestUrl<Action[]>("actions","getItems",["a.table='"+table+"'"]).subscribe((items)=>{
      //verifica  le condizioni
    this.getAction(table,(items:Action[])=>{
      for(let i=0;i<items.length;i++){
        items[i].conditionsArray=JSON.parse(items[i].conditions);
        let verify:boolean=true;
        for(let j=0;j<items[i].conditionsArray.length;j++){
          let s=Helper.getFieldbyString(items[i].conditionsArray[j].field,record);

          switch(items[i].conditionsArray[j].condition){
            case "ugual":
              if(Helper.getFieldbyString(items[i].conditionsArray[j].field,record)!=items[i].conditionsArray[j].value && verify)
                verify=false;
                
              break;

            case "major":
              if(Helper.getFieldbyString(items[i].conditionsArray[j].field,record)<=items[i].conditionsArray[j].value && verify)
                verify=false;
                
              break;

              case "minor":
                if(Helper.getFieldbyString(items[i].conditionsArray[j].field,record)>=items[i].conditionsArray[j].value && verify)
                  verify=false;
                  
                break;
          }

          
          //if(Globals.app.replaceKeyword(record[items[i].conditionsArray[j].field],record)!=items[i].conditionsArray[j].value && verify==true)
            //verify=false;
        }

        if(verify){
          
         //fai l'azione
         let action=JSON.parse(items[i].action);

         let v:Object={} as Object;
         let where:string[]=[];
         if(action.fields){
          for(let f of action.fields){
            v[f.name]=Helper.replaceKeyword(f.value,record);
            where.push(f.name+"='"+v[f.name]+"'");
          }
        }

         v['id_address']=Helper.searchKey("id_address",record);
         let actions_response=[];
         switch (action.action){
           case "EDIT":
            if(action_request=="EDIT"){
              onExit=false;
              //esegui funzione
              let cfs:CustomfunctionsService=new CustomfunctionsService();
              //parserizza i parametri (se esistono)
              let params=null;
              if(action.params){
                params=Helper.replaceKeyword(action.params,record);
              }
              try{
                cfs.executeFunction(action.id_function,params).subscribe((result)=>{
                    if(result){
                      let action_response:any={};
                      action_response.id=action.id_function;
                      action_response.result=result;
                      actions_response.push(action_response);
                    }
                    if(oncomplete) oncomplete(actions_response);
                });
              }catch{
                if(oncomplete) oncomplete(false);
              }
            }

            break;
           case "UPDATE":
           case "INSERT":
            if(action_request=="INSERT"){
              onExit=false;
              //verifica se la voce è già stata inserita
              Globals.ws.requestUrl(action.table,"getItem",[where.join(" AND ")]).subscribe(((item)=>{
                if(item==null)
                  if(confirm("Salvare i valori anche nella tabella "+action.table+" ?"))
                    Globals.ws.send(action.table,"save",v,[],(return_id)=>{
                      console.log(return_id);
                    },"Valori aggiunti nella tabella "+action.table);
              }))

              if(oncomplete) oncomplete(true);

            }
             
             break;
          
         }
         
         


          
        }
        
         
      }
      if(onExit)
        if(oncomplete) 
          oncomplete(false);

    });
  }

  getAction(table:string,oncomplete){
    if(ActionsService.actions.length>0){

      for(let a of ActionsService.actions){
        if(a.table==table){
          oncomplete(a.actions);
          return;
        }
      }
   
    }
    Globals.ws.requestUrl<Action[]>("actions","getItems",["a.table='"+table+"'"]).subscribe((items:Action[])=>{
      let a:any={};
      a.table=table;
      a.actions=[];

      for(let i of items){
        let act:Action=new Action();
        Object.assign(act,i);
        //act.parseCondition();
        a.actions.push(act);
      }

      let found=false;
      for(let a of ActionsService.actions){
        if(a.table==table){
          found=true;
        }
      }
      if(!found)
        ActionsService.actions.push(a);
      oncomplete(a.actions);
    });
    
   
    
  }
}
