import { Injectable } from '@angular/core';

import { User } from "./include/structures";
import { WebserverService } from "./lib/webserver.service";
import { NavigationHelper } from "./navigation";
import { Parameters } from "./include/parameters";
import { Message } from "./message";
import { ModalWindow } from "./modal";
import { Config } from "./config";
import { Access } from "./access";
import { C1Connector } from './c1connector';

@Injectable()
export class Globals {

    static readonly:boolean=false;
    static total_space:number=0;
    static total_occupied_space:number=0;
    static title:string;
    static user:User;
    static isLogin:boolean=false;
    static ws:WebserverService;
    static navigation:NavigationHelper;
    static parameters:Parameters=new Parameters();
    static message:Message;
    static modal:ModalWindow;
    static config:Config;
    static access:Access;
    static rootpath="";
    static events:any[]=[];
    static c1connector:C1Connector;//=new C1Connector();
    static sections:any[]=[];
    //#region MODAL
    constructor(
      private webserverService:WebserverService,
      private config:Config,
      private access:Access,
      private navigation:NavigationHelper,
      private message:Message,
      private modal:ModalWindow,
      private c1connector:C1Connector
    ){
      Globals.ws=this.webserverService;
      Globals.config=this.config;
      Globals.access=this.access;
      Globals.navigation=this.navigation;
      Globals.message=this.message;
      Globals.modal=this.modal;
      Globals.c1connector=this.c1connector;
      Globals.user=new User();
      


      
    }      

    
  
    //#endregion

    //#region COMPONENT and VISUALIZATION
    static loading:boolean=false; //visualizza il loading
    static loadingstatusbar:boolean=false; //visualizza il loading nello statusbar
    static loadingstatusbarmessage:string="Elaborazione in corso...";
    static currentModel;
    static lastSearchIDS:number[]=[]; //ultima ricerca effettuata
      //ultimo record di dettaglio aperto (necessario per passare i record di riferimento)
    static lastDetailRecord:any; 
    static lastDetailRecord_id:number;
    static lastDetailRecord_table:string;
    //#endregion

    static setLoading(value){
      setTimeout(()=>{
        Globals.loading=value;
   
      },200)
    }

    static setLoadingStatusBar(value,message=""){
      setTimeout(()=>{
        Globals.loadingstatusbar=value;
        Globals.loadingstatusbarmessage=message;
      },200)
    }

    static setTitle(value){
      setTimeout(()=>{
        Globals.title=value;
   
      },200)
    }
    
    static addSection(table,sectionname){
      Globals.sections.push({"table":table,"name":sectionname});
    }
    
}