import { Component, OnInit, ViewChild } from '@angular/core';
import { WebcamComponent, WebcamImage, WebcamInitError } from 'ngx-webcam';

@Component({
  selector: 'lib-takephoto',
  templateUrl: './takephoto.component.html',
  styleUrls: ['./takephoto.component.css']
})
export class TakephotoComponent implements OnInit {

  @ViewChild(WebcamComponent)
  webcam:WebcamComponent;

  imageCaptured;

  constructor() { }

  ngOnInit(): void {
    console.log("Camera started");
  }

  take(){
    this.webcam.imageCapture.subscribe((image:WebcamImage)=>{
      this.imageCaptured=image.imageAsBase64;
      this['modalWindow'].close("success");
    });
    this.webcam.takeSnapshot()
    
  }

  cancel(){
    this['modalWindow'].close("cancel");
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      console.warn("Camera access was not allowed by user!");
    }
  }

}
