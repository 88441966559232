import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Observable } from 'rxjs';
import { CashRegister } from '../db/cashregister';

@Injectable({
  providedIn: 'root'
})
export class CashregisterService extends WSService<CashRegister> {

  constructor() { super("cashregister")}

  getCountPayment(date,created_from):Observable<any>{
    return Globals.ws.requestUrl<any>("cashregister","getCountPayment",[date,created_from]);
  }
}
