import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Modal} from '../../include/modal';
import {Template} from '../../include/structures';
import {TemplatesService} from '../../lib/templates.service';
@Component({
  selector: 'app-selecttemplate',
  templateUrl: './selecttemplate.component.html',
  styleUrls: ['./selecttemplate.component.sass']
})
export class SelecttemplateComponent extends Modal implements OnInit {
  @ViewChild("modalRequestParams")
  modalRequestParams:TemplateRef<any>;
  modalRequestParamsRef;
  @Input()
  templates:Template[]=[];
  selectedTemplate:Template;
  table:string;
  section:string="";

  arguments:string[];
  detail:boolean=false;

  constructor(private templatesService: TemplatesService,private modalService:NgbModal) { super() }

  ngOnInit(): void {
    if(this.templates==null || this.templates.length==0){
      this.templatesService.getAllTemplates(this.table,this.section).subscribe((items)=>{
        this.templates=items;
        if(this.templates.length>0)
          this.selectedTemplate=this.templates[0];
      });
    }


  }

  getTemplate(template){
    
      this.selectedTemplate=template;
      
      try{
        this.selectedTemplate.requestparamsArray=JSON.parse(this.selectedTemplate.requestparams);

        //aggiungi il campo value all'array
        for(let i=0;i<this.selectedTemplate.requestparamsArray.length;i++){
          this.selectedTemplate.requestparamsArray['value']="";
        }

      }catch{
        this.selectedTemplate.requestparamsArray=[];
      }
      
      if(this.selectedTemplate.requestparamsArray.length>0){
        this.openRequestParams();
      }else{
        this['modalWindow'].close('success');
      }

      
  }

  openRequestParams(){
    this.modalRequestParamsRef=this.modalService.open(this.modalRequestParams);
  }

  confirmRequestParams(){
    
    this.selectedTemplate.requestparams=JSON.stringify(this.selectedTemplate.requestparamsArray);
    this['modalWindow'].close('success');
    this.closeRequestParams()
  }

  closeRequestParams(){
    this.modalRequestParamsRef.close("success");
  }

}
