import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Activities } from 'projects/c1-backend/src/app/db/activities';
import { Installation } from 'projects/c1-backend/src/app/db/installation';
import { Globals } from 'projects/core/src/globals';
import { InventoriesDetailsService } from 'projects/c1-backend/src/app/services/inventoriesdetails.service';
import { Filter, FilterMode } from 'projects/core/src/include/structures';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'user-action-substitution',
  templateUrl: './substitution.component.html',
  styleUrls: ['./substitution.component.css']
})
export class SubstitutionComponent implements OnInit {

  @ViewChild("modalListInventory")
  modalListInventory:TemplateRef<any>;
  modalListInventoryRef;

  @Input()
  mode="modal";

  @Input()
  item:Installation=new Installation();

  @Input()
  activity:Activities=new Activities();
  
  products=[];
  serials=[];

  product_selected;

  listInventoryStep=1;

  constructor(    
    private inventoriesDetailsService:InventoriesDetailsService,
    private modalService:NgbModal
    ) { }

  ngOnInit(): void {
    if(this.activity.user_action==null || this.activity.user_action=='null'){
      this.activity.user_action={};
      this.activity.user_action['table']="installations";
      this.activity.user_action['action']="substitution";
      this.activity.user_action['products']=[];

      this.addProduct(this.item.sn);
    }
    
    
  }

  addProduct(sn:string=""){
    let p={};
    p['oldproduct']=sn;
    p['newproduct']="";
    this.activity.user_action['products'].push(p);
  }

  removeProduct(p){
    for(let i=0;i<this.activity.user_action['products'].length;i++){
      if(this.activity.user_action['products'][i]==p){
        this.activity.user_action['products'].splice(i,1);
        return;
      }
    }
  }
  loadInventory(){

    let id_inventory=Globals.user.getParam("id_inventory",0);


    //prendi tutti i prodotti disponibili in magazzino
    let filter_id_inventory:Filter=new Filter();
    let filter:Filter[]=[];
    filter_id_inventory.mode=FilterMode.normal;
    filter_id_inventory.fields=[];
    filter_id_inventory.fields.push("ps.id_inventory");
    filter_id_inventory.value=id_inventory.toString();
    filter.push(filter_id_inventory);

    let filter_stock:Filter=new Filter();
    filter_stock.mode=FilterMode.major;
    filter_stock.fields=[];
    filter_stock.fields.push("ps.stock");
    filter_stock.value="0";
    filter.push(filter_stock);

    /*
    let filter_status:Filter=new Filter();
    filter_status.mode=FilterMode.normal;
    filter_status.fields=[];
    filter_status.fields.push("ps.status");
    filter_status.value="1";
    filter.push(filter_status);*/

    this.inventoriesDetailsService.getProductAvaible(filter,[],0,1000000).subscribe((items)=>{
      this.products=items['value'];
    });
  }

  cancel(){
    this['modalWindow'].close();
  }

  confirm(){
    this.cancel();
  }

  searchInventory(p){
    this.product_selected=p;
    this.loadInventory();
    this.listInventoryStep=1;
    this.modalListInventoryRef=this.modalService.open(this.modalListInventory);
    /*
    Globals.modal.showModal(InventoryuserComponent,[],(instance)=>{
      if(instance){
        this.activity.user_action['newproduct']=instance['recordSelected']['sn'];
      }
    });
    */
  }

  selectProduct(product){

    if(product.serials.length>1){
      this.listInventoryStep=2;
      this.serials=product.serials;
    }else{
      this.selectSn(product.serials[0].sn);
    }
    
  }

  selectSn(sn){
   this.product_selected.newproduct=sn;
    this.closeModalInventory();
  }

  closeModalInventory(){
    this.modalListInventoryRef.close("success");
  }

}
