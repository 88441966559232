import { CommonModule } from '@angular/common';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './modal.component';


@NgModule({
   exports: [ModalComponent],
   declarations: [ModalComponent],
   imports:[CommonModule,FormsModule],
   schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
   providers: [],
   
})
export class ModalModule{ }