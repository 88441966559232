<app-windowlist [model]="this">
    


    <div class="row my-1" >
        <div class="col-lg-2 pr-lg-0" *ngIf="filter_box && viewMode=='table'">
            <small>
                <app-filterdate [date_type]="default_filterdate" [date_from]="date_from" [date_to]="date_to" [future]="true" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="updateList()"></app-filterdate>
                <ul class="card small-box list-group p-1 mt-2">
                    <li class="list-group-item pointer" [class.active]="filter_id_type.value==''" (click)="filter_id_type.value='';getItems()">Tutte le tipologie</li>
                    <li class="list-group-item pointer" [class.active]="filter_id_type.value==t.id" *ngFor="let t of type_deadlines" (click)="filter_id_type.value=t.id;getItems()">{{t.name}}</li>
                    <div class="icon"><i class="far fa-calendar-check"></i></div>
                </ul>
                <ul class="card small-box list-group p-1 my-2" *ngIf="methodpayments.length>0" >
                    <li class="list-group-item text-truncate pointer" [class.active]="filter_method_payment.value==''" (click)="filter_method_payment.value='';getItems()">Tutte le modalità di pagamento</li>
                    <li class="list-group-item text-truncate pointer" [class.active]="filter_method_payment.value && filter_method_payment.value.indexOf(m.name)>-1" *ngFor="let m of methodpayments"  (click)="setFilterTypeValue('filter_method_payment',m.name);">{{m.name}}</li>
                    <div class="icon"><i class="fas fa-dollar-sign"></i></div>
                </ul>
            </small>
        </div>
        <div [class.col-lg-12]="!filter_box || viewMode!='table'" [class.col-lg-10]="filter_box">
            <div class="card card-outline mb-0">
                <div class="p-0">
                    <div class="row p-1" >
                        <div class="col-3"></div>
                        <div class="col-6">

                            <select class="form-control form-control-sm" name="filter_id_type_product" [(ngModel)]="filter_id_type_product.value" (ngModelChange)="updateWhereJoin()">
                                <option  value="">Tutte le tipologie di prodotti</option>
                                <option *ngFor="let t of type_products" value="{{t.id}}">{{t.name}}</option>
                            </select>

                            <ng-container *ngIf="filter_box && viewMode!='calendar'">
                                <div class="input-group input-group-sm" >
                                    <input type="text" #table_search name="table_search" class="form-control float-right" placeholder="cerca..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                    <div class="input-group-append ">
                                        <button type="submit" class="btn btn-default"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                        <button class="btn btn-default text-danger"  title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div [class.col-3]="filter_box" [class.col-12]="!filter_box" *ngIf="mode!='modal'" class="py-1">

                           <!--
                            <div class="dropdown dropleft show"  *ngIf="user.isAdmin()">
                                <a class="btn btn-default btn-xs float-right ml-1" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" title="Altre operazioni" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v"></i>
                                </a>
                                <div class="dropdown-menu py-0" aria-labelledby="dropdownMenuLink">
                                    <small>
                                        <button type="button" *ngIf="user.isAdmin() && isModuleEnabled('financialaccounts')" class="dropdown-item" title="Assegna gli elementi selezionati ad un conto contabile" (click)="assignFinancialAccount()">Assegna conto contabile</button>
                                        <button type="button" class="dropdown-item" title="Imposta tutti gli elementi selezionati come tipologia di movimento in uscita" (click)="setTypeAmountOut()">Imposta come movimento di uscita</button>
                                        <button type="button" class="dropdown-item" title="Imposta tutti gli elementi selezionati come tipologia di movimento di ingresso" (click)="setTypeAmountIn()">Imposta come movimento di ingresso</button>
                                       
                                    </small>
                                </div>
                            </div>
                           -->
                            <!-- <button type="button" (click)="delete(0,true)" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>-->
                            <button type="button"  title="Elimina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 "><i class="far fa-trash-alt"></i></button>
                            <button type="button"  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 "><i class="fas fa-trash"></i></button>
                            <button type="button"  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-undo"></i></button>
                           <!-- <button type="button"  title="Converte le scadenze selezionate in attività" (click)="convertDeadlinesIntoActivities()" [disabled]="form.value.id.length == 0" class="btn btn-primary btn-xs float-right ml-1 "><i class="fas fa-sync-alt"></i></button>
                           -->
                            <button type="button"  title="Esporta" *ngIf="toolbar_action" (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-file-export"></i></button>
                            <button type="button"  title="Aggiungi" *ngIf="toolbar_action" (click)="openDetail(null,[{'name':'id_address','value':id_address},{'name':'id_table','value':id_table},{'name':'table','value':table},{'name':'reference','value':reference},{'name':'amount','value':amount},{'name':'tax','value':tax},{'name':'methodpayment','value':methodpayment},{'name':'date','value':date}])" 
                                class="btn btn-success btn-xs float-right ml-1"><i class="fas fa-plus"></i></button>
                           <!-- <button type="button"  title="Crea scadenze multiple" *ngIf="toolbar_action" (click)="openGenerateInstallments()" class="btn btn-success btn-xs text-yellow float-right ml-1 "><i class="fas fa-hat-wizard"></i></button>
                            -->
                        </div>
                    </div>

                    <app-deadlines-calendar *ngIf="viewMode=='calendar'" [table]="'installations'" [join]="'installations as i ON i.id=d.id_table'" [where_join]="where_join"></app-deadlines-calendar>
              
                    <app-deadlines-list *ngIf="viewMode=='table'"  [list]="list" [viewMode]="viewMode" (updateList)="updateList()" [form]="form"></app-deadlines-list>

                </div>
                <div class="card-footer clearfix py-1" *ngIf="showFooter && viewMode!='calendar'" >
                    <app-pagination  [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>

</app-windowlist>
