import { Component, HostListener, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'projects/core/src/common/modal/modal.component';
import { KeyboardComponent } from '../../../modules/keyboard/keyboard.component';

@Component({
  selector: 'app-methodpayment',
  templateUrl: './methodpayment.component.html',
  styleUrls: ['./methodpayment.component.css']
})
export class MethodpaymentComponent implements OnInit {
  @ViewChild('modal', { static: false })
  modal: ModalComponent ;

  @ViewChild("paidInput")
  paidInput:ElementRef<any>;

  @ViewChild(KeyboardComponent)
  keyboard:KeyboardComponent;

  listMethods=[];

  @Input()
  total:number;

  paid:number=0;

  get total_paid(){
    let t=0;
    for(let m of this.listMethods){
      t=t+m.amount;
    }
    return t;
  }

  get total_remain(){
    let t=0;
    for(let m of this.listMethods){
      t=t+m.amount;
    }
    return this.total-t;
  }

  constructor(
    private modalService:NgbModal
  ) { }
  method_payment="";
  ngOnInit(): void {
    this.paid=this.total;
  }

  selectMethod(m){

    let i={};
    i['methodpayment']=m;
    i['amount']=parseFloat(this.paid.toString());
    this.listMethods.push(i);
    this.keyboard.clear();
    this.paid=this.total_remain;
    this.paidInput.nativeElement.focus();
    this.paidInput.nativeElement.select();
    
    //this.method_payment=m;
    


    //this.modal.activeModal.close("success");
  }

  removePayment(m){
    for(let i=0;i<this.listMethods.length;i++){
      if(this.listMethods[i]==m){
        this.listMethods.splice(i,1);
        this.paid=this.total_remain;
        this.paidInput.nativeElement.focus();
        this.paidInput.nativeElement.select();
        return;
      }
    }
  }


  confirm(){
    if(this.total_remain<=0) {
      this['modalWindow'].close("success");
    }
  }


  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {

    if(event.keyCode==113){ //F2
      if(this.total_remain>0) {
        this.selectMethod('Contanti');
      }
    }

    if(event.keyCode==115){ //F4
      if(this.total_remain>0) {
        this.selectMethod('POS');
      }
    }

    if(event.keyCode==117){ //F6
      if(this.total_remain>0) {
        this.selectMethod('Tickets');
      }
    }

    if(event.keyCode==119){ //F8
      if(this.total_remain<=0) {
        this.confirm();
      }
    }
    
  }

}
