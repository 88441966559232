import { NgModule } from '@angular/core';
import { MoveinventoryComponent } from './moveinventory.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SearchproductModule } from '../searchproduct/searchproduct.module';
import { ProductitemModule } from '../productitem/productitem.module';


@NgModule({
    imports: [FormsModule,CommonModule],
   exports: [MoveinventoryComponent],
   declarations: [MoveinventoryComponent],
   providers: [],
})
export class MoveinventoryModule{ }