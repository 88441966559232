
<div *ngIf="softwarebackground" style="position:fixed;
            top:0; left:0;
            width:100%; height:100%; 
            z-index:0;
            background-image:url('{{softwarebackground}}');
            background-size: cover;
            background-color:#a8b6c4" 
            [style.background-color]="softwarebackground" >
</div>
<form>
 <div class="login-box-body login-card-body elevation-1">
    <div class="login-logo">
        <img src="{{softwareicon}}" alt="" class="brand-image" style="height: 80px;">
        <p>{{softwarename}}</p>        
    </div>
    <div class="input-group mb-3">
        <input type="text" class="form-control"  placeholder="La tua Username" name="uname" [(ngModel)]="uname" (keyup.enter)="onLogin()">
        <div class="input-group-append">
            <div class="input-group-text border">
            <span class="fas fa-user text-light"></span>
            </div>
        </div>
    </div>
    <div class="input-group mb-3">
        <input type="password" autocomplete class="form-control"  placeholder="La tua Password" name="passwd" [(ngModel)]="passwd" (keyup.enter)="onLogin()">
        <div class="input-group-append">
            <div class="input-group-text border">
            <span class="fas fa-key text-light"></span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
        <button class="btn btn-primary btn-block" (click)="login()">Accedi</button>
        </div>
        <div class="col-2"></div>
    </div>
    <div class="row mt-3">
        <div class="col-12 text-center">
            <input class="mb-0" type="checkbox" name="remember_connected" [(ngModel)]="remember_connected"><small>&ensp;Ricordati di me</small>
        </div>
    </div>
 </div>
</form>
<div *ngIf="copyright" class="animation__shake fixed-bottom text-light text-xs text-center m-3">
    <b>C1</b> is a <a class="text-light" href="http://codingict.it" target="_blank"><b>Coding</b></a> software
</div>
