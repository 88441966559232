import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { EditTableHeaderComponent } from './edit-table-header.component';
import {ModalModule} from '../modal/modal.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


@NgModule({
   declarations: [EditTableHeaderComponent],
   imports:[CommonModule,FormsModule,ModalModule],
   exports: [EditTableHeaderComponent],
   schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

   providers: [],
   
})
export class EditTableHeaderModule{ 

}
