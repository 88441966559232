import { CommonModule } from '@angular/common';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ModalModule } from '../modal/modal.module';
import { TemplateSearchModule } from '../template-search/template-search.module';
import { WindowlistComponent } from './windowlist.component';


@NgModule({
    imports: [CommonModule,TemplateSearchModule],
   exports: [WindowlistComponent],
   declarations: [WindowlistComponent],
   providers: [],
})
export class WindowlistModule{ }