import { Injectable } from '@angular/core';
import {WSService} from 'projects/core/src/include/service'
import { Contact } from '../db/contact';
import { Observable } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
@Injectable({
  providedIn: 'root'
})
export class ContactsService extends WSService<Contact> {

  constructor() {super("contacts") }

  getContacts(table,id):Observable<any>{
    return  Globals.ws.requestUrl<any>("contacts","getItems",["table="+table+" AND id_table="+id]);
 
  }

  
}

