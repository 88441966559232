import { Component, OnInit, ViewChild, Output,EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { StripeCardNumberComponent } from 'ngx-stripe';

import { StripeService } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
  PaymentIntent,
} from '@stripe/stripe-js';
import { Globals } from '../../globals';


@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.css']
})
export class StripeComponent implements OnInit {
  
  @Output()
  onsuccess:EventEmitter<any>=new EventEmitter();

  @Output()
  onbeforepay:EventEmitter<any>=new EventEmitter();
  
  @Output()
  onerror:EventEmitter<string>=new EventEmitter();

  @Output()
  oninvalid:EventEmitter<string>=new EventEmitter();
  
  @Input()
  amount:number=0;

  @Input()
  showPayButton:boolean=true;

  @Input()
  class:string="";

  @Input()
  stripe_public_key:string="";

  @Input()
  buttonTitle="Paga ora";

  @Input()
  disableButton:boolean=false;

  @Input()
  beforePay:Function=null;

  @Input()
  enablePayment=true;


  @Output()
  paymentMethodId:EventEmitter<string>=new EventEmitter();


  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        backgroundColor:'white',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    
  };

  stripeTest: UntypedFormGroup;

  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    public stripeService: StripeService
  ) {}

  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
    });
    this.stripeService.changeKey(Globals.config.config['stripe_api_key']);
  }

  pay(): void {

    this.onbeforepay.emit();
   
    if (this.stripeTest.valid) {
      
      this.stripeService.createPaymentMethod({
        type:"card",
        card:this.card.element,
        billing_details: {
          name: this.stripeTest.get('name')?.value,
        }
      }).subscribe((result)=>{
        if (result.error) { // Show error to your customer (e.g., insufficient funds)
          
          this.onerror.emit(result.error.message);
        } else {
          
          this.paymentMethodId.emit(result.paymentMethod.id);
          // The payment has been processed!
          console.log(result);
        }
      });


      
     
    } else {
      this.oninvalid.emit();
    }
  }
  
  

  private handleError(error: HttpErrorResponse) {
    Globals.setLoading(false);
        console.error('An error occurred:', error.message);
        alert(error.message);
      return throwError(
        'Something bad happened; please try again later.');
      
    }

  
  
  

}
