import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';
import { StatedocumentComponent } from './statedocument.component';


@NgModule({
    imports: [FormsModule,CommonModule,ModalModule],
   exports: [StatedocumentComponent],
   declarations: [StatedocumentComponent],
   providers: [],
})
export class StatedocumentModule{ }