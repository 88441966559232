import { NgModule } from '@angular/core';
import { GoodsComponent } from './goods.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SearchproductModule } from '../searchproduct/searchproduct.module';
import { ProductitemModule } from '../productitem/productitem.module';
import { MoveinventoryModule } from '../moveinventory/moveinventory.module';


@NgModule({
    imports: [FormsModule,CommonModule,SearchproductModule,ProductitemModule,MoveinventoryModule],
   exports: [GoodsComponent],
   declarations: [GoodsComponent],
   providers: [],
})
export class GoodsModule{ }