import { Component, OnInit,Input, Output } from '@angular/core';
import { UntypedFormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Type } from 'projects/c1-backend/src/app/db/type';
import { TypesService } from 'projects/c1-backend/src/app/services/types.service';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode } from 'projects/core/src/include/structures';

@Component({
  selector: 'app-types',
  templateUrl: './types.component.html',
  styleUrls: ['./types.component.sass']
})

export class TypesSettingsComponent extends ModelList<Type> implements OnInit {

 
  
 
  filter_type:Filter=new Filter();
  filter_search:Filter=new Filter();

  selectedType:Type={} as Type;

 
  constructor(
    private fb1: UntypedFormBuilder,
    private typesService: TypesService,
    
  ) {
    super(typesService,fb1);
   }


  ngOnInit(): void {
    this.title='Tipologie';
    super.ngOnInit.apply(this, arguments); 
    
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("t.name");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_type.mode=FilterMode.normal;
    this.filter_type.fields=[];
    this.filter_type.fields.push("t.table");
    this.filter_type.value="";
    this.filter.push(this.filter_type);

    this.ordering.field="t.id";

    this.getItems();
 
    
  }

  setRule(record:Type){
    
    this.selectedType=Object.assign({}, record);

    
  }

  confirmRule():void{
    
    this.typesService.save(this.selectedType,(id)=>{
      this.getItems();
    });
  }

  addType(){
    let r:Type={} as Type;
    r.published=true;

    
    if(this.filter_type.value!="")
      r.table=this.filter_type.value;
    this.selectedType=r;
  }

  confirm_delete(id){
    confirm("Eliminare l'elemento?")?this.delete(id):false;
  }

 
}
