<div class="row mb-1" *ngFor="let record of list">
    <div class="col-md-5 pr-0">
        <input id="inpudivescription" class="form-control form-control-sm" [(ngModel)]="record.note" placeholder="Descrizione...">
    </div>
    <div class="col-md-2 px-1">
        <select class="form-control form-control-sm"  #type_contact [(ngModel)]="record.id_type"  title="{{record.id_type}}" >
            <option *ngFor="let t of type_contacts" value="{{t.id}}" >{{t.name}}</option>
        </select>
    </div>
    <div class="col-md-4 p-0">
        <div class="input-group">
            <input type="text" class="form-control form-control-sm" [(ngModel)]="record.value" placeholder="numero di telefono, indirizzo mail, ...">
            <!-- <div class="input-group-append" *ngIf="record.value">
                <a class="btn btn-sm btn-primary" *ngIf="record.id_type == 7" href="mailto:{{record.value}}"  title="scrivi mail">
                    <i class="far fa-envelope"></i></a>
                <a class="btn btn-sm btn-success" *ngIf="record.id_type == 6" target="_blank" href="https://api.whatsapp.com/send?phone=39{{record.value}}"  title="apri Chat" type="button">
                    <i class="fab fa-whatsapp"></i></a>
            </div>-->
        </div>
    </div>
    <div class="col-md-1 text-right">
        <a class="btn btn-sm btn-danger btn-block" title="Elimina" (click)="deleteRecord(record)"><i class="far fa-trash-alt"></i></a>
    </div>
</div>
<a (click)="newRecord()" class="btn btn-sm btn-success btn-block"  title="Aggiungi"><i class="fa fa-plus-circle mr-2"></i>Aggiungi</a>











<!-- 
        <table class="table table-sm table-head-fixed text-nowrap">
            <thead>
                <tr>
                    <th>Tipo</th>
                    <th>Riferimento</th>
                    <th>Note</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            
                
                <tr *ngFor="let record of list">
                    <div class="border-0"><select class="custom-select" [(ngModel)]="record.id_type" ><option *ngFor="let t of type_contacts" value="{{t.id}}" >{{t.name}}</option></select></div>
                    <div class="border-0"><input type="text" class="form-control" [(ngModel)]="record.value" placeholder=" ..."></div>
                    <div class="border-0"><input type="text" class="form-control" [(ngModel)]="record.note" placeholder=" ..."></div>
                    <div class="border-0"><button class="btn btn-danger btn-sm float-right mt-1" (click)="deleteRecord(record)"><i class="fa fa-trash-alt"></i></button></div>
                </tr>
               
            </tbody>
        </table>

 -->
