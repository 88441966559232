<section class="content">
   
    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a class="nav-link" [class.active]="tab==1" (click)="tab=1">
                <i class="fas fa-list"></i><span class="d-none d-sm-inline-block ml-2">Lista</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [class.active]="tab==2" (click)="tab=2">
                <i class="fa fa-clock"></i><span class="d-none d-sm-inline-block ml-2">Moduli</span>
            </a>
        </li>
       
    </ul>
    <div class="tab-content"  >
        <div class="tab-pane fade show active" *ngIf="tab==1">
            <app-form-list  [modalWindow]="modalWindow" [mode]="mode"></app-form-list>
        </div>
        <div class="tab-pane fade show active" *ngIf="tab==2">
            <app-form-settings [modalWindow]="modalWindow" [mode]="mode"></app-form-settings>
        </div>
      
    </div>
</section>