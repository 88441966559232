import { Component, OnInit, Input } from '@angular/core';
import { CustomField } from '../../include/structures';
import {CustomfieldsService} from '../../lib/customfields.service';
import { TableColumn } from '../../include/modellist';
import { Modal } from '../../include/modal';
@Component({
  selector: 'app-edit-table-header',
  templateUrl: './edit-table-header.component.html',
  styleUrls: ['./edit-table-header.component.sass']
})
export class EditTableHeaderComponent implements OnInit {

  @Input()
  table:string;

  @Input()
  tableColumns:TableColumn[]=[];

  list:CustomField[]=[];

  constructor(
    private customfieldsService:CustomfieldsService
  ) { }

  ngOnInit(): void {
    this.customfieldsService.getCustomFields(this.table,false).subscribe((items)=>{
      this.list=items;

      //carica i valori predefiniti 
      if(this.tableColumns.length>0){
        for(let t of this.tableColumns){
          for(let l of this.list){
            if(t.label==l.label)
              l.selected=true;
          }
        }
      }
    });

    
    


  }

  confirm(){
    this['modalWindow'].close("success");
  }

}
