<select [class.section_disabled]="disabled" class="{{class}}" name="nation" [(ngModel)]="nation" (change)="onSelected($event)">
    <option value='IT' selected>Italia</option>
    <option value='AF'>Afghanistan</option>
    <option value='AL'>Albania</option>
    <option value='DZ'>Algeria</option>
    <option value='AD'>Andorra</option>
    <option value='AO'>Angola</option>
    <option value='AI'>Anguilla</option>
    <option value='AQ'>Antartide</option>
    <option value='AG'>Antigua e Barbuda</option>
    <option value='SA'>Arabia Saudita</option>
    <option value='AR'>Argentina</option>
    <option value='AM'>Armenia</option>
    <option value='AW'>Aruba</option>
    <option value='AU'>Australia</option>
    <option value='AT'>Austria</option>
    <option value='AZ'>Azerbaigian</option>
    <option value='BS'>Bahamas</option>
    <option value='BH'>Bahrein</option>
    <option value='BD'>Bangladesh</option>
    <option value='BB'>Barbados</option>
    <option value='BE'>Belgio</option>
    <option value='BZ'>Belize</option>
    <option value='BJ'>Benin</option>
    <option value='BM'>Bermuda</option>
    <option value='BT'>Bhutan</option>
    <option value='BY'>Bielorussia</option>
    <option value='MM'>Birmania</option>
    <option value='BO'>Bolivia</option>
    <option value='BA'>Bosnia ed Erzegovina</option>
    <option value='BW'>Botswana</option>
    <option value='BR'>Brasile</option>
    <option value='BN'>Brunei</option>
    <option value='BG'>Bulgaria</option>
    <option value='BF'>Burkina Faso</option>
    <option value='BI'>Burundi</option>
    <option value='KH'>Cambogia</option>
    <option value='CM'>Camerun</option>
    <option value='CA'>Canada</option>
    <option value='CV'>Capo Verde</option>
    <option value='TD'>Ciad</option>
    <option value='CL'>Cile</option>
    <option value='CN'>Cina</option>
    <option value='CY'>Cipro</option>
    <option value='VA'>Città del Vaticano</option>
    <option value='CO'>Colombia</option>
    <option value='KM'>Comore</option>
    <option value='KP'>Corea del Nord</option>
    <option value='KR'>Corea del Sud</option>
    <option value='CI'>Costa d'Avorio</option>
    <option value='CR'>Costa Rica</option>
    <option value='HR'>Croazia</option>
    <option value='CU'>Cuba</option>
    <option value='CW'>Curaçao</option>
    <option value='DK'>Danimarca</option>
    <option value='DM'>Dominica</option>
    <option value='EC'>Ecuador</option>
    <option value='EG'>Egitto</option>
    <option value='SV'>El Salvador</option>
    <option value='AE'>Emirati Arabi Uniti</option>
    <option value='ER'>Eritrea</option>
    <option value='EE'>Estonia</option>
    <option value='ET'>Etiopia</option>
    <option value='FJ'>Figi</option>
    <option value='PH'>Filippine</option>
    <option value='FI'>Finlandia</option>
    <option value='FR'>Francia</option>
    <option value='GA'>Gabon</option>
    <option value='GM'>Gambia</option>
    <option value='GE'>Georgia</option>
    <option value='GS'>Georgia del Sud e isole Sandwich meridionali</option>
    <option value='DE'>Germania</option>
    <option value='GH'>Ghana</option>
    <option value='JM'>Giamaica</option>
    <option value='JP'>Giappone</option>
    <option value='GI'>Gibilterra</option>
    <option value='DJ'>Gibuti</option>
    <option value='JO'>Giordania</option>
    <option value='GR'>Grecia</option>
    <option value='GD'>Grenada</option>
    <option value='GL'>Groenlandia</option>
    <option value='GP'>Guadalupa</option>
    <option value='GU'>Guam</option>
    <option value='GT'>Guatemala</option>
    <option value='GG'>Guernsey</option>
    <option value='GN'>Guinea</option>
    <option value='GQ'>Guinea Equatoriale</option>
    <option value='GW'>Guinea-Bissau</option>
    <option value='GY'>Guyana</option>
    <option value='GF'>Guyana francese</option>
    <option value='HT'>Haiti</option>
    <option value='HN'>Honduras</option>
    <option value='HK'>Hong Kong</option>
    <option value='IN'>India</option>
    <option value='ID'>Indonesia</option>
    <option value='IR'>Iran</option>
    <option value='IQ'>Iraq</option>
    <option value='IE'>Irlanda</option>
    <option value='IS'>Islanda</option>
    <option value='BV'>Isola Bouvet</option>
    <option value='CX'>Isola del Natale</option>
    <option value='IM'>Isola di Man</option>
    <option value='NF'>Isola Norfolk</option>
    <option value='AX'>Isole Åland</option>
    <option value='BQ'>Isole BES</option>
    <option value='KY'>Isole Cayman</option>
    <option value='CC'>Isole Cocos e Keeling</option>
    <option value='CK'>Isole Cook</option>
    <option value='FO'>Isole Fær Øer</option>
    <option value='FK'>Isole Falkland</option>
    <option value='HM'>Isole Heard e McDonald</option>
    <option value='MP'>Isole Marianne Settentrionali</option>
    <option value='MH'>Isole Marshall</option>
    <option value='UM'>Isole minori esterne degli Stati Uniti</option>
    <option value='PN'>Isole Pitcairn</option>
    <option value='SB'>Isole Salomone</option>
    <option value='TC'>Isole Turks e Caicos</option>
    <option value='VI'>Isole Vergini americane</option>
    <option value='VG'>Isole Vergini britanniche</option>
    <option value='IL'>Israele</option>    
    <option value='JE'>Jersey</option>
    <option value='KZ'>Kazakistan</option>
    <option value='KE'>Kenya</option>
    <option value='KG'>Kirghizistan</option>
    <option value='KI'>Kiribati</option>
    <option value='KW'>Kuwait</option>
    <option value='LA'>Laos</option>
    <option value='LS'>Lesotho</option>
    <option value='LV'>Lettonia</option>
    <option value='LB'>Libano</option>
    <option value='LR'>Liberia</option>
    <option value='LY'>Libia</option>
    <option value='LI'>Liechtenstein</option>
    <option value='LT'>Lituania</option>
    <option value='LU'>Lussemburgo</option>
    <option value='MO'>Macao</option>
    <option value='MK'>Macedonia del Nord</option>
    <option value='MG'>Madagascar</option>
    <option value='MW'>Malawi</option>
    <option value='MY'>Malaysia</option>
    <option value='MV'>Maldive</option>
    <option value='ML'>Mali</option>
    <option value='MT'>Malta</option>
    <option value='MA'>Marocco</option>
    <option value='MQ'>Martinica</option>
    <option value='MR'>Mauritania</option>
    <option value='MU'>Mauritius</option>
    <option value='YT'>Mayotte</option>
    <option value='MX'>Messico</option>
    <option value='MD'>Moldavia</option>
    <option value='MC'>Monaco</option>
    <option value='MN'>Mongolia</option>
    <option value='ME'>Montenegro</option>
    <option value='MS'>Montserrat</option>
    <option value='MZ'>Mozambico</option>
    <option value='NA'>Namibia</option>
    <option value='NR'>Nauru</option>
    <option value='NP'>Nepal</option>
    <option value='NI'>Nicaragua</option>
    <option value='NE'>Niger</option>
    <option value='NG'>Nigeria</option>
    <option value='NU'>Niue</option>
    <option value='NO'>Norvegia</option>
    <option value='NC'>Nuova Caledonia</option>
    <option value='NZ'>Nuova Zelanda</option>
    <option value='OM'>Oman</option>
    <option value='NL'>Paesi Bassi</option>
    <option value='PK'>Pakistan</option>
    <option value='PW'>Palau</option>
    <option value='PA'>Panama</option>
    <option value='PG'>Papua Nuova Guinea</option>
    <option value='PY'>Paraguay</option>
    <option value='PE'>Perù</option>
    <option value='PF'>Polinesia Francese</option>
    <option value='PL'>Polonia</option>
    <option value='PR'>Porto Rico</option>
    <option value='PT'>Portogallo</option>
    <option value='QA'>Qatar</option>
    <option value='GB'>Regno Unito</option>
    <option value='CZ'>Repubblica Ceca</option>
    <option value='CF'>Repubblica Centrafricana</option>
    <option value='CG'>Repubblica del Congo</option>
    <option value='CD'>Repubblica Democratica del Congo</option>
    <option value='TW'>Repubblica di Cina</option>
    <option value='DO'>Repubblica Dominicana</option>
    <option value='RE'>Riunione</option>
    <option value='RO'>Romania</option>
    <option value='RW'>Ruanda</option>
    <option value='RU'>Russia</option>
    <option value='EH'>Sahara Occidentale</option>
    <option value='KN'>Saint Kitts e Nevis</option>
    <option value='LC'>Saint Lucia</option>
    <option value='VC'>Saint Vincent e Grenadine</option>
    <option value='BL'>Saint-Barthélemy</option>
    <option value='MF'>Saint-Martin</option>
    <option value='PM'>Saint-Pierre e Miquelon</option>
    <option value='WS'>Samoa</option>
    <option value='AS'>Samoa Americane</option>
    <option value='SM'>San Marino</option>
    <option value='SH'>Sant'Elena, Isola di Ascensione e Tristan da Cunha</option>
    <option value='ST'>São Tomé e Príncipe</option>
    <option value='SN'>Senegal</option>
    <option value='RS'>Serbia</option>
    <option value='SC'>Seychelles</option>
    <option value='SL'>Sierra Leone</option>
    <option value='SG'>Singapore</option>
    <option value='SX'>Sint Maarten</option>
    <option value='SY'>Siria</option>
    <option value='SK'>Slovacchia</option>
    <option value='SI'>Slovenia</option>
    <option value='SO'>Somalia</option>
    <option value='ES'>Spagna</option>
    <option value='LK'>Sri Lanka</option>
    <option value='FM'>Stati Federati di Micronesia</option>
    <option value='US'>Stati Uniti d'America</option>
    <option value='PS'>Stato di Palestina</option>
    <option value='ZA'>Sudafrica</option>
    <option value='SD'>Sudan</option>
    <option value='SS'>Sudan del Sud</option>
    <option value='SR'>Suriname</option>
    <option value='SJ'>Svalbard e Jan Mayen</option>
    <option value='SE'>Svezia</option>
    <option value='CH'>Svizzera</option>
    <option value='SZ'>Swaziland</option>
    <option value='TJ'>Tagikistan</option>
    <option value='TZ'>Tanzania</option>
    <option value='IO'>Territori Britannici dell'Oceano Indiano</option>
    <option value='TF'>Territori Francesi del Sud</option>
    <option value='TH'>Thailandia</option>
    <option value='TL'>Timor Est</option>
    <option value='TG'>Togo</option>
    <option value='TK'>Tokelau</option>
    <option value='TO'>Tonga</option>
    <option value='TT'>Trinidad e Tobago</option>
    <option value='TN'>Tunisia</option>
    <option value='TR'>Turchia</option>
    <option value='TM'>Turkmenistan</option>
    <option value='TV'>Tuvalu</option>
    <option value='UA'>Ucraina</option>
    <option value='UG'>Uganda</option>
    <option value='HU'>Ungheria</option>
    <option value='UY'>Uruguay</option>
    <option value='UZ'>Uzbekistan</option>
    <option value='VU'>Vanuatu</option>
    <option value='VE'>Venezuela</option>
    <option value='VN'>Vietnam</option>
    <option value='WF'>Wallis e Futuna</option>
    <option value='YE'>Yemen</option>
    <option value='ZM'>Zambia</option>
    <option value='ZW'>Zimbabwe</option>
</select>