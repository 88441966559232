<app-window [title]="title">
    <form role="form" id="detailForm">
        <div class="row">
            <div class="col-12">
                <div class="form-group row">
                    <div class="col-2">
                        <label>Nome</label>
                    </div>
                    <div class="col-10">
                        <input type="text" class="form-control form-control-sm" name="name" [(ngModel)]="record.name">
                    </div>
                </div>

                <div class="form-group row mt-2" >
                    <div class="col-lg-2"><label>Logo</label></div>
                    <div class="col-lg-9">
                        <div class="input-group">
                            <input type="text" class="form-control form-control-sm" name="logo" [(ngModel)]="record.logo">
                            <input type="file" style="display: none;" #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files)">
                            <div class="input-group-append">
                                <a class="btn btn-info btn-sm" (click)="fileToUpload.click()"><i class="far fa-image mr-2"></i>Sfoglia</a>
                            </div>
                        </div>
                        <small><i>Inserisci un immagine dal <b>computer</b> oppure scrivi l'URL di un immagine dal <b>web</b>, da utilizzare come logo.</i></small>
                    </div>
                    <div class="col-lg-1">
                        <img class="w-100" src="{{record.logo}}">
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-2">
                        <label>Webservice Plugin</label>
                    </div>
                    <div class="col-10">
                        <select class="form-control form-control-sm" name="ws" [(ngModel)]="record.ws">
                            <option *ngFor="let w of wss" value="{{w}}">{{w}}</option>
                        </select>
                    </div>
                </div>
                <div class="card" *ngIf="record.ws=='OfflineWebservice'">
                    <div class="card-header">
                        <b>Linee</b>
                        <button class="btn btn-xs btn-success float-right" (click)="addRow()"><i class="fa fa-plus mr-2"></i>Aggiungi linea</button>
                    </div>
                    <div class="">
                        <div class="row" >
                            <div class="col-12">
                                <div class="" *ngFor="let route of record.routes;let ridx=index">
                                    <div class="card-header">
                                        <div class="row">
                                    
                                            <div class="col-1 pt-3">
                                                <i class="fas fa-2x fa-bus-alt"></i>
                                            </div>
                                            <div class="col-2">
                                                <small>Titolo</small>
                                                <input type="text" class="form-control form-control-sm" name="code{{ridx}}" [(ngModel)]="route.code">
                                            </div>
                                            <div class="col-4">
                                                <small>Descrizione</small>
                                                <input type="text" class="form-control form-control-sm" name="name{{ridx}}" [(ngModel)]="route.name">
                                            </div>
                                            <div class="col-4 pt-4">
                                                <input type="checkbox" [checked]="checkDay(route,1)" (click)="setDay(route,1,$event)"><small class="ml-1 mr-2">Lun</small>
                                                <input type="checkbox" [checked]="checkDay(route,2)" (click)="setDay(route,2,$event)"><small class="ml-1 mr-2">Mar</small>
                                                <input type="checkbox" [checked]="checkDay(route,3)" (click)="setDay(route,3,$event)"><small class="ml-1 mr-2">Mer</small>
                                                <input type="checkbox" [checked]="checkDay(route,4)" (click)="setDay(route,4,$event)"><small class="ml-1 mr-2">Gio</small>
                                                <input type="checkbox" [checked]="checkDay(route,5)" (click)="setDay(route,5,$event)"><small class="ml-1 mr-2">Ven</small>
                                                <input type="checkbox" [checked]="checkDay(route,6)" (click)="setDay(route,6,$event)"><small class="ml-1 mr-2">Sab</small>
                                                <input type="checkbox" [checked]="checkDay(route,0)" (click)="setDay(route,0,$event)"><small class="ml-1 mr-2">Dom</small>
                                            </div>
                                            <div class="col-1 pt-3 text-right">
                                                <button class="btn text-danger btn-sm" (click)="deleteRow(route)"><i class="fa fa-trash-alt"></i></button>
                                                <button class="btn btn-sm" *ngIf="route.expand" (click)="route.expand=false"><i class="fa fa-chevron-up"></i></button>
                                                <button class="btn btn-sm" *ngIf="!route.expand" (click)="route.expand=true"><i class="fa fa-chevron-down"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" *ngIf="route.expand">
                                        <table class="table">
                                            <thead>
                                                <th>Orario</th>
                                                <th>Stazione</th>
                                                <th colspan="2">Indirizzo</th>
                                                <th class="text-center">Punto di prelievo</th>
                                                <th>Coordinate</th>
                                               
                                                <th class="text-right">
                                                    <button class="btn btn-xs btn-success" (click)="addRoute(route.route)"><i class="fa fa-plus"></i></button>
                                                </th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let r of route.route;let i=index">
                                                    <td><input type="text" class="form-control form-control-sm" name="start_time{{i}}" [(ngModel)]="r.start_time"></td>
                                                    <td><input type="text" class="form-control form-control-sm" name="port{{i}}" [(ngModel)]="r.port"></td>
                                                    <td colspan="2"><input type="text" class="form-control form-control-sm" name="address{{i}}" [(ngModel)]="r.address"></td>
                                                    <td  class="text-center">
                                                        <input type="checkbox" class="" name="pickup{{i}}" [(ngModel)]="r.pickup">
                                                    </td>
                                                    <td>
                                                        <div class="input-group input-group-sm">
                                                            <div class="input-group-prepend input-group-prepend-sm">
                                                                <input type="text" class="form-control form-control-sm" placeholder="Latitudine" name="latitude{{i}}" [(ngModel)]="r.latitude">
                                                            </div>
                                                            <div class="input-group-append input-group-append-sm">
                                                                <input type="text" class="form-control form-control-sm" placeholder="Longitudine" name="longitude{{i}}" [(ngModel)]="r.longitude">
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-right">
                                                        <button class="btn btn-danger btn-xs" (click)="deleteRoute(route.route,r)"><i class="fa fa-trash-alt"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </form>
    <app-toolbarmodel [model]="this"></app-toolbarmodel>
</app-window>