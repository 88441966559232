import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'projects/core/src/authGuard';
import { ProductsComponent } from './products.component';
import { ProductsDetailsComponent } from './products-details/products-details.component';
import { SerialsComponent } from './serials/serials.component';

const routes: Routes = [
  { path: '', component: ProductsComponent,canActivate:[AuthGuard] },
  { path: 'product-detail/:id', component: ProductsDetailsComponent ,canActivate:[AuthGuard]},
  { path: 'serials', component: SerialsComponent ,canActivate:[AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
