import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { VerifyVATNumberService } from '../../services/verify-vatnumber.service';

@Component({
  selector: 'app-vat-number',
  templateUrl: './vat-number.component.html',
  styleUrls: ['./vat-number.component.css']
})
export class VatNumberComponent implements OnInit {
  
  @Input()
  vat_number:string="";

  @Output()
  onTextChange:EventEmitter<string>=new EventEmitter();

  @Input()
  name:string="vat_number";

  vat_number_error:string="";

  constructor(
    private verifyVATNumberService:VerifyVATNumberService

  ) { }

  ngOnInit(): void {

  }

  verifyVATNumber():boolean{   
    let v:boolean=false;
    if(this.vat_number=='XXXXXXXXXXX' || this.vat_number=='OO99999999999') return v=true; // salta se privato estero o extra UE

    this.vat_number_error="";
    if(this.vat_number!=undefined) {
      v=this.verifyVATNumberService.validate(this.vat_number);
      if(!v)
        this.vat_number_error=this.verifyVATNumberService.error_message;
    }
    return v;
  }

  onKeyChange(){
    this.onTextChange.emit(this.vat_number);
  }

}
