import { Component, OnInit } from '@angular/core';
import { Modal } from 'projects/core/src/include/modal';
import { Product } from '../../../db/product';



@Component({
  selector: 'app-showserials',
  templateUrl: './showserials.component.html',
  styleUrls: ['./showserials.component.sass']
})
export class ShowserialsComponent extends Modal implements OnInit {

  product:Product;
  

  constructor(    
    ) {super() }

  ngOnInit(): void {
    console.log(this.product);
  }

}
