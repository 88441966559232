import { Injectable } from '@angular/core';
import { Shops } from '../db/shops';
import { WSService } from 'projects/core/src/include/service'
import { Observable, Observer } from 'rxjs';
import { GlobalHistogramBinarizer } from '@zxing/library';
import { Globals } from 'projects/core/src/globals';

@Injectable({
  providedIn: 'root'
})

export class ShopsService extends WSService<Shops> {

  static shops=[];

  static shops_address=[];

  constructor() {super("shops") }

  getItemsByAddress(id_address:number):Observable<Shops[]>{

    if(ShopsService.shops_address.length==0){
      Globals.ws.requestUrl<Shops[]>("shops","getItems",["id_address="+id_address.toString()],true,0,1000000).subscribe((items:any)=>{
        ShopsService.shops_address=items;
      });
      return Globals.ws.requestUrl<Shops[]>("shops","getItems",["id_address="+id_address.toString()],true,0,1000000);

    }else{

      function sequenceSubscriber(observer: Observer<any[]>) {
        observer.next(ShopsService.shops_address);
        observer.complete();
        return {unsubscribe() {}};
      }
      
        return new Observable(sequenceSubscriber);
    }

  }

  getShops():Observable<Shops[]>{

    if(ShopsService.shops.length==0){
      Globals.ws.requestUrl<Shops[]>("shops","getItems",["status=1"],true,0,1000000).subscribe((items:any)=>{
        ShopsService.shops=items;
      });
      return Globals.ws.requestUrl<Shops[]>("shops","getItems",["status=1"],true,0,1000000);

    }else{

      function sequenceSubscriber(observer: Observer<any[]>) {
        observer.next(ShopsService.shops);
        observer.complete();
        return {unsubscribe() {}};
      }
      
        return new Observable(sequenceSubscriber);
    }

    
  }


  delete(ids: string[]): Observable<Shops> {
    ShopsService.shops=[];
    ShopsService.shops_address=[];  
    return super.delete(ids);
  }

  save(item: Shops, return_id: Function, checkdeadlinesandactions?: boolean, showAfterMessage?: boolean): void {
    ShopsService.shops=[]; 
    ShopsService.shops_address=[];   
    return super.save(item,return_id,checkdeadlinesandactions,showAfterMessage);
  }
  


}
