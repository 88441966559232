import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Filter, FilterAdvanced, Ordering } from 'projects/core/src/include/structures';
import { Observable } from 'rxjs';
import { InstallationLog } from '../db/installation';

@Injectable({
  providedIn: 'root'
})
export class InstallationslogsService extends WSService<InstallationLog>  {

  constructor() { super("installationslogs")}

  getTotals(filters:Filter[],ordering:Ordering[],advancedFilter:FilterAdvanced[]):Observable<any>{
    return Globals.ws.requestUrl("installationslogs","getTotals",this.getSQLWhere(filters,ordering,advancedFilter));
  }

}
