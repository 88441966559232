import { Image } from "projects/c1-backend/src/app/db/image";
import { Helper } from "projects/core/src/helper";

export class RentalPrice{
    id:number;
    name:string;
    published_start:Date;
    published_end:Date;
    time_start:string;
    time_end:string;
    duration:number;
    max_duration:number;
    type:number;
    min_adults:number;
    min_children:number;
    typeprice:number;
    min_partecipants:number;
    description:string;
    status:number;
    shops:any[];
    class:string;
    image:Image;
    label:string;


    constructor(){
        this.id=0;
        this.published_start=Helper.convertDateControl();
        this.shops=[];
    }


    parseImage(){
        try{
            this.image=JSON.parse(this.image.toString());
            let img=new Image();
            Object.assign(img,this.image);
            this.image=img;
        
        }catch{
            this.image=new Image();
        }
      

    }


}


