<app-windowlist [model]="this">
    <div class="row">
        <div class="col-lg-3" *ngIf="filterbox">
            <small>
                <app-filterdate [date_type]="default_filterdate" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="updateList()"></app-filterdate>
            </small>
        </div>
        <div class="col-9">
            <div class="card card-outline">
                <div class="p-0">
                    <div class="row" *ngIf="filterbox">
                        <div class="col-8">
                            <div class="input-group input-group-sm m-1" >
                                <input type="text" #table_search name="table_search" class="form-control float-right" placeholder="cerca..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger"   title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="input-group input-group-sm m-1" >
                                <select class="custom-select" [(ngModel)]="filter_id_user.value" (ngModelChange)="getItems()">
                                    <option value="">Tutti gli operatori</option>
                                    <ng-container *ngFor="let u of users">
                                        <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <div class="col-2">
                            <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="far fa-trash-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-undo"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  title="Esporta" (click)="export()"  class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  title="Aggiungi" (click)="newForm()" class="btn btn-success btn-xs float-right m-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12" >
                        <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                            <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>
                            <!-- <br><a type="button" (click)="" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
                        </div>
                        <form *ngIf="list && list.length>0" [formGroup]="form">
                            <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                                <thead class="bg-light">
                                    <th>
                                        <div #checkboxselectall class="icheck-primary d-inline mr-3">
                                            <input type="checkbox" title="Seleziona tutti"
                                            (change)="onChangeAll($event.target.checked)" />
                                        </div>
                                    </th>
                                    <th>Data
                                        <button type="button" class="btn btn-tool" (click)="switchOrdering('c.date')">
                                            <i class="fas fa-sort {{classOrdering('c.date')}}"  ></i>
                                        </button>
                                    </th>
                                    <th>Modulo
                                        <button type="button" class="btn btn-tool" (click)="switchOrdering('c.description')">
                                            <i class="fas fa-sort {{classOrdering('c.description')}}"  ></i>
                                        </button>
                                    </th>
                                    <th>Riferimento</th>
                                    <th>Utente</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                
                                    <!-- elemento ripetuto -->
                                    <tr *ngFor="let record of list">
                                        <td  title="{{record.date | date:'EEEE'}}">
                                            <div class="icheck-primary d-inline mr-3">
                                                <input type="checkbox" #checkrecord id_record="{{record.id}}"
                                                (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <a [class.trashed]="record.status == 2" (click)="openDetail(record)">
                                                {{record.date | date:'dd/M/yyyy'}}
                                            </a>
                                        </td>
                                        <td>{{ record.name }}</td>
                                        <td><small><app-reference [table]="record.table" [reference]="record.reference"></app-reference></small></td>
                                        <td><small>{{record.username}}</small></td>
                                        <td>
                                            <a *ngIf="mode!='modal'" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right  ml-1">
                                            <i class="fas fa-pencil-alt"></i></a>
                                            <button type="button" *ngIf="mode!='modal'"  (click)="notify(record)" title="Notifica" class="btn btn-primary btn-xs float-right ml-1">
                                                <i class="far fa-paper-plane"></i>
                                            </button>
                                            <button type="button" *ngIf="mode!='modal'"  (click)="exportInRow(record)"   title="Stampa" class="btn btn-warning btn-xs float-right ml-1">
                                                <i class="fas fa-print"></i></button>
                                            <button type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record);">
                                                <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                        <div class="card-footer clearfix py-1" *ngIf="mode!='embedded'">
                            <app-pagination [model]="this" ></app-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>