import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-statedocument',
  templateUrl: './statedocument.component.html',
  styleUrls: ['./statedocument.component.css']
})
export class StatedocumentComponent implements OnInit {

  @Input()
  state:number=1;

  constructor() { }

  ngOnInit(): void {
  }

}
