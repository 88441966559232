import { Component, Input, OnInit } from '@angular/core';
import { Globals } from 'projects/core/src/globals';


@Component({
  selector: 'app-serials',
  templateUrl: './serials.component.html',
  styleUrls: ['./serials.component.sass']
})
export class SerialsComponent  implements OnInit {
  tab=1;
  @Input()
  mode:string;

  @Input()
  modalWindow:any={};

  @Input()
  recordSelected:any={};
  
  isModuleEnabled(modulename){
    return Globals.parameters.get(modulename)?true:false;
  }

  ngOnInit(): void {
    
  }

  

}
