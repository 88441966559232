import { Injectable } from '@angular/core';
import {Inventories} from '../db/inventories';

import {WSService} from 'projects/core/src/include/service'
import { Observable, Observer } from 'rxjs';
import {Globals} from 'projects/core/src/globals';


@Injectable({
  providedIn: 'root'
})
export class InventoriesService extends WSService<Inventories> {

  static inventories=[];

  constructor() {super("inventories") }

  getInventories():Observable<Inventories[]>{
  
    if(InventoriesService.inventories.length==0){
      Globals.ws.requestUrl<Inventories[]>("inventories","getItems",[],true,0,100000).subscribe((items:any)=>{
        InventoriesService.inventories=items;
      });
      return Globals.ws.requestUrl<Inventories[]>("inventories","getItems",[],true,0,100000);

    }else{

      function sequenceSubscriber(observer: Observer<any[]>) {
        observer.next(InventoriesService.inventories);
        observer.complete();
        return {unsubscribe() {}};
      }
      
        return new Observable(sequenceSubscriber);
    }
  
    
  
  
  }

  delete(ids: string[]): Observable<Inventories> {
    InventoriesService.inventories=[];  
    return super.delete(ids);
  }

  save(item: Inventories, return_id: Function, checkdeadlinesandactions?: boolean, showAfterMessage?: boolean): void {
    InventoriesService.inventories=[];    
    return super.save(item,return_id,checkdeadlinesandactions,showAfterMessage);
  }

  resetStock(id_product,id_inventory,sn):Observable<any>{
    return Globals.ws.requestUrl<any>("inventories","resetStock",[id_product,id_inventory,sn]);
  }

  getAnalytics(id_category,id_type,filter_difference,start="1900-01-01",end="2999-12-31"):Observable<any>{
    return Globals.ws.requestUrl<any>("inventories","getAnalytics",[id_category,id_type,filter_difference,start,end]);
  }

  getTotalAnalytics(id_category,id_type,filter_difference):Observable<any>{
    return Globals.ws.requestUrl<any>("inventories","getTotalAnalytics",[id_category,id_type,filter_difference]);
  }

  getProductAnalytics(id_category,id_type,from,end):Observable<any>{
    return Globals.ws.requestUrl<any>("inventories","getProductAnalytics",[id_category,id_type,from,end]);
  }

}

