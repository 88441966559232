import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Type } from 'projects/c1-backend/src/app/db/type';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode } from 'projects/core/src/include/structures';
import { Ads } from 'projects/modules/src/app/ads/ads';
import { AdsService } from '../../services/ads.service';
import { DetailComponent } from './detail/detail.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent extends ModelList<Ads> implements OnInit {

  constructor(
    private fb1: UntypedFormBuilder,
    private adsService: AdsService,
  ) { 
    super(adsService,fb1);
    this.detailViewModal=DetailComponent;
  }

  type_ads:Type[]=[];

  filter_search:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_id_type:Filter=new Filter();

  ngOnInit(): void {

    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("a.title");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("a.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);
  

    this.getItems();
  }

}
