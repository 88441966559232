import { Component, OnInit } from '@angular/core';
import { Shops } from 'projects/c1-backend/src/app/db/shops';
import { ShopsService } from 'projects/c1-backend/src/app/services/shops.service';
import { Globals } from 'projects/core/src/globals';

@Component({
  selector: 'app-point',
  templateUrl: './point.component.html',
  styleUrls: ['./point.component.css']
})

export class PointComponent implements OnInit {
 shop:Shops;
  list:any[]=[];

  get projectname():string{
    {
      return Globals.config.softwarename;
    }
  }

  constructor(
    private shopsService:ShopsService
  ) { }

  ngOnInit(): void {

    const default_address=Globals.parameters.get("general").getParam("default_address");

  
    this.shopsService.getItemsByAddress(default_address).subscribe((items)=>{
      let params;
      //verifica se l'utente ha i permessi per accedere all'esercizio
      for(let i of items){
        try{
          params=JSON.parse(i.params);
          if(params['role']){
            if(params['role'].indexOf(Globals.user.role)>-1)
              this.list.push(i);
          }else{
            this.list.push(i);
          }

        }catch{
          i.params={};
          this.list.push(i);
        }finally{
          
        
        }
        
        
      }
      this.list.sort((a, b) => (a.name < b.name ? -1 : 1));
    });

    

    let audio = new Audio();
    audio.src = "../assets/confirm.wav";
    audio.load();
    audio.play();

  }


  confirm(shop){

    this.shop=shop;
    this['modalWindow'].close("success");

  }

}
