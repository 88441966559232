import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
import { Product } from '../db/product';

import { WSService } from 'projects/core/src/include/service'
import { Filter, Ordering } from 'projects/core/src/include/structures';

export interface ProductSearched{
  code:number;
  description:string;
  netprice:number;
  tax:number;
  grossprice:number;
}


@Injectable({
  providedIn: 'root'
})
export class ProductsService extends WSService<Product> {

  constructor() { super("products")};


  listModels(id_brand:number):Observable<Product[]>{
    return  Globals.ws.requestUrl<Product[]>("products","getItems",["pb.id_brand="+id_brand.toString()],true,0,1000000000);
    
  }



  getProduct(search:string):Observable<Product>{
    return  Globals.ws.requestUrl<Product>("products","getProduct",[search]);
    
  }

  getProducts(ids):Observable<Product[]>{
    return  Globals.ws.requestUrl<Product[]>("products","getProducts",[ids]);
    
  }

  getProductsByBrand(id_brand):Observable<Product[]>{
    return  Globals.ws.requestUrl<Product[]>("products","getItems",["pm.id_brand="+id_brand],true,0,10000000);
    
  }

  getProductsByTag(tag):Observable<Product[]>{
    return  Globals.ws.requestUrl<Product[]>("products","getItems",["pm.tags LIKE '%"+tag+"%'"],true,0,10000000);
    
  }

  getProductsByCodeInternal(codeinternal:string):Observable<Product[]>{
    
    return Globals.ws.requestUrl<Product[]>("products","getItems",["pm.codeinternal='"+codeinternal+"' AND pm.status=1"],true,0,1000000);
  }


  getCategory(id_category:number):Observable<Product[]>{
    return  Globals.ws.requestUrl<Product[]>("products","getItems",["pm.id_category="+id_category.toString()+" AND pm.status=1"],true,0,1000000000);
    
  }

  getDocuments(id_product:number):Observable<any[]>{
    return  Globals.ws.requestUrl<any[]>("products","getDocuments",[id_product.toString()]);
    
  }

  getItemsStockPeriod(filter:Filter[]=[],ordering:Ordering[]=[],from,to,paging_start=0,paging_count=10,id_inventory=0):Observable<any[]>{
    [this.lastSQLWhere,this.lastSQLOrder]=this.getSQLWhere(filter,ordering);
    return Globals.ws.requestUrl("products","getItemsStockPeriod",[this.lastSQLWhere,this.lastSQLOrder,from,to,id_inventory],false,paging_start,paging_count);
  }
  
  getAnalytic(id_product,type,from,to,id_document,id_type):Observable<any[]>{
    return Globals.ws.requestUrl("products","getAnalytic",[id_product,type,from,to,id_document,id_type]);
  
  }
}
