import { Injectable,Input } from '@angular/core';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class UploadService  {

 

  constructor() { }

  uploadFile(file,subdirectory,oncomplete,hashfile=false,default_filename="") {
   
   
    
    Globals.ws.send("upload","upload",null,[subdirectory,hashfile,default_filename],(result)=>{
      
      if(oncomplete)  
        oncomplete(result);
    },"File caricato correttamente!",file);
    
}

uploadImage64(img64,subdirectory,oncomplete,hashfile=false) {
  
  let image:any={};
  image.b64=img64;
    
  Globals.ws.send("upload","uploadImg",image,[subdirectory,hashfile],(result)=>{
    
    if(oncomplete)  
      oncomplete(result);
  },"File caricato correttamente!");
  
}




}
