<app-windowlist [model]="this">
    <div class="row">
        <div class="col-lg-2 pr-lg-0 text-sm" *ngIf="filterbox">
            <app-filterdate [date_type]="mode!='embedded'?1:100" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="updateList()"></app-filterdate>
            <ul class="card small-box list-group p-1 mt-2" *ngIf="user.isAdmin() && isModuleEnabled('shops')">
                <li class="list-group-item text-truncate pointer" [class.active]="filter_created_from.value==''" (click)="filter_created_from.value='';getItems()">Tutte le sedi</li>
                <li class="list-group-item text-truncate pointer" *ngFor="let s of shops" [class.active]="filter_created_from.value==s.id" (click)="filter_created_from.value=s.id;getItems()">{{s.name}}</li>
                <div  *ngIf="shops.length>2" class="icon"><i class="fa fa-store-alt "></i></div>
            </ul>                           
            <!-- <ng-container *ngIf="user.isAdmin() && isModuleEnabled('financialaccounts') && financialaccounts.length>1">
                <div class="card small-box list-group p-1 mt-2 mb-2" >
                    <app-financialaccount [showList]="true" [showAll]="true" [id_financialaccount]="this.filter_financialaccount.value" [children]="true" (selected)="this.filter_financialaccount.value=$event['id'];updateList();"></app-financialaccount>
                    <div *ngIf="financialaccounts.length>2" class="icon"><i class="fas fa-coins"></i></div>
                </div>
            </ng-container> -->
        </div>
        <div [class.col-lg-10]="filterbox" [class.col-lg-12]="!filterbox" >
            <div class="card card-outline">
                <ng-container *ngIf="filterbox">
                    <div class="row">
                        <div class="col-lg-9">
                            <div class="input-group input-group-sm m-1" >
                                <input type="text" #table_search name="table_search" class="form-control float-right" placeholder="cerca..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append">
                                    <button type="submit" class="btn btn-default" title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger" title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                    <button class="btn btn-default" title="Ricerca avanzata" data-toggle="collapse" data-target="#advancedSearchPanel"  aria-expanded="false" aria-controls="advancedSearchPanel" ><span id="searchclear" class="fas fa-sliders-h"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 py-1">
                            <div class="dropdown dropleft show">
                                <a class="btn btn-default btn-xs float-right mx-1 mt-1" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v"></i>
                                </a>
                                <div class="dropdown-menu py-0" aria-labelledby="dropdownMenuLink">
                                    <small>
                                        <button type="button" class="dropdown-item" (click)="createDocument()" titel="Crea documento fiscale con i dati dell'elemento selezionato">Crea documento fiscale</button>
                                        <button type="button" class="dropdown-item" *ngIf="user.isAdmin() && isModuleEnabled('financialaccounts')" title="Assegna gli elementi selezionati ad un conto contabile" (click)="assignFinancialAccount()">Assegna conto contabile</button>
                                    </small>
                                </div>
                            </div>
                            <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1"><i class="far fa-trash-alt"></i></button>
                            <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right  ml-1 mt-1"><i class="fas fa-trash"></i></button>
                            <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-undo"></i></button>
                            <button  title="Esporta" (click)="export()"  class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-file-export"></i></button>
                            <button  title="Aggiungi" (click)="openDetail(null,[{'name':'id_address','value':id_address},{'name':'id_table','value':id_table},{'name':'table','value':table},{'name':'reference','value':reference}])" class="btn btn-success btn-xs float-right ml-1 mt-1"><i class="fas fa-plus"></i></button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div  class="small-box bg-light m-1 p-1 collapse" id="advancedSearchPanel">
                                <div class="row">
                                    <div class="col-xs-12 col-lg-11">
                                        <app-advancedsearch [id_field]="'c.id'" [table]="'cashflow'" [filters]="advancedFilter"></app-advancedsearch>
                                    </div>
                                    <div class="col-xs-12 col-lg-1 pl-1">
                                        <button title="Filtra" class="btn btn-outline-secondary btn-block btn-sm float-right" (click)="getItems()"><i class="fa fa-filter"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-sm-6 col-lg-2 pr-lg-0">
                            <div class="mx-1">
                                <select class="form-control form-control-sm" [(ngModel)]="filter_id_type.value" (ngModelChange)="getItems()" [class.alert-success]="filter_id_type.value==1" [class.alert-danger]="filter_id_type.value==2">
                                    <option value="">Ingressi + Uscite</option>
                                    <option value="1">Solo Ingressi</option>
                                    <option value="2">Solo Uscite</option>
                                </select>
                            </div>
                        </div>
                       
                        <div class="col-sm-6 col-lg-2 px-lg-0">
                            <div class="mx-1">
                                <select class="form-control form-control-sm" [(ngModel)]="filter_method_payment.value" (ngModelChange)="getItems()" [class.alert-info]="filter_method_payment.value">
                                    <option value="">Tutti i metodi di pagamento</option>
                                    <option *ngFor="let m of methodpayments" (value)="m.name">{{m.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-2 px-lg-0">
                            <div class="mx-1">
                                <select class="form-control form-control-sm"  [(ngModel)]="filter_source.value" (ngModelChange)="getItems()" [class.alert-info]="filter_source.value">
                                    <option value="">Tutte le casse/banche</option>
                                    <option *ngFor="let s of sources" (value)="s.description">{{s.description}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-2 px-lg-0" *ngIf="user.isAdmin() && isModuleEnabled('financialaccounts') && financialaccounts.length>1">
                            <app-financialaccount [showAll]="true" [id_financialaccount]="this.filter_financialaccount.value" (selected)="this.filter_financialaccount.value=$event['id'];getItems()"></app-financialaccount>
                        </div>
                        <div class="col-sm-6 col-lg-2 pl-lg-0">
                            <div class="mx-1">
                                <div class="input-group input-group-sm">
                                    <select class="form-control form-control-sm" [(ngModel)]="filter_id_user.value" (ngModelChange)="getItems()" [class.alert-info]="filter_id_user.value">
                                        <option value="">Tutti gli operatori</option>
                                        <ng-container *ngFor="let u of users">
                                            <ng-container *ngIf="all_users">
                                                <option [class.text-gray]="!u.enabled" [ngValue]="u.id">{{u.name}}</option>
                                            </ng-container>
                                            <ng-container *ngIf="!all_users">
                                                <option *ngIf="u.enabled" [ngValue]="u.id">{{u.name}}</option>
                                            </ng-container>
                                        </ng-container>
                                    </select>
                                    <div class="input-group-append pl-1">
                                        <input class="" type="checkbox" title="Visualizza anche operatori disabilitati" (change)="all_users==true?all_users=false:all_users=true" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-2 pl-lg-0" *ngIf="mode=='embedded'">
                            <select class="form-control form-control-sm" [(ngModel)]="modeShow" [class.alert-warning]="modeShow==1 || modeShow==2">
                                <option [value]="0">Mostra tutte</option>
                                <option [value]="1">Mostra solo quelle dell'anno in corso</option>
                                <option [value]="2">Mostra gli ultimi 365 giorni</option>
                            </select>
                        </div>
                    </div>
                    
                </ng-container>

                <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                    <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>
                    <!-- <br><a type="button" (click)="" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
                </div>
                <form *ngIf="list && list.length>0" [formGroup]="form">
                    <table class="table table-striped table-nowrap table-sm table-hover m-0 text-sm">
                        <thead class="bg-light">
                            <th>
                                <div #checkboxselectall class="icheck-primary d-inline mr-3">
                                    <input type="checkbox" title="Seleziona tutti"
                                    (change)="onChangeAll($event.target.checked)" />
                                </div>
                            </th>
                            <th>Data
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('c.date')">
                                    <i class="fas fa-sort {{classOrdering('c.date')}}"  ></i>
                                </button>
                            </th>
                            <th>Causale
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('c.description')">
                                    <i class="fas fa-sort {{classOrdering('c.description')}}"  ></i>
                                </button>
                            </th>
                            <th *ngIf="mode!='embedded'">Anagrafica</th>
                            <th class="text-center">Importo
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('c.amount')">
                                    <i class="fas fa-sort {{classOrdering('c.amount')}}"  ></i>
                                </button>
                            </th>
                            <th class="text-center">Versato
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('c.paid')">
                                    <i class="fas fa-sort {{classOrdering('c.paid')}}"  ></i>
                                </button>
                            </th>
                            <th class="text-center">Differenza
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('c.paid')">
                                    <i class="fas fa-sort {{classOrdering('c.paid')}}" ></i>
                                </button>
                            </th>
                            <th>Riferimento</th>
                            <th class=" text-center">Utente</th>
                            <th></th>
                        </thead>
                        <thead>
                            <tr>
                                <td *ngIf="mode=='embedded'" colspan="3"><b>TOTALE</b>
                                    <i class="text-sm" *ngIf="total_amount_future!=total_amount"><br>TOTALE (FUTURO)</i>
                                </td>
                                <td *ngIf="mode!='embedded'" colspan="4"><b>TOTALE</b>
                                    <i class="text-sm" *ngIf="total_amount_future!=total_amount"><br>TOTALE (FUTURO)</i>
                                </td>
                                <td class="text-center" [class.text-red]="total_amount < 0">
                                    <b>{{total_amount | currency:'EUR':true }}</b>
                                    <span class="text-sm" *ngIf="total_amount_future!=total_amount">
                                        <br/>
                                        <i>{{total_amount_future | currency:'EUR':true}}
                                            <span *ngIf="total_difference_future!=total_difference">*</span>
                                        </i>
                                    </span>
                                </td>
                                <td class="text-center" [class.text-red]="total_paid < 0">
                                    <b>{{total_paid | currency:'EUR':true }}</b>
                                    <span class="text-sm" *ngIf="total_paid_future!=total_paid">
                                        <br/>
                                        <i>{{total_paid_future | currency:'EUR':true}}
                                            <span *ngIf="total_difference_future!=total_difference">*</span>
                                        </i>
                                    </span>
                                </td>
                                <td  class="text-center" [class.text-red]="total_difference < 0">
                                    <b>{{total_difference | currency:'EUR':true }}</b>
                                    <span class="text-sm" *ngIf="total_difference_future!=total_difference">
                                        <br/>
                                        <i>{{total_difference_future | currency:'EUR':true}}*</i>
                                    </span>
                                </td>
                                <td class="text-right" colspan="3">
                                    <small *ngIf="total_difference_future!=total_difference">
                                    <br>* valori ancora da contabilizzare
                                    </small>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                        
                            <!-- elemento ripetuto -->
                            <ng-container *ngFor="let record of list">
                                <tr *ngIf="isShowed(record)" [class.bg-futureline]="verifyDate(record.date)" [class.bg-redline]="record.id_type==2" [class.bg-greenline]="record.id_type==1">
                                    <td  title="{{record.date | date:'EEEE'}}">
                                        <div class="icheck-primary d-inline mr-3">
                                            <input type="checkbox" #checkrecord id_record="{{record.id}}"  title="{{record.id}}"
                                            (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <a [class.trashed]="record.status == 2" (click)="openDetail(record)">
                                            {{record.date | date:'dd/M/yyyy'}}<br><small class="text-gray">{{record.date | date:'EEEE'}}</small>
                                        </a>
                                    </td>
                                    <td><b>{{record.description}}</b></td>
                                    <td *ngIf="mode!='embedded'">
                                        {{record.addressItem.name}}<br>
                                        <small class="text-gray">{{record.addressItem.city}} {{record.addressItem.country}}</small>
                                    </td>
                                    <td class="text-center">
                                        <span *ngIf="record.id_type==1" >{{ record.amount*(1+record.tax/100) | currency:'EUR':true }}<span *ngIf="verifyDate(record.date)">*</span></span>
                                        <span *ngIf="record.id_type==2" class="text-red">{{ record.amount*(1+record.tax/100)*-1 | currency:'EUR':true }}<span *ngIf="verifyDate(record.date)">*</span></span>
                                        <br/>
                                        <div *ngIf="record.method_payment" class="badge border"><b>{{ record.method_payment }}</b></div>
                                        <br *ngIf="record.method_payment" />
                                        <div *ngIf="record.source" class="badge badge-secondary mw-100 text-truncate">{{record.source}}</div>
                                    </td>
                                    <td class="text-center">
                                        <span *ngIf="record.id_type==1" >{{ record.paid | currency:'EUR':true }}<span *ngIf="verifyDate(record.date)">*</span></span>
                                        <span *ngIf="record.id_type==2" class="text-red">{{ record.paid*-1 | currency:'EUR':true }}<span *ngIf="verifyDate(record.date)">*</span></span>
                                    </td>
                                    <td class="text-center">
                                        <span *ngIf="record.balance==0">--</span>
                                        <span *ngIf="record.balance!=0" [class.text-red]="record.balance < 0">{{ record.balance | currency:'EUR':true }}<span *ngIf="verifyDate(record.date)">*</span></span>
                                    </td>
                                    <td class="py-1">
                                        <small>
                                            <app-reference *ngIf="record.reference" [table]="record.table" [reference]="record.reference"></app-reference>
                                        </small>
                                    </td>
                                    <td class="text-center">
                                        <div class="text-truncate mw-100">{{record.username}}</div>
                                        <div *ngIf="record.shopname" class="badge border mw-100 text-truncate"><i class="fa-store-alt fas mr-1"></i><b>{{record.shopname}}</b></div>
                                    </td>
                                    <td>                                        
                                        <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record);">
                                            <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i></button>
                                        <button type="button" *ngIf="deleteInRow" class="btn btn-xs btn-danger float-right mt-1 ml-1" (click)="trash([record.id],true);">
                                            <i class="fa fa-trash"></i></button>
                                        <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right mt-1 ml-1">
                                            <i class="fas fa-pencil-alt"></i></button>
                                        <button type="button" *ngIf="mode!='modal'" (click)="duplicate(record)" title="Duplica" class="btn btn-info btn-xs float-right mt-1 ml-1">
                                            <i class="far fa-clone"></i></button>
                                       
                                    </td>
                                </tr>
                            </ng-container>
                            <!-- fine elemento ripetuto -->
                            
                        </tbody>
                        <tfoot>
                            <tr>
                                <td *ngIf="mode=='embedded'" colspan="3"><b>TOTALE</b>
                                    <i class="text-sm" *ngIf="total_amount_future!=total_amount"><br>TOTALE (FUTURO)</i>
                                </td>
                                <td *ngIf="mode!='embedded'" colspan="4"><b>TOTALE</b>
                                    <i class="text-sm" *ngIf="total_amount_future!=total_amount"><br>TOTALE (FUTURO)</i>
                                </td>
                                <td class="text-center" [class.text-red]="total_amount < 0">
                                    <b>{{total_amount | currency:'EUR':true }}</b>
                                    <span class="text-sm" *ngIf="total_amount_future!=total_amount">
                                        <br/>
                                        <i>{{total_amount_future | currency:'EUR':true}}
                                            <span *ngIf="total_difference_future!=total_difference">*</span>
                                        </i>
                                    </span>
                                </td>
                                <td class="text-center" [class.text-red]="total_paid < 0">
                                    <b>{{total_paid | currency:'EUR':true }}</b>
                                    <span class="text-sm" *ngIf="total_paid_future!=total_paid">
                                        <br/>
                                        <i>{{total_paid_future | currency:'EUR':true}}
                                            <span *ngIf="total_difference_future!=total_difference">*</span>
                                        </i>
                                    </span>
                                </td>
                                <td  class="text-center" [class.text-red]="total_difference < 0">
                                    <b>{{total_difference | currency:'EUR':true }}</b>
                                    <span class="text-sm" *ngIf="total_difference_future!=total_difference">
                                        <br/>
                                        <i>{{total_difference_future | currency:'EUR':true}}*</i>
                                    </span>
                                </td>
                                <td class="text-right" colspan="3">
                                    <small *ngIf="total_difference_future!=total_difference">
                                    <br>* valori ancora da contabilizzare
                                    </small>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2"><b>INGRESSI</b></td>
                                <td *ngIf="mode!='embedded'"></td>
                                <td></td>
                                <td class="text-center text-success">
                                    <b>{{total_amount_in | currency:'EUR':true }}</b>
                                </td>
                                <td class="text-center text-success">
                                    <b>{{total_paid_in | currency:'EUR':true }}</b>
                                    
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                
                            </tr>
                            <tr>
                                <td colspan="2"><b>USCITE</b></td>
                                <td *ngIf="mode!='embedded'"></td>
                                <td></td>
                                <td class="text-center text-danger">
                                    <b>{{total_amount_out | currency:'EUR':true }}</b>
                                </td>
                                <td class="text-center text-danger">
                                    <b>{{total_paid_out | currency:'EUR':true }}</b>
                                    
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                
                            </tr>
                        </tfoot>
                    </table>
                </form>
                <div class="card-footer clearfix py-1" *ngIf="mode!='embedded'">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
                 
            </div>
        </div>
    </div>
</app-windowlist>