import { SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { Component, HostListener, Input, OnInit, Output,EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Image } from 'projects/c1-backend/src/app/db/image';
import { InventoriesDetails, InventoryMode } from 'projects/c1-backend/src/app/db/inventories';
import { Product } from 'projects/c1-backend/src/app/db/product';
import { BookingService } from 'projects/c1-backend/src/app/services/booking.service';
import { SalesComponent } from 'projects/c1-backend/src/app/views/inventories/sales/sales.component';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { Extension } from 'projects/core/src/include/parameters';
import { ToastMode } from 'projects/core/src/include/structures';
import { UploadService } from 'projects/core/src/lib/upload.service';
import { Booking } from '../../../db/booking';
import { InstallationslogsService } from '../../../services/installationslogs.service';
@Component({
  selector: 'app-checkoutbooking',
  templateUrl: './checkoutbooking.component.html',
  styleUrls: ['./checkoutbooking.component.css']
})
export class CheckoutComponent implements OnInit {

  @ViewChild("sigPad")
  sigPad:SignaturePadComponent;
  signature;

  @ViewChild("confirmProductModal")
  confirmProductModal:TemplateRef<any>;
  confirmProductModalRef;
  selectedProduct;

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {

    if(event.keyCode==113){ //F2
      this.goPrevious();
    }

    if(event.keyCode==115){ //F4
      this.goNext();
    }

    
  }

  fileToUpload: File = null;

  @Input()
  booking:Booking;

  @Input()
  showClose:boolean=true;

  @Input()
  showSteps:boolean=true;

  @Output()
  onSuccess:EventEmitter<void>=new EventEmitter();

  @Input()
  canExploreCatalog:boolean=true;

  
  get NextTitleBtn(){
    return this.step<(this.steps.length-1)?"Avanti":"Conferma";
  }

  get showPrevious(){
    return this.step==0?false:true;
  }

  get showNext(){
    return this.step>-1?true:false;
  }

  steps=['Verifica dei prodotti','Conferma']
  step=0;
  textTosearch:string
  listChecked:any[]=[];
  list:InventoriesDetails[]=[];
  passengers=[];
  inventoryItems=[];
  id_category_log=0;

  module:Extension;
  get steptitle(){
    return this.steps[this.step];
  }

  constructor(
    private bookingService:BookingService,
    private installationsLogs:InstallationslogsService,
    private uploadService:UploadService,
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {

    //converti in classe
    let r=new Booking();
    Object.assign(r,this.booking);
    this.booking=r;


    this.module=Globals.parameters.get("booking");
    this.id_category_log=Globals.parameters.get("installations").getParam("id_category_log");
    this.getBookingInventories();

  }

  getBookingInventories(){

    let km_per_hour=this.module.getParam("km_per_hour",0);
    
    

    this.bookingService.getBookingInventories(this.booking.id).subscribe((items)=>{
      this.inventoryItems=items;
      for(let i of this.inventoryItems){
        i['selected']=true;

        //parserizza il prodotto
        let p:Product=new Product();
        Object.assign(p,i['product']);
        i['product']=p;

        //calcola stima dei km percorsi
        i['km_route_estimated']=this.booking.realDurationHour*km_per_hour;

        
        

        this.list.push(i);
      }
        
    });
  }


  selectInventory(i){
    if(!i['selected']){
      i['product']=[];
      i['product']['id']=i.id_product;
      
      this.list.push(i);
      

    }else{
      for(let r=1;r<this.list.length;r++)
        if(this.list[r].id==i.id){
          this.list.splice(r,1);
          continue;
        }

      i['selected']=false;
    }
    
    this.checkProducts();
  }
  

  checkProducts(){
    for(let d of this.booking.details){
      for(let i of this.list){
        if(i.product.id==d.product.id){
          d['quantityFounded']=i.quantity;
        }
      }
    }


  }

  

  handleFileInput(files: FileList) {
    Globals.setLoading(true);
    this.fileToUpload = files.item(0);
    this.uploadService.uploadFile(this.fileToUpload,"personalid",(result)=>{

      let i:Image=new Image();
      i.file=result;

      Globals.setLoading(false);
     
    },true);
}

  validateStep(step,isNext=true){
    switch(step){
      
      case 0:
        if(!isNext){
          return true;
        }
       //verifica se per tutte le biciclette sono stati segnati i KM
       let count_km=0;
       let count_bike=0;
       for(let i of this.list){
         if(i.product.id_category==this.id_category_log){
           if(i['km'] && i['km']>0){
             count_km++;
           }
           count_bike++;
         }
       }

       if(count_km<count_bike){
         Globals.message.showToaster("Non sono stati definiti i chilometraggi delle biciclette",ToastMode.DANGER);
         return false;
       }

        return true;

      case 1:

       
       

        return true;
        break;
      case 2:
       break;
      case 3:
       
        break;
     case 4:
      

      break;
        
    }

    return true;
  }


  goTo(step){
    this.step=step;
    if(step==2){
      this.confirm();
    }
  }

  goNext(){
    if(this.validateStep(this.step))
      this.goTo(this.step+1);
  }

  goPrevious(){
    if(this.validateStep(this.step,false))
      this.goTo(this.step-1);
    
  }

  confirm(){
    let tollarance_km_per_hour=this.module.getParam("tollerance_km_per_hour",0);

    for(let i=0;i<this.list.length;i++){
      this.list[i].id=0;
      this.list[i].type=InventoryMode.load;
      this.list[i].id_inventory=this.booking.shop_end.id_inventory;
      this.list[i].id_table=this.booking.id;
      this.list[i].quantity=1;
      this.list[i].table="booking";
      this.list[i].date=Helper.convertDateControl();
      this.list[i].time=Helper.currentTime();
    }

    
    this.booking.state=5;
    this.booking.checkout=Helper.convertDateControl(null,true);
    this.booking.checkout_iduser=Globals.user.id;
    this.booking['listToInventory']=this.list;
    this.bookingService.save(this.booking,()=>{
      
      this.bookingService.emailConfirmCheckout(this.booking.id).subscribe(()=>{
        
        //effettua la verifica se vi è qlc bene che non rispetta i criteri del chilometraggio
        for(let i of this.list){
          if(i.product.id_category==this.id_category_log){

            let km_route=i['km']-i['last_km'];



            if(km_route> i['km_route_estimated']*(1+tollarance_km_per_hour) || km_route< i['km_route_estimated']*(1-tollarance_km_per_hour)){
              this.bookingService.emailMonitoringAlarm(this.booking.id,i['sn']).subscribe(()=>{});
            }
          }
        }



        this.onSuccess.emit();
        this.closeWindow();
      });
      
    });
    
    
  }

  closeWindow(){
    if(this['modalWindow'])
      this['modalWindow'].close('success');
  }

  openConfirmProduct(i){
    this.selectedProduct=i;
    i['selected']=true;
    this.confirmProductModalRef=this.modalService.open(this.confirmProductModal);
    this.getCoordinate();
  }
  confirmProduct(){

    /*
    this.getCoordinate(()=>{
      this.confirmProductModalRef.close();
    });
    */

    this.confirmProductModalRef.close();
  }


  getCoordinate(oncomplete=null){
    this.getPosition().then(pos=>
      {
        this.selectedProduct.latitude=pos.lat;
        this.selectedProduct.longitude=pos.lng;
        if(oncomplete)
          oncomplete();
     });
  }

  getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }

  openSales(){

    let ids_product_danger=this.module.getParam("ids_products_danger","");

    ids_product_danger=ids_product_danger.split(",");

    Globals.modal.showModal(SalesComponent,[
      {"name":"mode","value":"modal"},
      {"name":"ids_products","value":ids_product_danger}
    ],()=>{

    });
  }

}
