import { NgModule } from '@angular/core';
import { SearchgpsComponent } from './searchgps.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';


@NgModule({
    imports: [FormsModule,CommonModule,ModalModule],
   exports: [SearchgpsComponent],
   declarations: [SearchgpsComponent],
   providers: [],
})
export class SearchgpsModule{ }