import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode, Ordering, OrderingMode } from 'projects/core/src/include/structures';
import { Documents } from '../../../db/documents';
import { DocumentsService } from '../../../services/documents.service';
import { ShopsService } from '../../../services/shops.service';
import { DocumentsDetailsComponent } from '../documents-details/documents-details.component';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.css']
})
export class TransferComponent extends ModelList<Documents> implements OnInit {

  @Input()
  filterbox:boolean=true;

  date_from: Date=new Date();
  date_to: Date=new Date();
  isFilterSet:boolean=false;
  filter_search:Filter=new Filter();
  filter_state:Filter<number>=new Filter<number>();
  filter_reference:Filter=new Filter();

  filter_typedocument:Filter<number>=new Filter<number>();
  filter_status:Filter=new Filter();
  filter_id_type:Filter<number>=new Filter<number>();
  filter_id_shop_end:Filter=new Filter();

  filter_date:Filter=new Filter();  
  shops=[];

  constructor(
    private fb1: UntypedFormBuilder,
    private invoicesService: DocumentsService,
    private shopsService:ShopsService

  ) { 
    super(invoicesService,fb1);
    this.detailViewModal=DocumentsDetailsComponent;
  }

  ngOnInit(): void {

    this.title = 'Trasferimenti';
    super.ngOnInit.apply(this, arguments); 

    const default_address=Globals.parameters.get("general").getParam("default_address");

    this.shopsService.getItemsByAddress(default_address).subscribe((items)=>{
      this.shops=items;
    });

    if(this.mode!="embedded"){
      this.filter_date.mode=FilterMode.date;
      this.filter_date.fields=[];
      this.filter_date.value=Helper.convertDateControl(this.date_from);
      this.filter_date.value2=Helper.convertDateControl(this.date_to);
      this.filter_date.fields.push("i.date");
      this.filter.push(this.filter_date);
    }

    if(this.mode!="embedded") //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
      this.updateList();

  }

  setFilters():void{

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("i.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.filter_id_shop_end.mode=FilterMode.normal;
    this.filter_id_shop_end.fields=[];
    this.filter_id_shop_end.fields.push("i.id_shop_end");
    this.filter_id_shop_end.value="";
    this.filter.push(this.filter_id_shop_end);

    this.filter_id_type.mode=FilterMode.normal;
    this.filter_id_type.fields=[];
    this.filter_id_type.fields.push("i.id_type");
    this.filter_id_type.value=2;
    this.filter.push(this.filter_id_type);

    this.filter_typedocument.mode=FilterMode.normal;
    this.filter_typedocument.fields=[];
    this.filter_typedocument.fields.push("i.typedocument");
    this.filter_typedocument.value=10;
    this.filter.push(this.filter_typedocument);

    this.filter_reference.mode=FilterMode.normal;
    this.filter_reference.fields=[];
    this.filter_reference.fields.push("i.reference");
    this.filter_reference.value="";
    this.filter.push(this.filter_reference);

    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("i.name");
    this.filter_search.fields.push("i.description");
    this.filter_search.fields.push("i.reference");
    this.filter_search.fields.push("i.code");
    this.filter_search.fields.push("a.name");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_state.mode=FilterMode.normal;
    this.filter_state.fields=[];
    
    this.filter_state.fields.push("i.state");
    this.filter_state.nullvalue=0;
    this.filter_state.value=0;
    this.filter.push(this.filter_state);

    this.ordering.field="i.date";
    this.ordering.mode=OrderingMode.discendent;   

  }

  updateList(id_address:number=0):void{
    if(!this.isFilterSet)
        this.setFilters();
  
    this.getItems();
  }

}
