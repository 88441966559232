import { Component, Input, OnInit,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-nations',
  templateUrl: './nations.component.html',
  styleUrls: ['./nations.component.css']
})
export class NationsComponent implements OnInit {

  @Input()
  class:string="";

  @Input()
  nation:any;

  @Input()
  disabled:boolean=false;

  @Output()
  onSelect:EventEmitter<any>=new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.nation = "IT";
  }

  onSelected(event){
    this.onSelect.emit(event.target.value);
  }


}
