import { Component, OnInit, ViewChild } from '@angular/core';
import {Product} from '../../../db/product';
import { InventoriesDetails } from '../../../db/inventories';
import {InventoriesDetailsService} from '../../../services/inventoriesdetails.service';
import { Modal } from 'projects/core/src/include/modal';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { LoadserialsComponent } from '../loadserials/loadserials.component';
@Component({
  selector: 'app-inventoryout',
  templateUrl: './inventoryout.component.html',
  styleUrls: ['./inventoryout.component.sass']
})
export class InventoryoutComponent implements OnInit {


  list:InventoriesDetails[]=[];
  total:number;
  quantity:number=1;
  constructor(private inventoriesDetailsService:InventoriesDetailsService) { }




  ngOnInit(): void {
    /*
    this.modalWindow.result.then((m)=>{
      //quando si chiude la modal
      if(m=="success"){
          this.inventoriesDetailsService.saveMultiple(this.list);
      }
        
    
    });*/


  }

  add(product:Product){

    

    //verifica se il prodotto deve avere un seriale
    if(product.has_serials){
      if(product.sn=="" || product.sn==undefined){
        Globals.modal.showModal(LoadserialsComponent,[{"name":"type","value":2},{"name":"quantity","value":1},{"name":"id_product","value":product.id},{"name":"serials","value":product.serials}],(instance)=>{
          if(instance!=null){
            for(let s of instance['serials']){
              if(s.selected==true){
                product.sn=s.sn;
                this._addProduct(product);
              }
            }

            
          }
        });
        return;
      }
    }
    this._addProduct(product);
    
  }

  _addProduct(product:Product){

    //verifica se il prodotto è stato già aggiunto
    for(let i=0;i<this.list.length;i++){
      if(this.list[i].id_product==product.id){
        //se ha un numero seriale, verifica che non sia lo stesso
        if(product.has_serials){
          if(this.list[i].sn==product.sn){
            return;
          }
        }else{
          this.list[i]['quantity']=this.list[i]['quantity']+this.quantity;
          this.calculateTotal();
          return;
        }
      }
    }


    let d:InventoriesDetails={} as InventoriesDetails;
    d.id=0;
    d.product=product;
    d.sn=product.sn;
    d.id_product=product.id;
    d.quantity=this.quantity;
    d.netprice=product.getPrice(d.quantity,2);
    d.tax=product.tax;
    d.type=2; //scarico
    d.unit=product.unit;
    d.description=product.brand+" "+product.name;
    d.gross=d.netprice*(1+d.tax/100);
    d.id_inventory=Globals.parameters.get("inventories").getParam("default_inventory");

    d.date=Helper.convertDateControl();
    let t=new Date();
    d.time=t.getHours().toString()+":"+t.getMinutes().toString();
    d.id_user=Globals.user.id;
    this.list.push(d);
    this.calculateTotal();
  }

  remove(product:Product){
    for(let i=0;i<this.list.length;i++){
      if(this.list[i].id_product==product.id){
        this.list.splice(i,1);
        this.calculateTotal();
        return;
      }

    }
  }

  calculateTotal(){
    this.total=0;
    for(let p of this.list){
      p.netprice=p.product.getPrice(p.quantity,2);
      this.total=this.total+(p.netprice*p.quantity*(1+p.tax/100));
    }
  }

  cancel(){
    this['modalWindow'].dismiss("cancel");
  }

  confirm(){

    this.inventoriesDetailsService.saveMultiple(this.list,()=>{
      this['modalWindow'].dismiss("success");
    });

    
  }
 

}
