import { NgModule } from '@angular/core';
import { NoteboxComponent } from './notebox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


@NgModule({
    imports: [FormsModule,CommonModule],
   exports: [NoteboxComponent],
   declarations: [NoteboxComponent],
   providers: [],
})
export class NoteboxModule{ }