<app-windowlist [model]="this">
    <div class="row">
        <div class="col-12">
            <div class="card card-outline">
                <div class="p-0">
                    <div class="row">
                        <div class="col-lg-10">
                            <div class="input-group input-group-sm p-1">
                                <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per nome compagnia..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append">
                                    <button type="submit" class="btn btn-default"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger"  title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-5 text-right py-1">
                            <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-sm float-right mr-1"><i class="far fa-trash-alt"></i></button>
                            <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-sm float-right mr-1"><i class="fas fa-trash"></i></button>
                            <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-sm float-right mr-1"><i class="fas fa-undo"></i></button>
                            <button  title="Aggiungi" (click)="openDetail()" class="btn btn-success btn-sm float-right mr-1"><i class="fas fa-plus"></i></button>
                        </div>
                    </div>

                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>Nessun elemento soddisfa i parametri di ricerca impostati!</i>
                        <!-- <br><a type="button" (click)="" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                            <thead class="bg-light">
                                <th>
                                    <div class="icheck-primary d-inline mr-3">
                                        <input type="checkbox" title="Seleziona tutti"
                                        (change)="onChangeAll($event.target.checked)" />
                                    </div>
                                    Compagnia
                                </th>
                                <th class="text-center"></th>
                                <th></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let c of list">
                                    <td>
                                        <div class="icheck-primary d-inline mr-3">
                                            <input type="checkbox" #checkrecord id_record="{{c.id}}"
                                            (change)="onChange(c.id, $event.target.checked)" [checked]="checkedAll" />
                                        </div>
                                        <b class="text-lg">{{c.name}}</b>
                                    </td>
                                    <td class="text-center">
                                        <img *ngIf="c.logo" src="{{c.logo}}" style="max-height:40px">
                                    </td>
                                    <td>
                                        <button *ngIf="mode!='modal'" (click)="openDetail(c)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1">
                                            <i class="fas fa-pencil-alt"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>