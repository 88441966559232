import { Component, OnInit,Input,Output,EventEmitter, Type } from '@angular/core';
import { Globals } from '../../globals';
import { WSService } from '../../include/service';

@Component({
  selector: 'app-selectfieldcustom',
  templateUrl: './selectfieldcustom.component.html',
  styleUrls: ['./selectfieldcustom.component.css']
})
export class SelectfieldcustomComponent implements OnInit {
  @Input()
  class:string;

  @Input()
  service:WSService<any>;

  @Input()
  fieldRender:string;

  @Input()
  component:Type<any>;

  searchText:string;

  @Output()
  OnRecordSelected:EventEmitter<any>=new EventEmitter<any>();

  @Output()
  OnRecordView:EventEmitter<any>=new EventEmitter<any>();

  @Output()
  OnAdd:EventEmitter<void>=new EventEmitter();

  @Output()
  OnUnlink:EventEmitter<void>=new EventEmitter();

  @Output()
  OnExplore:EventEmitter<void>=new EventEmitter();

  @Input()
  showSearchButton:boolean=false;

  @Input()
  showAddButton:boolean=false;

  @Input()
  showViewButton:boolean=false;

  @Input()
  showUnlinkButton:boolean=false;

  @Input()
  recordSelected:any;
  

  list:any[]=[];

  constructor() { }

  ngOnInit(): void {
  }

  search(event){

    //escludi le frecce 
    if(event.keyCode>= 37 && event.keyCode<=40){
      return;
    }

    const text=event.target.value;
    if(this.OnSelect(text))
      return;
    if(text.length>3){

      this.service.search(text).subscribe((items)=>{
        this.list=items;
        
      });
    }
  }

  OnSelect(text){
    if(this.list.length>0){
      for(let l of this.list){
        if(text==l[this.fieldRender]){
          this.recordSelected=l;
          this.OnRecordSelected.emit(this.recordSelected);
          return true;
        }
      }
    }
    return false;
  }

  edit(){
    this.OnExplore.emit();
    //this.recordSelected=null;
    //this.OnRecordSelected.emit(this.recordSelected);
  }

  view(){
    this.OnRecordView.emit(this.recordSelected);
  }

  add(){
   this.OnAdd.emit();
  }

  explore(){
    this.OnExplore.emit();
  }

  unlink(){
    this.OnUnlink.emit();
  }

}
