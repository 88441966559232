import {Address} from './address';
import { SNAvaible } from '../db/inventories';
import { Image } from './image';
import { CustomField } from 'projects/core/src/include/structures';


export class Pricelist{
    id:number;
    tag:string;
    price:number;
    markup:number;
    grossprice:number;
    tax:number;
}

export class stock{
    id:number;
    id_product:number;
    id_inventory:number;
    inventoryname:string;
    stock:number;
    serials:SNAvaible[];
    min_stock:number;
}


export class Product{
    id:number;
    name:string;
    id_brand:number;
    brand:string;
    category:string="";
    customfieldsList:CustomField[]=[];
    defaultcustomfields:string="";
    status:number;
    id_category:number;
    //netprice:number;
    tax:number;
    taxcost:number;
    grossprice:number;
    netcost:number;
    discountcost1:number;
    discountcost2:number;
    discountcost3:number;
    grosscost:number;
    
    id_supplier:number;
    note:string;
    supplier:Address;
    unit:string;
    weight:number;
    length:number;
    width:number;
    height:number;
    barcode:string;
    barcode_pack:string;
    barcode_quantity:number;
    stock:number;
    stocks:stock[];
    pricelist:any;
    codeinternal:string;
    codeexternal:string;
    has_serials:boolean;
    serials:SNAvaible[];
    sn:string;
    snpattern:string;
    icon:string;
    discount:number=0;
    state_reference:any;
    state_description:string;
    minstock:number;
    maxdiscount:number;
    description:string;
    images:Image[];
    title:string;
    tags:string;
    stockable:boolean;
    rentable:boolean;
    id_type:number;
    type:string;
    correlations:Product[];
    pricelistname:string;
    price:number;
    constructor(){
        this.pricelist=[];
        this.images=[];
        this.serials=[];
        this.correlations=[];
        this.stocks=[];
        this.customfieldsList=[];
    }

    get netprice():number{
        return this.firstPrice();
    }


    set netprice(value:number){
        if(!Array.isArray(this.pricelist)){
            try{
                this.pricelist=JSON.parse(this.pricelist);
            }catch{
                this.pricelist=[];
               
                
            }
        }

        if(this.pricelist.length==0){
            let p:Pricelist=new Pricelist();
            p.price=value;
            p.grossprice=p.price*(1+this.tax/100);
            p.tag="";
            p.id=0;
            this.pricelist.push(p);
        }else{
            this.pricelist[0].price=value;
            this.pricelist[0].grossprice=this.pricelist[0].price*(1+this.tax/100);
        }




    }


    firstPrice():number{
        if(!Array.isArray(this.pricelist)){
            try{
                this.pricelist=JSON.parse(this.pricelist);
            }catch{
                this.pricelist=[];
            }
        }

        if(this.pricelist.length>0){
            
            return this.pricelist[0].price;
        }

       


        return 0;
    }
    
    getStockInventory(id_inventory){
        for(let s of this.stocks){
            if(s.id_inventory==id_inventory){
                return s.stock;
            }
        }
    }
    getPrice(quantity,type=2,address:Address=null){
        if(type==1){ //acquisto
            this.price=this.netcost;
           return this.netcost;
        }
           else{

            this.checkPricelist();

            if(this.pricelist.length>0){
                if(address){
                    for(let i=0;i<this.pricelist.length;i++){
                        let tag=this.pricelist[i].tag;
                        let row;
                        if(typeof(tag)=="object"){
                            row=tag;
                        }else{
                            try{
                                row=JSON.parse(tag);
                               
                                
                            }catch{
                                row={"id_type":0,"name":"Tutti"};
                            }
                        }
                        
                        
                         //cerca il parametro
                        let keys=Object.keys(row);
                            for (let k of keys){
                                if(address[k]==row[k]){
                                    this.pricelistname=row['name'];
                                    this.price=this.pricelist[i].price;
                                    return this.pricelist[i].price;
                                }
                            }


                    }
                }
                this.pricelistname="Tutti";
                this.price=this.pricelist[0].price;
                return this.pricelist[0].price;
            }

            

            return 0;
        }
            
    }

    getPriceByTag(tag:string){
        this.checkPricelist();
        if(this.pricelist.length>0){
            for(let p of this.pricelist)
                if(p.tag.toUpperCase()==tag.toUpperCase())
                    return p.price;
        }

        return 0;
    }

    checkPricelist(){
        if(!Array.isArray(this.pricelist)){
            try{
                this.pricelist=JSON.parse(this.pricelist);
            }catch{
                this.pricelist=[];
            }

            this.pricelist.forEach(element => {
                element.tax=this.tax;
                element.grossprice=element.price*(1+element.tax/100);
            });
        }
    }

    getEquivalentDiscountCost(){
        let s1=this.discountcost1/100;
        let s2=this.discountcost2/100;
        let s3=this.discountcost3/100;
        let s=s1+s2+s3-s1*s2-s2*s3-s1*s3+s1*s2*s3;

        return s*100;
    }
   
    parseImages(){
        let images=[];
        try{
            images=JSON.parse(this.images.toString());
        }catch{
            images=[];
        }
        this.images=[];
        for(let i of images){
            let img=new Image();
            Object.assign(img,i);
            this.images.push(img);
        }


    }

    coverImage(){
        if(typeof(this.images)=="string"){
            this.parseImages();
        }
        if(this.images){
            if(this.images.length>0){
                return this.images[0];
            }else{
                return new Image();
            }
        }else{
            return new Image();
        }
    }

}