import { Component, OnInit } from '@angular/core';
import { ModelList } from '../../include/modellist'
import { UntypedFormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Filter,FilterMode,Notification, NotificationMessages,Condition } from '../../include/structures'

import {NotificationService} from '../../lib/notification.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.sass']
})
export class NotificationComponent extends ModelList<Notification> implements OnInit {

  public Editor = ClassicEditor;
  editorOn=true;


  filter_type:Filter=new Filter();
  selectedNotify:Notification={} as Notification;

  gateway_email:NotificationMessages;
  gateway_sms:NotificationMessages;
  gateway_whatsapp:NotificationMessages;

  constructor(
    private notificationService: NotificationService,
    
    
    private fb1: UntypedFormBuilder,
  ) { 
    super(notificationService,fb1);
  }

  ngOnInit(): void {
    this.title='Notifiche';

    super.ngOnInit.apply(this, arguments); 

   


    this.filter_type.mode=FilterMode.normal;
    this.filter_type.fields=[];
    this.filter_type.fields.push("n.table");
    this.filter_type.value="";
    this.filter.push(this.filter_type);

    this.ordering.field="n.id";

    this.getItems();

  }
  addNotification(){
    let r:Notification={} as Notification;
    r.status=1;
    
   
    this.selectedNotify=r;
  }
  setNotify(record:Notification){
    
    this.selectedNotify=Object.assign({}, record);

    this.selectedNotify.conditionsArray=[];
    if(this.selectedNotify.conditions!="")
      this.selectedNotify.conditionsArray=JSON.parse(this.selectedNotify.conditions);


    if(this.selectedNotify.gateways==undefined)
      this.selectedNotify.gateways=[];
    

    if(this.selectedNotify.gateways.length>0){
      for(let i=0;i<this.selectedNotify.gateways.length;i++)
        switch(this.selectedNotify.gateways[i].gateway){
          case "email":
            this.gateway_email=this.selectedNotify.gateways[i];
            break;
          case "sms":
            this.gateway_sms=this.selectedNotify.gateways[i];
            break;
          case "whatsapp":
            this.gateway_whatsapp=this.selectedNotify.gateways[i];
            break;
          
          }
      
    }else{
      this.gateway_email={} as NotificationMessages;
      this.gateway_email.gateway="email";
      this.selectedNotify.gateways.push(this.gateway_email);

      this.gateway_sms={} as NotificationMessages;
      this.gateway_sms.gateway="sms";
      this.selectedNotify.gateways.push(this.gateway_sms);

      this.gateway_whatsapp={} as NotificationMessages;
      this.gateway_whatsapp.gateway="whatsapp";
      this.selectedNotify.gateways.push(this.gateway_whatsapp);


    }
      
      
   

  }

  confirmNotify(){
    this.selectedNotify.conditions=JSON.stringify(this.selectedNotify.conditionsArray);
    this.notificationService.save(this.selectedNotify,(id)=>{
      this.getItems();
    });
  }

  addCondition():void{
    let c:Condition={} as Condition;
    if(this.selectedNotify.conditionsArray==undefined)
      this.selectedNotify.conditionsArray=[];

    this.selectedNotify.conditionsArray.push(c);
  }

  removeCondition(c:Condition):void{
    for(let i=0;i<this.selectedNotify.conditionsArray.length;i++)
      if(this.selectedNotify.conditionsArray[i]==c)
        this.selectedNotify.conditionsArray.splice(i,1);
  }
    
  confirm_delete(id){
    confirm("Eliminare l'elemento?")?this.delete(id):false;
  }



}
