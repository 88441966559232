<ng-container *ngIf="!showOnlyTree && !showList">
    <span class="form-control form-control-sm pointer text-truncate" (click)="open();" 
        [class.text-purple]="financialaccount" title="Seleziona conto contabile">
        <i class="fas fa-coins mr-2 py-1"></i>
        <span class="text-gray" *ngIf="!financialaccount">Seleziona...</span>
        <span *ngIf="financialaccount">{{financialaccount}}</span>
    </span>
</ng-container>

<ng-container *ngIf="showList">
    <app-tree [tree]="financialaccounts" (onSelect)="selectNode($event);confirm()"  ></app-tree>
</ng-container>

<ng-template #modalTree>
    <div class="modal-header bg-light">
        <b>Seleziona il conto contabile</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="close()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <!--<tree-root [nodes]="financialaccounts" [focused]="true" (activate)="selectNode($event)" [options]="options"></tree-root>-->
        <app-tree [tree]="financialaccounts" (onSelect)="selectNode($event);confirm()"  ></app-tree>
    </div>
   <!-- <div class="modal-footer">
        <button class="btn btn-ouline-secondary btn-block" (click)="confirm()">Chiudi</button>
    </div> -->
</ng-template>