import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemService } from '../../lib/system.service';

@Component({
  selector: 'lib-btn-select-field',
  templateUrl: './btn-select-field.component.html',
  styleUrls: ['./btn-select-field.component.css']
})
export class BtnSelectFieldComponent implements OnInit {
  @ViewChild("modalFields")
  modalFields:TemplateRef<any>;
  modalFieldsRef;

  @Input()
  table:string;

  @Output()
  fieldSelected:EventEmitter<any>=new EventEmitter();

  list=[];

  constructor(
    private modalService:NgbModal,
    private systemService:SystemService
  ) { }

  ngOnInit(): void {
  }

  openModalFields(){
  this.systemService.getStructureTable(this.table).subscribe((items)=>{
    this.list=items;
    this.modalFieldsRef=this.modalService.open(this.modalFields);
  });

   
  }

  closeModalFields(){
    this.modalFieldsRef.close("success");
  }

  selectField(name){
    this.fieldSelected.emit(name);
    this.closeModalFields();
  }

}
