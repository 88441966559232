

<!-- elemento ripetuto -->
<div class="form-group row mb-1" *ngIf="isListValid()">
    <ng-container *ngFor="let record of list">                            
        <div class="col-sm-12" *ngIf="record.type=='separator'">
            <hr class="my-1" *ngIf="record.label!=''" />
            <h5 class="text-left mb-0" *ngIf="record.label!=''">{{record.label}}</h5>
        </div>
        <div [class.col-sm-2]="layout=='horizontal'" [class.col-sm-12]="layout=='vertical'" *ngIf="record.type!='separator'">
            <label *ngIf="record.type!='hidden'" title="{{record.id_customfield}}">{{record.label}}</label>
        </div>
        <div  *ngIf="record.type!='separator'" [class.col-sm-4]="layout=='horizontal'" [class.col-sm-12]="layout=='vertical'" class="align-middle mb-1" >
            <!-- text -->
            <input *ngIf="record.type=='text'" type="text" name="value" class="form-control form-control-sm" [readonly]="record.option=='readonly'" placeholder="..." [(ngModel)]="record.value">
            <!-- textarea -->
            <textarea *ngIf="record.type=='textarea'" name="value" class="form-control form-control-sm" [readonly]="record.option=='readonly'" placeholder="..." [(ngModel)]="record.value"></textarea>
            <!-- hidden -->
            <input *ngIf="record.type=='hidden'" type="hidden" name="value" class="form-control form-control-sm"  placeholder="..." [(ngModel)]="record.value">
            <!-- tax code -->
            <app-tax-code *ngIf="record.type=='tax_code'" [record]="origin" [showReverseBtn]="true"  [tax_code]="record.value" (onTextChange)="record.value=$event" [options]="record.option"></app-tax-code>            
                <!--
                <input *ngIf="record.type=='tax_code'" type="text" class="form-control form-control-sm" [class.is-invalid]="!verifyTaxCode(record)" [class.is-valid]="record.valid" required="required"
                    name="value"  placeholder="Es. XXXXXX00X00X000X" maxlength="16" #tax_code="ngModel" [(ngModel)]="record.value" pattern="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$" title="{{tax_code_error}}"  >
                -->
            <!-- date -->
            <input *ngIf="record.type=='date'" type="date"  name="value" class="form-control form-control-sm"  [readonly]="record.option=='readonly'" placeholder="..." [(ngModel)]="record.value">
            <!-- number -->
            <input *ngIf="record.type=='number'" type="number" name="value" class="form-control form-control-sm pr-0"  [readonly]="record.option=='readonly'" placeholder="..." [(ngModel)]="record.value">
            <!-- currency -->
            <div class="input-group" *ngIf="record.type=='currency'" >
                <div class="input-group-prepend ">
                    <span class="input-group-text">&euro;</span>
                </div>
                <input type="number" class="form-control form-control-sm"  min="0" step="0.01" name="value"  [readonly]="record.option=='readonly'" placeholder="..." [(ngModel)]="record.value" >
            </div>
            <!-- url -->
            <div class="input-group" *ngIf="record.type=='url'" >
                <input type="url" class="form-control form-control-sm"  name="value" placeholder="..." [(ngModel)]="record.value" >
                <div class="input-group-append ">
                    <span class="input-group-text"><a target="_blank" href="{{record.value}}"><i class="fas fa-eye text-gray-dark"></i></a></span>
                </div>
            </div>
            <!-- select -->
            <div *ngIf="(record.type=='select' && tag!='features')">
                <select class="form-control form-control-sm"  name="value" [(ngModel)]="record.value">
                    <optgroup *ngIf="record.option!=null && record.option!=''">
                        <option  *ngFor="let o of record.option" value="{{o.item}}">{{o.item}}</option>
                    </optgroup>
                </select>
            </div>
            <div *ngIf="(record.type=='select' && tag=='features')">
                <ngx-tags-input displayField="item" class="form-control form-control-sm" [(ngModel)]="record.option" name="option"></ngx-tags-input>
            </div>
            <!-- checkbox -->
            <div *ngIf="record.type=='checkbox'" >  
                <select class="form-control form-control-sm"  name="value" [(ngModel)]="record.value">
                    <option value="1">Si</option>
                    <option value="0">No</option>
                </select>
            </div>
            <!-- multi -->
            <div *ngIf="record.type=='multi'" >
                <table class="table" *ngIf="record.value!=undefined">
                    <tr *ngFor="let r of record.value.split('|')">
                        <td>{{r}}</td>
                        <td><button class="btn btn-danger btn-sm" (click)="removeTextOnMulti(record,r)"><i class="fa fa-trash"></i></button></td>
                    </tr>
                </table>
                <button type="button" class="btn btn-success btn-sm" (click)="addTextOnMulti(record)"><i class="fa fa-plus"></i></button>
            </div>
            <!-- image -->
            <div *ngIf="record.type=='image'" >
                <div class="image-container">
                    <ng-container *ngIf="record.value">
                        <img src="{{getBasePath(record.value)}}" class="img-thumbnail">
                        <button class="btn btn-danger btn-sm" style="position: absolute;left: 0px;top: -7px;" (click)="record.value=''"><i class="fa fa-times"></i></button>
                    </ng-container>
                    <button type="button" class="btn btn-secondary btn-block"  (click)="uploadFile(record)">Carica</button>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<button type="button" class="btn btn-default btn-xs float-right" (click)="openImport()"><i class="fas fa-file-import mr-2"></i>Importa campi da file CSV</button>