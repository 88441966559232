import { Component, OnInit } from '@angular/core';
import { Helper } from 'projects/core/src/helper';
import { Filter, FilterMode } from 'projects/core/src/include/structures';
import { DocumentsService } from '../../../services/documents.service';

@Component({
  selector: 'app-documentoverview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {


  date_from: Date=new Date();
  date_to: Date=new Date();
  filter_date:Filter=new Filter();  
  created_from:number=0;
  list=[];
  tab=1;
  list_month=[];
  total={
    "total_out":0,
    "total_in":0,
    "total_difference":0,
    "total_pay":0,
    "total_paid":0
    
    

  }

  selectedYear=new Date().getFullYear();
  constructor(
    private documentsService:DocumentsService
  ) { }

  ngOnInit(): void {

    this.filter_date.mode=FilterMode.date;
    this.filter_date.fields=[];
    this.filter_date.value=Helper.convertDateControl(this.date_from);
    this.filter_date.value2=Helper.convertDateControl(this.date_to);
    this.tab=1;
    this.getMonths();
    
  }

  getDocuments(){

    
    this.documentsService.getOverview(this.filter_date.value,this.filter_date.value2,this.created_from).subscribe((items)=>{
      this.list=items;
      
    });
  }

  getMonths(){
    this.documentsService.getOverviewByMonths(this.selectedYear,this.created_from).subscribe((items)=>{
      this.list_month=items;
      this.calculateTotalMonths();
      
    });
  }

  calculateTotalMonths(){

    this.total={
      "total_out":0,
      "total_in":0,
      "total_difference":0,
      "total_pay":0,
      "total_paid":0
    }

    for(let r of this.list_month){
      this.total.total_in=this.total.total_in+r.total_in;
      this.total.total_out=this.total.total_out+r.total_out;
      this.total.total_difference=this.total.total_difference+(r.total_out-r.total_in);
      this.total.total_pay=this.total.total_pay+r.total_pay;
      this.total.total_paid=this.total.total_paid+r.total_paid;
      
    }


  }

}
