import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { ToastMode } from 'projects/core/src/include/structures';
import { RegistrationService } from '../../services/registration.service';

@Component({
  selector: 'app-insertpassword',
  templateUrl: './insertpassword.component.html',
  styleUrls: ['./insertpassword.component.css']
})
export class InsertpasswordComponent implements OnInit {

  @Input()
  username:string;

  @Output()
  OnResult:EventEmitter<any>=new EventEmitter();

  password:string;

  constructor(private registrationService:RegistrationService) { }

  ngOnInit(): void {

    Globals.access.loginEvent.subscribe((result)=>{
      if(result){
        this.OnResult.emit();
      }
    });
  }

  login(){

    
    Globals.access.onLogin(this.username,this.password,"1","",false,false,false);
  }

  cancel(){
    this.OnResult.emit();
  }

  sendPassword(){
    this.registrationService.recoverPassword(this.username).subscribe((items)=>{
      Globals.message.showToaster("A breve riceverai le tue credenziali al tuo indirizzo email",ToastMode.WARNING);
      
    });
  }

}
