import { Component, OnInit} from '@angular/core';
import { ModelList } from '../../include/modellist'
import { UntypedFormBuilder  } from '@angular/forms';
import { Filter,FilterMode,Template} from '../../include/structures'
import { TemplatesService } from '../../lib/templates.service';
import { Globals } from '../../globals';
import { TemplatesDetailComponent } from './detail/detail.component';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.sass']
})
export class TemplatesComponent extends ModelList<Template> implements OnInit {
 

 

 
  filter_type:Filter=new Filter();
  filter_search:Filter=new Filter();

  /*
  example:any;
  selectedOrigin:any;
  selectedTemplate:Template={} as Template;
  */
  get user(){
    return Globals.user;
  }

  constructor(
    private fb1: UntypedFormBuilder,
    private templatesService: TemplatesService,
  ) {
    super(templatesService,fb1);
    this.detailViewModal=TemplatesDetailComponent;

   }

  ngOnInit(): void {
    this.title='Modelli di esportazione';
    super.ngOnInit.apply(this, arguments); 

    this.filter_type.mode=FilterMode.normal;
    this.filter_type.fields=[];
    this.filter_type.fields.push("t.table");
    this.filter_type.value="";
    this.filter.push(this.filter_type);

    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("t.name");
    this.filter_search.fields.push("t.description");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.getItems();
  }

  /*

  addTemplate(){
    let r:Template={} as Template;
    r.status=1;
    r.source="local";
    
    if(this.filter_type.value!="")
      r.table=this.filter_type.value;
    this.selectedTemplate=r;
  }

  setRule(record:Template){
    
    this.selectedTemplate=Object.assign({}, record);
    this.selectedTemplate.originsArray=[];
    if(this.selectedTemplate.origins!="")
      this.selectedTemplate.originsArray=JSON.parse(this.selectedTemplate.origins);

    this.selectedTemplate.requestparamsArray=[];
    if(this.selectedTemplate.requestparams!="")
      this.selectedTemplate.requestparamsArray=JSON.parse(this.selectedTemplate.requestparams);
    
    this.getSections();

  }

  confirmTemplate(oncomplete=null):void{
    
    this.selectedTemplate.origins=JSON.stringify(this.selectedTemplate.originsArray);
    this.selectedTemplate.requestparams=JSON.stringify(this.selectedTemplate.requestparamsArray);
    
    this.templatesService.save(this.selectedTemplate,(id)=>{
      this.getItems();
      if(oncomplete)
        oncomplete();
    });
  }


  addOrigin():void{
    let c:Origin={} as Origin;
    if(this.selectedTemplate.originsArray==undefined)
      this.selectedTemplate.originsArray=[];

    this.selectedTemplate.originsArray.push(c);
  }

  removeOrigin(c:Origin):void{
    for(let i=0;i<this.selectedTemplate.originsArray.length;i++)
      if(this.selectedTemplate.originsArray[i]==c)
        this.selectedTemplate.originsArray.splice(i,1);
  }



  addRequestParam():void{
    let c:RequestParam={} as RequestParam;
    c['type']="text";
    if(this.selectedTemplate.requestparamsArray==undefined)
      this.selectedTemplate.requestparamsArray=[];

    this.selectedTemplate.requestparamsArray.push(c);
  }

  removeRequestParam(c:RequestParam):void{
    for(let i=0;i<this.selectedTemplate.requestparamsArray.length;i++)
      if(this.selectedTemplate.requestparamsArray[i]==c)
        this.selectedTemplate.requestparamsArray.splice(i,1);
  }

  getExample(c:Origin){
      let args=prompt("Inserire i parametri necessari per ottenere un esempio di origine");
      if(args!=null){
        Globals.ws.requestUrl(c.table,c.task,[args]).subscribe((result)=>{
          this.example=result;
        });

      }
  }

  getPreview(){
    let args=prompt("Inserire i parametri necessari per ottenere un anteprima di esempio");
      if(args){
        this.confirmTemplate(()=>{
          let m={};
          m['where']=args;
          Globals.setLoading(true);
          this.templatesService.openTemplate(this.selectedTemplate.id,JSON.stringify(m)).subscribe((url)=>{
            Globals.setLoading(false);
            window.open(url,"_blank");
          });
        });
        
      }

  }
  
  confirm_delete(id){
    confirm("Eliminare l'elemento?")?this.delete(id):false;
  }

  

  
  

  getSections(){
    this.sections=[];
    for(let s of Globals.sections){
      if(s.table==this.selectedTemplate.table){
        this.sections.push(s.name);
      }
    }
  }
*/

  checkPermission(record,action="edit"){
    if(action=="edit"){
    
      if(record.source=="remote"){
        if(Globals.user.role=="superuser"){
          return true;
        }
      }else{
        return true;
      }

      return false;
    }
    if(action=="delete"){
      if(record.source=="remote")
        return false;

      return true;
    }

  }

  

}
