<app-modal title="Gestione categorie" class="modal-xl">
    <div class="row">
        <div class="col-12">
            <button class="btn btn-primary btn-xs float-right m-1" (click)="add()"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;Aggiungi</span></button>
       
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-treecategories #tree [list]="items"></app-treecategories>
        </div>
    </div>
</app-modal>