import { Component, OnInit,Input } from '@angular/core';
import {CustomField, FilterAdvanced} from 'projects/core/src/include/structures'
import { CustomfieldsService } from 'projects/core/src/lib/customfields.service';



@Component({
  selector: 'app-advancedsearch',
  templateUrl: './advancedsearch.component.html',
  styleUrls: ['./advancedsearch.component.sass']
})






export class AdvancedsearchComponent  implements OnInit {

  @Input()
  table:string;
  @Input()
  id_field:string;

  customfields:CustomField[]=[];

  constructor(private customfieldsService:CustomfieldsService ) {
   



  }
  


  ngOnInit(): void {
    this.customfieldsService.getCustomFields(this.table,false).subscribe((items)=>{
      
      for(let c of items){
        if(c.type=="select"){
          //parserizza le opzioni
          if(c.option==null)
            c.option=[];
          else{
            try{
              if(typeof(c.option)=="string")
                c.option=JSON.parse(c.option);
              
            }catch{
              c.option=[];
            }
          }
        }
      }
      
      
      this.customfields=items;
      //forza il ricaricamento del filtro
      for(let i=0;i<this.filters.length;i++){
        this.filters[i].customfield=this.customfields.filter((c)=>c.id==this.filters[i].customfield.id)[0];

        
        
      }
     
    });

  }

  @Input()
  filters:FilterAdvanced[]=[]


  addFilter():void{
    let c:FilterAdvanced=new FilterAdvanced();
    c.table=this.table;
    c.id_field=this.id_field;
    c.condition="ugual";
    c.nextCondition="INTERSECT";
    if(this.filters==undefined)
        this.filters=[]

    this.filters.push(c);
  }

  removeFilter(c:FilterAdvanced):void{
    for(let i=0;i<this.filters.length;i++){
      if(this.filters[i]==c){
        this.filters.splice(i,1);
        return;
      }

    }
  }

  search(){
    
  }


}
