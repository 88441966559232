import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FinancialAccountsService } from '../../services/financialaccounts.service';
import { ModelList } from 'projects/core/src/include/modellist';
import { UntypedFormBuilder } from '@angular/forms';
import { FinancialAccount } from '../../db/financialaccount';

@Component({
  selector: 'app-financialaccount',
  templateUrl: './financialaccount.component.html',
  styleUrls: ['./financialaccount.component.css']
})
export class FinancialaccountComponent extends ModelList<FinancialAccount> implements OnInit,AfterViewInit  {

  @ViewChild("modalTree")
  modalTree:TemplateRef<any>;
  modalTreeRef;

  @Input()
  id_financialaccount:number;

  @Input()
  financialaccount:string;

  @Input()
  autoOpen=false;

  @Input()
  showOnlyTree=false;

  @Input()
  showList=false;

  @Input()
  showAll=false;

  @Input()
  classname="form-control form-control-sm"

  @Input()
  children:boolean=false;
  @Output()
  selected:EventEmitter<any>=new EventEmitter<number>();

  financialaccounts=[];
  options = {};
  openTree=false;
  nodeSelected;
  

  constructor(
    private financialAccountsService:FinancialAccountsService,
    private fb1: UntypedFormBuilder,
    private modalService:NgbModal

  ) {super(financialAccountsService,fb1); }


  ngOnInit(): void {
    this.title = 'Conti Contabili';
    super.ngOnInit.apply(this, arguments);

    
  }

  ngAfterViewInit(): void {
    this.financialAccountsService.getAccounts().subscribe((items_c)=>{
      this.financialaccounts=items_c;

      if(this.showOnlyTree){
        this.open();
      }

      if(this.showAll){
        let a=[];
        a['id']="";
        a['name']="Tutti i conti contabili";
        a['children']=[];        
        if (this.financialaccounts[0].name!='Tutti i conti contabili') 
          this.financialaccounts.unshift(a);     
      } else {
        let a=[];
        a['id']="";
        a['name']="Nessun conto contabile";
        a['children']=[];
        if (this.financialaccounts[this.financialaccounts.length - 1].name!='Nessun conto contabile') 
          this.financialaccounts.push(a);
      }

      this.ngOnChanges();
    });
  }

  ngOnChanges(){

    if(this.id_financialaccount){
      let n=this.searchNode(this.financialaccounts,this.id_financialaccount);
      if(n){
        this.financialaccount=n.name;
    
      }
    }

    
  }


  selectNode($event){
    //if($event.node.data.children.length==0){
      this.financialaccount=$event.name;
      this.nodeSelected=$event;
      
    //}
  }

  


  searchNode(nodes,id){
    let n_find=null;
    for(let n of nodes){
      if(n['id']==id){
        n_find=n;
      }
      if(n['children'].length>0){
        n_find=this.searchNode(n['children'],id);
      }

      if(n_find){
        return n_find;
      }
      
    }

    return n_find;
  }


  open(){
    this.modalTreeRef=this.modalService.open(this.modalTree);
  }

  confirm(){
    if(this.nodeSelected){
      if(this.children){
        //prendi tutti gli id del nodo e dei figli
        let array=[];
        this.getId(this.nodeSelected,array);
        this.selected.emit({"id":array.join(",")});
   
      }else{
        this.selected.emit({"id":this.nodeSelected.id,"name":this.nodeSelected.name});
   
      }
    }

    if(this['modalWindow']){
      this['modalWindow'].close("success");
    }

    this.modalTreeRef.close("success");
  }

  getId(node,array_id=[]){
    if(node.children.length>0){
      array_id.push(node.id);
      for(let c of node.children){
        this.getId(c,array_id);
      }
    }else{
      array_id.push(node.id);
    }
  }

  close(){

    if(this['modalWindow']){
      this['modalWindow'].close("success");
    }

    this.modalTreeRef.close("success");
  }
}
