import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cash-flow-container',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.sass'],
})

export class CashFlowContainerComponent  implements OnInit {
  tab=1;
  ngOnInit(){

  }

}
