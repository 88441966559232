import { Component, ViewChild, OnInit, Input, TemplateRef} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Ticket, Passenger,TicketDetail } from  '../../../ticket';

import { Card } from '../../../card';
import {NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup ,UntypedFormControl,Validators} from '@angular/forms';
import { Model } from 'projects/core/src/include/model';
import { Type } from '../../../../../../../c1-backend/src/app/db/type';
import { CustomfieldsComponent } from 'projects/core/src/common/customfields/customfields.component';
import { UploadService } from 'projects/core/src/lib/upload.service';
import { Globals } from 'projects/core/src/globals';
import { AddressDetailsComponent } from '../../../../../../../c1-backend/src/app/views/address/address-details/address-details.component';
import { AddressComponent } from '../../../../../../../c1-backend/src/app/views/address/address.component';
import { AddressesService } from 'projects/c1-backend/src/app/services/addresses.service';
import { TicketsService } from '../../../services/tickets.service';
import { TypesService } from 'projects/c1-backend/src/app/services/types.service';
import { CardsService } from '../../../services/cards.service';
import { NotificationService } from 'projects/c1-backend/src/app/services/notification.service';



@Component({
  selector: 'app-tickets-details',
  templateUrl: './tickets-details.component.html',
  styleUrls: ['./tickets-details.component.sass']
})

export class TicketsDetailsComponent extends Model<Ticket> implements OnInit {
  
  /**FORM UPLOAD */
  formUpload = new UntypedFormGroup({

    
    file: new UntypedFormControl('', [Validators.required]),

    fileSource: new UntypedFormControl('', [Validators.required])

  });
  uploadResponse;




  fileToUpload: File = null;

  type_ticket:Type[];
  detailForm;
  

  @Input() db_cities: Array<any>

  backLink="/tickets";
  

  @ViewChild(CustomfieldsComponent) customfieldsView:CustomfieldsComponent;
  

  @ViewChild("modalUpload")
  modalUpload:TemplateRef<any>;
  modalUploadRef;

  cards:Card[];

  selectedTicketDetail:TicketDetail;


  constructor(
    private route1: ActivatedRoute,
    private ticketsService: TicketsService,
    private typesService: TypesService,
    private cardsService:CardsService,
    private notificationService:NotificationService,

    private modalService: NgbModal,
    private uploadService:UploadService,
    public addressesService:AddressesService,

    ) {
      super(route1,ticketsService); 
    
    
  }

  

  ngOnInit (): void {

    this.title = 'Biglietto Dettaglio';
    this.backLink="/tickets";

    super.ngOnInit.apply(this, arguments); 
    
    if(this.mode!="modal")
      this.route1.params.subscribe((params) => this.id = params.id);

    this.typesService.getTypes("tickets").subscribe((items)=>{
      this.type_ticket=items;
      this.getItem();
   });
   
   this.cardsService.getCards().subscribe((items)=>{
      this.cards=items;
    });


    this.afterGetItem=(()=>{
      if(this.record.id==0 || this.record.id==undefined){
        this.record.id_type=this.type_ticket[0].id;
        this.record.customfields=[];
      }else{
        for(let r of this.record.tickets){
          if(r.listpassengers!="")
            r.passengersArray=JSON.parse(r.listpassengers);
          }
      }
    })

    this.afterSave=(()=>{
      if(confirm("Inviare una notifica al cliente?")){
        this.notificationService.showPreviewNotification(this.record.addressItem['contacts'],this.record,"tickets",()=>{

        },this.record.id_address);
      }
     
    })

  }

  save(reloadItem:boolean=true,oncomplete:Function=null):void{

    for(let r of this.record.tickets){
      r.listpassengers=JSON.stringify(r.passengersArray);
    }
    
    super.save(reloadItem,oncomplete);    
  }

  addPassenger(ticket):void{
    if(ticket.passengersArray==undefined)
    ticket.passengersArray=[];
    let p:Passenger={} as Passenger;
    p.seat="";
    p.name="";
    p.lastname="";
    ticket.passengersArray.push(p);
  }
  
  removePassenger(passengersArray:Passenger[],p:Passenger):void{
    for (let i=0;i<passengersArray.length;i++){
      if(p==passengersArray[i]){
        passengersArray.splice(i,1);
        return;
      }

    }
  }

  bookAll():void{
    this.ticketsService.bookAll(this.id);

  }

  book(id:number):void{
    //this.ticketsService.book(id);

  }

  confirmAll():void{
    Globals.message.showToaster("Controllo online in corso ...");
    this.ticketsService.confirmAll(this.id).subscribe((result)=>{
      
      for(let t of result){
        for (let t2 of this.record.tickets){
          if(t['ticket'].id==t2.id)
            t2.file=t['ticket'].file;
        }
      }
       

      if(result[0]['response']=="OK"){
        Globals.message.showToaster("Conferma biglietto completata","success");
        
      }else{
        Globals.message.showToaster(result[0]['message']);
      }

      //this.getItem();
    });
  }

  confirm(id:number):void{
    //this.ticketsService.confirm(id).subscribe((item)=>{
      //console.log(item);
    //});
  }


  downloadPDF(item:TicketDetail){
   

    if(item.file==""){

      item.id_state=10;

      this.ticketsService.getPDF(item.id).subscribe((result)=>{
        window.open(result['code'],'_blank');
        item.id_state=2;
        
      });
    }else{
      window.open(Globals.config.serverUrl+item.file,"_blank");
      
    }
  }


  openUploadModal(t:TicketDetail){
    this.selectedTicketDetail=t;
    this.modalUploadRef=this.modalService.open(this.modalUpload);
  }

  closeModalUpload(){
    this.modalUploadRef.close("success");
  }


  handleFileInput(files: FileList) {
    Globals.setLoading(true);
    this.fileToUpload = files.item(0);


    this.uploadService.uploadFile(this.fileToUpload,"tickets",(result)=>{

      this.selectedTicketDetail.file=result;

    
      Globals.setLoading(false);
     
    },true);
  }

  


  onFileSelect(event) {

    if (event.target.files.length > 0) {

      const file = event.target.files[0];

      this.formUpload.patchValue({

        fileSource: file

      });
    
    }
  }

  onUpload() {
    
    let item={} as Object;
    item['directory']="documents";
    item['fileToUpload']=this.formUpload.get('fileSource').value;
   
    this.uploadService.uploadFile(item,"",
      (res) => {
        if(res['status']){
          this.selectedTicketDetail.file=res['filename'];
          
        }else{
          alert(res['message']);
        }

        this.modalService.dismissAll("success");
      }
    );
  }



  selectAddress(){
    Globals.modal.showModal(AddressComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance['recordSelected']){
        this.record.addressItem=instance['recordSelected'];
        this.record.id_address=this.record.addressItem.id;
      }
    })
  }


  OnViewAddress(r){
    Globals.modal.showModal(AddressDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":r.id}
    ],(instance)=>{
      if(instance!=null)
        this.OnSelectAddress(instance['record']);
    });
  }

  addAddress(){
    Globals.modal.showModal(AddressDetailsComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance!=null){
        this.record.addressItem=instance['record'];
        this.record.id_address=this.record.addressItem.id;
      }
    })
  }

  OnSelectAddress(r){
    if(r){
      this.record.addressItem=r;
      this.record.id_address=r.id;
    }
  }
  


}
