<app-window [title]="title">

    <ul class="nav nav-tabs" id="myTab" role="tablist">        
        <li class="nav-item ">
            <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#tabAds1" role="tab" aria-controls="profile" aria-selected="false">
                <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Generale</span></a>
        </li>

        <li class="nav-item ">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabAds2" role="tab" aria-controls="profile" aria-selected="false">
                <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Ubicazione</span></a>
        </li>

        <li class="nav-item ">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabAds3" role="tab" aria-controls="profile" aria-selected="false">
                <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Offerte</span></a>
        </li>

        <li class="nav-item ">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabAds4" role="tab" aria-controls="profile" aria-selected="false">
                <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Documenti</span></a>
        </li>
        
    </ul>
    
    <div class="p-2" >
        <div class="tab-content" id="myTabContent">
            <!-- GENERALE -->
            <div class="tab-pane fade show active" id="tabAds1" role="tabpanel" aria-labelledby="tabAds1-tab">
                <form role="form" id="detailForm" *ngIf="record">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Codice</label>
                                <div class="input-group" >
                                    <input class="form-control" type="text"  name="code"  [(ngModel)]="record.code"  />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            
                            <app-type [id_type]="record.id_type" [table]="'ads'" [record]="record" ></app-type>
                             
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Categoria</label>
                                <app-adscategory [show_all]="false"  [category]="record.id_category" (selected)="record.id_category=$event;"></app-adscategory>
           
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Stato</label>
                                <select class="form-control" name="state" [(ngModel)]="record.state">
                                    <option value="1">In attesa di ricezione offerte</option>
                                    <option value="2">Offerta assegnata</option>
                                    <option value="3">Offerta conclusa</option>
                                    
                                </select>
                    
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label>Titolo</label>
                                <div class="input-group" >
                                    <input class="form-control form-control-sm" type="text"  name="title"  [(ngModel)]="record.title"  />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label>Descrizione</label>
                                <div class="input-group" >
                                    <textarea class="form-control form-control-sm"  name="description"  [(ngModel)]="record.description" ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </form>
            </div>

            <!-- UBICAZIONE -->
            <div class="tab-pane fade" id="tabAds2" role="tabpanel" aria-labelledby="tabAds2-tab">
                <div class="row">
                    <div class="col-12">
                        <app-cities 
                            [address]="record.address" 
                            [city]="record.city" 
                            [zip]="record.zip" 
                            [latitude]="record.latitude" 
                            [longitude]="record.longitude"
                        
                            (onEntrySelected)="record.address=$event.address;record.city=$event.city;record.country=$event.country;record.zip=$event.zip;"
                        
                        >
                        </app-cities>
                    </div>
                    
                    
                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Latitudine</label>
                            <div class="input-group" >
                                <input class="form-control form-control-sm" type="text"  name="latitude"  [(ngModel)]="record.latitude"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Longitudine</label>
                            <div class="input-group" >
                                <input class="form-control form-control-sm" type="text"  name="longitude"  [(ngModel)]="record.longitude"  />
                            </div>
                        </div>
                    </div>
                </div>
            
            
            </div>
             <!-- OFFERTE -->
             <div class="tab-pane fade" id="tabAds3" role="tabpanel" aria-labelledby="tabAds3-tab">
                <div class="row">
                    <div class="col-12">
                        <table class="table">
                            <thead>
                                <th>Data</th>
                                <th>Utente</th>
                                <th>Offerta</th>
                                <th>Altro</th>
                                <th></th>
                                <th></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let o of record.offers">
                                    <td>{{o.date | date:"dd/MM/YYYY"}}</td>
                                    <td>{{o.username}}</td>
                                    <td>{{o.offer | currency:"&euro;"}}</td>
                                    <td>{{o.params}}</td>
                                    <td>
                                        <ng-container *ngIf="o.id==record.id_ads_offer">
                                            <span class="badge badge-success">Offerta assegnata</span>

                                        </ng-container>
                                    </td>
                                    <td class="text-right">
                                        <button *ngIf="o.id==record.id_ads_offer" class="btn btn-danger btn-xs mr-2"  title="disassegna offerta" (click)="unlinkOffer(o)"><i class="fa fa-unlink"></i></button>
                                        <button class="btn btn-danger btn-xs" (click)="deleteOffer(o)"><i class="fa fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
             <!-- DOCUMENTI -->
             <div class="tab-pane fade" id="tabAds4" role="tabpanel" aria-labelledby="tabAds3-tab">
                <div class="row">
                    <div class="col-12">
                        <app-documentlist #documentlist [filterbox]="false" [type]="0" [mode]="'embedded'" [id_table]="record.id" [table]="'ads'"></app-documentlist>
                               
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-toolbarmodel [model]="this"></app-toolbarmodel>
</app-window>