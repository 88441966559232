import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Observable, Observer } from 'rxjs';
import { FinancialAccount } from '../db/financialaccount';

@Injectable({
  providedIn: 'root'
})
export class FinancialAccountsService extends WSService<FinancialAccount> {


  static financialaccounts=[];
  constructor() { super("financialaccounts")}

  getAccounts():Observable<any[]>{

    if(FinancialAccountsService.financialaccounts.length==0){
      Globals.ws.requestUrl("financialaccounts","getAccounts",[]).subscribe((items:any)=>{
        FinancialAccountsService.financialaccounts=items;
      });
      return Globals.ws.requestUrl("financialaccounts","getAccounts",[]);

    }else{

      function sequenceSubscriber(observer: Observer<any[]>) {
        observer.next(FinancialAccountsService.financialaccounts);
        observer.complete();
        return {unsubscribe() {}};
      }
      
        return new Observable(sequenceSubscriber);
    }

   

  }

  getAnalytics(date_from,date_to,created_from=""):Observable<any[]>{
    return Globals.ws.requestUrl("financialaccounts","getAnalytics",[date_from,date_to,created_from]);
  }
  getAnalyticsByMonths(year,created_from=""):Observable<any[]>{
    return Globals.ws.requestUrl("financialaccounts","getAnalyticsByMonths",[year,created_from]);
  }
  getList(id_financialaccount,type,from,to,created_from=""):Observable<any[]>{
    return Globals.ws.requestUrl("financialaccounts","getList",[id_financialaccount,type,from,to,created_from]);
  }

  delete(ids: string[]): Observable<FinancialAccount> {
    FinancialAccountsService.financialaccounts=[];  
    return super.delete(ids);
  }

  save(item: FinancialAccount, return_id: Function, checkdeadlinesandactions?: boolean, showAfterMessage?: boolean): void {
    FinancialAccountsService.financialaccounts=[];    
    return super.save(item,return_id,checkdeadlinesandactions,showAfterMessage);
  }
}
