import { Component, OnInit, Input ,ViewChild,HostListener,PipeTransform, Pipe, TemplateRef } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl,Validators } from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode, OrderingMode, ToastMode, User } from 'projects/core/src/include/structures';
import { UserService } from 'projects/core/src/lib/user.service';
import { UploadService } from 'projects/core/src/lib/upload.service';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { Ticket, TicketDetail } from '../../ticket';
import { Type } from 'projects/c1-backend/src/app/db/type';
import { TypesService } from 'projects/c1-backend/src/app/services/types.service';
import { NotificationService } from 'projects/c1-backend/src/app/services/notification.service';
import { AddressDetailsComponent } from 'projects/c1-backend/src/app/views/address/address-details/address-details.component';
import { SearchComponent } from '../search/search.component';
import { TicketsService } from '../../services/tickets.service';
import { CardsService } from '../../services/cards.service';
import { Toast } from 'ngx-toastr';
import { CompaniesComponent } from '../companies/companies.component';
import { TicketsDetailsComponent } from '../tickets/tickets-details/tickets-details.component';




@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-tickets-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})

export class ListComponent extends ModelList<Ticket> implements OnInit {
  @Input()
  filterbox:boolean=true;

  
  
  @HostListener('window:message', ['$event'])
  onMessage(e) {
    if(e.data)
      if('task' in e.data)
        if (e.data.task == "successPayment") {
          this.processConfirm();
          this.modalService.dismissAll("success");
        }

        if (e.data.task == "errorPayment") {
          this.modalService.dismissAll("success");
          alert("C'è stato un errore nel pagamento");
        }
  }

  /**FORM UPLOAD */
  formUpload = new UntypedFormGroup({
    method_payment: new UntypedFormControl('postepay', [Validators.required]),
    
    seats1: new UntypedFormControl('', []),
    pnr1: new UntypedFormControl('', []),
    file1: new UntypedFormControl('', []),
    fileSource1: new UntypedFormControl('', []),
    seats2: new UntypedFormControl('', []),
    pnr2: new UntypedFormControl('', []),
    file2: new UntypedFormControl('', []),
    fileSource2: new UntypedFormControl('', [])

  });
  uploadResponse;
  fileToUpload: File = null;

  @ViewChild("modalUpload")
  modalUpload:TemplateRef<any>;
  modalUploadRef;

  @ViewChild("modalPay")
  modalPay:TemplateRef<any>;
  modalPayRef;

  @Input()
  id_address:number=0;
  

  date_from: Date=new Date();
  date_to: Date=new Date();
  today=new Date;


  type_ticket:Type[];
  status_ticket:Type[];

  filter_search:Filter=new Filter();
  filter_date:Filter=new Filter();  
  filter_id_type:Filter=new Filter();
  filter_id_state:Filter=new Filter();
  filter_source:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_method_payment:Filter=new Filter();  
  filter_id_address:Filter=new Filter();
  filter_id_user:Filter=new Filter();
  filter_company:Filter=new Filter();

  users:User[];
  
  isFilterSet:boolean=false;

  selectedTicket:Ticket;

  paygatewayurl:string="";
  onloadPayment=true;
  total=0;

  cards=[];
  id_card_default="";
  companies=[];
  constructor(
    private fb1: UntypedFormBuilder,
    private ticketsService: TicketsService,
    private typesService: TypesService,
    private userService:UserService,
    private notificationService:NotificationService,
    private uploadService:UploadService,
    private modalService: NgbModal,
    private cardsService:CardsService

    
  ) {
    super(ticketsService,fb1);
    this.detailViewModal=TicketsDetailsComponent;
   }


  ngOnInit(): void {
    this.title="Biglietti";
    super.ngOnInit.apply(this, arguments); 
   
    this.cardsService.getCards().subscribe((items)=>{
      this.cards=items;
      for(let c of this.cards){
        if(c.default){
          this.id_card_default=c.id.toString();
        }
      }
    })
      
    this.typesService.getTypes("tickets").subscribe((items)=>{
      this.type_ticket=items;
    });

    this.userService.getUsers().subscribe((items)=>{
      this.users=items;
    });

    this.ticketsService.getCompanies().subscribe((items)=>{
      this.companies=items;
    });
    /*
    AppComponent.app.savingTicketsEvent=(()=>{
      this.updateList(this.id_address);
    });
    */
    if(this.mode!="embedded") //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
      this.updateList(this.id_address);


    this.afterGetItems=(()=>{
      this.ticketsService.getTotals(this.filter,this.orderingList,this.advancedFilter).subscribe((item)=>{
        if(item)
          this.total=item.total;
      });
    });
  }

  setFilters():void{
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("a.name");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("t.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    if(this.id_address==0){
      this.date_from=this.today;

      this.date_from.setDate(1);


      this.date_to.setDate(this.date_from.getDate()+30);
   
      this.filter_date.mode=FilterMode.date;
      this.filter_date.fields=[];
      this.filter_date.fields.push("t.created");
      this.filter_date.value=Helper.convertDateControl(this.date_from);
      this.filter_date.value2=Helper.convertDateControl(this.date_to);   
      this.filter.push(this.filter_date);
    }
    this.filter_id_type.mode=FilterMode.normal;
    this.filter_id_type.fields=[];
    this.filter_id_type.fields.push("t.id_type");
    this.filter_id_type.value="";
    this.filter.push(this.filter_id_type);

    this.filter_id_state.mode=FilterMode.normal;
    this.filter_id_state.fields=[];
    this.filter_id_state.fields.push("t.id_state");
    this.filter_id_state.value="";
    this.filter.push(this.filter_id_state);

    this.filter_source.mode=FilterMode.normal;
    this.filter_source.fields=[];
    this.filter_source.fields.push("t.source");
    this.filter_source.value="";
    this.filter.push(this.filter_source);

    this.filter_company.mode=FilterMode.normal;
    this.filter_company.fields=[];
    this.filter_company.fields.push("t.id_company");
    this.filter_company.value="";
    this.filter.push(this.filter_company);
    
    
    this.filter_id_address.mode=FilterMode.normal;
    this.filter_id_address.fields=[];
    this.filter_id_address.fields.push("t.id_address");
    this.filter_id_address.value="";
    this.filter.push(this.filter_id_address);
    
    this.filter_id_user.mode=FilterMode.normal;
    this.filter_id_user.fields=[];
    this.filter_id_user.fields.push("t.created_by");
    this.filter_id_user.value="";
    this.filter.push(this.filter_id_user);
    
    Globals.navigation.getStateFilters(this.constructor.name,this.filter);
    

    this.ordering.mode=OrderingMode.discendent;
    this.ordering.field="t.created";
    
    

    

    this.isFilterSet=true;
  }

  ngOnChanges():void{
    if(this.id_address>0) //avvia la ricerca quando il filtro è attivo
      this.updateList(this.id_address);
  }
  

  updateList(id_address:number=0):void{
    if(!this.isFilterSet)
      this.setFilters();
      
    
    if(id_address>0){
      this.filter_id_address.value=id_address.toString();
    }

    this.getItems();
  }

  bookTicket(item:Ticket){
    if(confirm("Confermi di voler ribloccare i posti?")){
      let id_state_tmp=item.id_state;
      item.id_state=9;
      this.ticketsService.bookAll(item.id,item.id_company.toString()).subscribe((result)=>{
        if(result[0].response=="OK"){
          item.id_state=1;
          Globals.message.showToaster("Posti bloccati correttamente","success");
        }else{
          Globals.message.showToaster(result[0].message,"danger");
          
          item.id_state=id_state_tmp;
        }
        this.getItems();
      });
    }
    
  }

  confirmTicket(item:Ticket){
    let id_state_tmp=item.id_state;
    item.id_state=9;
    this.ticketsService.confirmAll(item.id,item.id_company.toString()).subscribe((result)=>{
      try{
        if(result['success']){
          this.ticketsService.getItem(item.id).subscribe((newitem)=>{
            Globals.message.showToaster("Biglietto confermato","success");
            this.notify(newitem);
            this.getItems();
          });

        }else{

          if(result['warningcode']=="98"){
            Globals.message.showToaster("La prenotazione è scaduta. Il biglietto è stato rimesso nello stato di conferma","danger");
            item.id_state=1;
            item.blocked=false;
         
          }else{


            item.id_state=id_state_tmp;
            Globals.message.showToaster(result[0]['message'],"danger");
            this.getItems();
          }
        }
      }catch{
          Globals.message.showToaster("Errore durante la conferma del biglietto. Riprovare oppure contattare l'amministratore del sistema","danger");
          item.id_state=1;
      }

    });
  }

  downloadPDF(item:TicketDetail){
   

    if(item.file!=""){

      
      window.open(Globals.config.serverUrl+item.file,"_blank");
      
    }
  }

  notify(record:Ticket){

    this.notificationService.showPreviewNotification(record.addressItem['contacts'],record,"tickets",()=>{

    },record.id_address);

    
    
  }




  openConfirmModal(t:Ticket){
    this.selectedTicket=t;
    this.selectedTicket.method_payment="postepay";
    this.modalUploadRef=this.modalService.open(this.modalUpload,{size:'lg'});
    if(!this.selectedTicket.blocked){
      if(t.tickets.length==2){
        this.formUpload.get("pnr1").setValidators(Validators.required);
        this.formUpload.get("seat1").setValidators(Validators.required);
        this.formUpload.get("file1").setValidators(Validators.required);
      
        this.formUpload.get("pnr2").setValidators(Validators.required);
        this.formUpload.get("seat2").setValidators(Validators.required);
        this.formUpload.get("file2").setValidators(Validators.required);
      }
    }
  }
  closeModalUpload(){
    this.modalUploadRef.close("success");
  }


  


  onFileSelect1(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.formUpload.patchValue({
        fileSource1: file
      });
    }
  }

  onFileSelect2(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.formUpload.patchValue({
        fileSource2: file
      });
    }
  }



  onConfirm() {
    
    if(this.selectedTicket.method_payment=="stripe"){
      this.openPayMethod(this.selectedTicket);
    }else{
      this.processConfirm();
    }
    this.modalUploadRef.close("success");
  }

  processConfirm(){
    this.ticketsService.save(this.selectedTicket,(return_id)=>{
      this.confirmTicket(this.selectedTicket);
    });
    /*
    if(this.selectedTicket.blocked){
      
    }else{
      this.onUpload();
      
    }
    this.modalService.dismissAll("success");*/

    
  }

  onUpload(id=1,oncomplete=null){
    let item={} as Object;
      item['directory']="documents";
      item['fileToUpload']=this.formUpload.get('fileSource'+id.toString()).value;
    
      this.uploadService.uploadFile(item,"",
        (res) => {
          if(res['status']){

            
            this.selectedTicket.tickets[id-1].pnr=this.formUpload.get('pnr'+id.toString()).value;
            this.selectedTicket.tickets[id-1].seats=this.formUpload.get('seats'+id.toString()).value;
            this.selectedTicket.tickets[id-1].file=res['filename'];
            this.selectedTicket.tickets[id-1].id_state=2;

            if(this.selectedTicket.tickets.length==2){
              if(id==0)
                this.onUpload(2,()=>{
                  this.selectedTicket.id_state=2;
                  this.ticketsService.save(this.selectedTicket,(return_id)=>{
                    this.modalService.dismissAll("success");
                    this.notify(this.selectedTicket);
                  });
                });
            }else{
              this.selectedTicket.id_state=2;
                  this.ticketsService.save(this.selectedTicket,(return_id)=>{
                    this.modalService.dismissAll("success");
                    this.notify(this.selectedTicket);
                  });
            }

            

            
          }else{
            alert(res['message']);
          }

          this.modalService.dismissAll("success");
        }
      );
  }


  onLoadIframePay(myIframe){
    //verifica in che stadio è il frame
    if(myIframe.src!=""){
      this.onloadPayment=false;
    }
  
    if(myIframe.src.indexOf("notify.php")>0){
      this.modalService.dismissAll("success");
    }
   }

   openPayMethod(ticket:Ticket){
    this.modalPayRef=this.modalService.open(this.modalPay,{size:'lg'});
    this.selectedTicket=ticket;
   }

   closeModalPay(){
    this.modalPayRef.close("success");
   }

   wizard(){
     Globals.modal.showModal(SearchComponent,[{"name":"mode","value":"modal"}],()=>{
       this.updateList();
     },"md");
   }

   paymentComplete(result){
     if(result){
       this.processConfirm();
       this.closeModalPay();
       this.getItems();
     }
   }

   

    

   openAddress(id_address){
     Globals.modal.showModal(AddressDetailsComponent,[
       {"name":"mode","value":"modal"},
       {"name":"id","value":id_address}
      ],(instance)=>{
      if(instance!=null){
        this.getItems();
      }
     });
   }
   

   handleFileInput(files: FileList,id_ticketdetail) {
    Globals.setLoading(true);
    this.fileToUpload = files.item(0);


    this.uploadService.uploadFile(this.fileToUpload,"tickets",(result)=>{

      for(let i=0;i<this.selectedTicket.tickets.length;i++){
        if(this.selectedTicket.tickets[i].id==id_ticketdetail){
          this.selectedTicket.tickets[i].file=result;
        }
      }
    
      Globals.setLoading(false);
     
    },true);
  }

  setCard(event){
    this.cardsService.setDefault(event.target.value).subscribe((result)=>{
      Globals.message.showToaster("Carta di default impostata",ToastMode.SUCCESS);
    })
  }

  openCompanies(){
    Globals.modal.showModal(CompaniesComponent,[],(instance)=>{

    });
  }


  expired(record){
    //controlla se vi è un biglietto scaduto
    for(let ticket of record.tickets){
      if (Helper.convertString2Date(ticket.date)<this.today   && ticket.id_state!=2){
        return true;
      }
    }

    return false;
  }
}
