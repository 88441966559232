import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionsComponent } from './actions/actions.component';
import { FormsModule } from '@angular/forms';
import { SelectTablesModule } from '../common/tables/tables.module';
import { AlarmsComponent } from './alarms/alarms.component';
import { CustomfieldssettingsComponent } from './customfields-settings/customfields-settings.component';
import { ModulesComponent } from './modules/modules.component';
import { NotificationComponent } from './notification/notification.component';
import { TemplatesComponent } from './templates/templates.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { PaginationModule } from '../common/pagination/pagination.module';
import { WindowlistModule } from '../common/windowlist/windowlist.module';
import { UsersComponent } from './users/users.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { GatewayComponent } from './gateway/gateway.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { SystemComponent } from './system/system.component';
import { TemplateEmailComponent } from './template-email/template-email.component';
import { WindowModule } from '../common/window/window.module';
import { ToolbarmodelModule } from '../common/toolbarmodel/toolbarmodel.module';
//import { NgxTagsInputModule } from 'ngx-tags-input';
import { NgJsonEditorModule } from 'ang-jsoneditor' 
import { CustomfunctionsComponent } from './customfunctions/customfunctions.component';
import { CustomFunctionsDetailComponent } from './customfunctions/detail/detail.component';
import { TypesSettingsComponent } from './types/types.component';
import { BtnSelectFieldComponent } from './btn-select-field/btn-select-field.component';
import { Globals } from '../globals';
import { TaxesComponent } from './taxes/taxes.component';
import { TemplatesDetailComponent } from './templates/detail/detail.component';


@NgModule({
  declarations: [
    ActionsComponent,
    AlarmsComponent,
    CustomfieldssettingsComponent,
    ModulesComponent,
    NotificationComponent,
    TemplatesComponent,
    TemplatesDetailComponent,
    UsersComponent,
    GatewayComponent,
    SystemComponent,
    TemplateEmailComponent,
    CustomfunctionsComponent,
    CustomFunctionsDetailComponent,
    TypesSettingsComponent,
    TaxesComponent,
    BtnSelectFieldComponent
  ],
  exports:[ActionsComponent,
    AlarmsComponent,
    CustomfieldssettingsComponent,
    ModulesComponent,
    NotificationComponent,
    TemplatesComponent,
    TemplatesDetailComponent,
    UsersComponent,
    GatewayComponent,
    SystemComponent,
    TemplateEmailComponent,
    CustomfunctionsComponent,
    CustomFunctionsDetailComponent,
    TypesSettingsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SettingsRoutingModule,
    PaginationModule,
    WindowlistModule,
    WindowModule,
    ToolbarmodelModule,
    SelectTablesModule,
    CKEditorModule,
    NgxJsonViewerModule,
  //  NgxTagsInputModule,
    NgJsonEditorModule,
    WindowModule,
    ToolbarmodelModule
  ]
})
export class SettingsModule { 

constructor(){


    Globals.parameters.loadModulesEvent.subscribe((result)=>{
      Globals.parameters.createParam(
          "notifications",
          "sms_c1connector",
          "Abilita invio sms da C1 Connector",
          "boolean"
      );

      Globals.parameters.createParam(
        "notifications",
        "whatsapp_c1connector",
        "Abilita invio whatsapp da C1 Connector",
        "boolean"
    );
    });
  }
}
