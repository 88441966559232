<ul class="small-box list-group {{class}}">
    <ng-container *ngIf="editButton">
        <li class="list-group-item btn btn-outline-secondary btn-xs border mb-1" (click)="editButton=!editButton">Chiudi</li>
        <li class="list-group-item btn btn-outline-success btn-xs border" (click)="add()"><i class="fa fa-plus mr-2"></i>Aggiungi categoria</li>
    </ng-container>
    <li *ngIf="showAll && !editButton" class="list-group-item d-flex justify-content-between align-items-center" [class.active]="id==0" >
        <span (click)="OnSelect(0)" class="text-truncate pointer">Tutte le categorie</span>
        <span>
            <i *ngIf="id!=0" title="Azzera selezione" (click)="OnSelect(-1)" class="fa fa-times text-danger pointer"></i>
            <i *ngIf="showCountProduct" title="Personalizza categorie" (click)="editButton=!editButton" class="fa fa-cog pointer ml-2"></i>
        </span>
    </li>
    <ng-container *ngFor="let i of list" >
        <li class="list-group-item text-left px-0 text-truncate pointer multi" [class.active]="!editButton && checkSelected(i.id)" (click)="OnSelect(i.id)">
            <div class="pl-2" style="height: 1rem" [class.text-gray]="!editButton && i.count==0" [class.text-danger]="!editButton && i.count<0">
                <span class="ml-3" *ngIf="i.parent>0">&#10551;</span>
                <i *ngIf="i.icon" class="ml-2" class="{{i.icon}}"></i>
                <span>
                    <span class="ml-2" *ngIf="editButton">[{{i.id}}]</span>
                    <span class="ml-2">{{i.name}}</span>
                    <span *ngIf="checkSelected(i.id)" class="ml-2">({{i.count}})</span>
                </span>
                <button *ngIf="editButton" class="btn btn-xs text-danger float-right mr-1" (click)="delete(i)"  title="Elimina"><i class="fa fa-trash"></i></button>
                <button *ngIf="editButton" class="btn btn-xs float-right mr-1" (click)="edit(i)"  title="Modifica"><i class="fa fa-pencil-alt"></i></button>
            </div>
            <div class="treeviewlist_bar">
                <div *ngIf="!checkSelected(i.id) && !editButton && showCountProduct && i.count>0" class="alert-success float-right text-right" style="width: calc( {{i.count}}px / 1 )">
                    <small class="pr-1">{{i.count}}</small>
                </div>
            </div>
            <app-treecategories *ngIf="i.hasChildren" [list]="i.children"></app-treecategories>
        </li>
    </ng-container> 
    <div class="icon"><i class="fas fa-clipboard-list mr-5"></i></div>
</ul>

<ng-template #detailModal let-modal>
    <div class="modal-header bg-light">
        <b>Modifica categoria</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="modal.close()" >
              <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Nome</label>
            <input type="text" class="form-control form-control-sm" [(ngModel)]="recordSelected.name" />
        </div>
        <div class="form-group">
            <label>Icona</label>
            <div class="input-group input-group-sm">
                <div class="input-group-prepend input-group-prepend-sm">
                    <span class="input-group-text bg-white"><i class="{{recordSelected.icon}}"></i></span>
                </div>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="recordSelected.icon" />
                <div class="input-group-append input-group-append-sm">
                    <a class="btn btn-sm"  title="cerca" target="_blank" href="https://fontawesome.com"><i class="fas fa-search"></i></a>
                </div>
                
            </div>
        </div>
        <div class="form-group">
            <label>Categoria collegata</label>
            <select class="form-control form-control-sm" [(ngModel)]="recordSelected.parent">
                <option value="0">Nessuna</option>
                <option *ngFor="let t of categories" [ngValue]="t.id"><span *ngFor="let i of [].constructor(t.level-1)"> - </span>{{t.name}}</option>
                    
            </select>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="save();modal.close()">Ok</button>
        <button class="btn btn-secondary" (click)="modal.close()">Annulla</button>
    </div>
</ng-template>