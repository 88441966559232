import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode, OrderingMode, User } from 'projects/core/src/include/structures';
import { InstallationLog } from '../../../../db/installation';
import { InstallationslogsService } from '../../../../services/installationslogs.service';
import { SerialslogsDetailComponent } from './serialslogs-detail/serialslogs-detail.component';

@Component({
  selector: 'app-serialslogs',
  templateUrl: './serialslogs.component.html',
  styleUrls: ['./serialslogs.component.css']
})
export class SerialslogsComponent extends ModelList<InstallationLog> implements OnInit {

  @Input()
  sn:string="";

  filter_sn:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_date:Filter=new Filter();  

  isFilterSet=false;

  total_refueling=0;
  total_price=0;
  avg_refueling=0;
  avg_price=0;
  total_km=0;


  constructor(
    private fb1: UntypedFormBuilder,
    private installationsLogsService: InstallationslogsService,
  ) { 
    super(installationsLogsService,fb1);
    this.detailViewModal=SerialslogsDetailComponent;
  }

  ngOnInit(): void {
    this.title='Log prodotto';
    super.ngOnInit.apply(this, arguments); 

    this.afterGetItems=()=>{
      this.installationsLogsService.getTotals(this.filter,this.orderingList,this.advancedFilter).subscribe((result)=>{
        this.total_price=result['total_price'];
        this.total_refueling=result['total_refueling'];
        this.avg_price=result['avg_price'];
        this.avg_refueling=result['avg_refueling'];
        this.total_km=result['total_km'];
        
      });
    }
  }

  ngOnChanges():void{
    if(this.sn!="" && this.sn!=undefined) //avvia la ricerca quando il filtro è attivo
      this.updateList();
  }

  setFilters():void{
    this.filter_date.mode=FilterMode.date;
    this.filter_date.fields=[];
    this.filter_date.fields.push("i.date");
    this.filter.push(this.filter_date);

    this.filter_sn.mode=FilterMode.like;
    this.filter_sn.fields=[];
    this.filter_sn.fields.push("i.sn");
    this.filter_sn.value=this.sn;
    this.filter.push(this.filter_sn);

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("i.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.ordering.field="i.date";
    this.ordering.mode=OrderingMode.discendent;
  }

  updateList():void{
    if(!this.isFilterSet)
      this.setFilters();

      this.getItems();
  }

}
