import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlarmComponent } from './alarm.component';
import { FormsModule } from '@angular/forms';
import { InitModule } from '../init/init.module';
import { ReferenceModule } from '../reference/reference.module';



@NgModule({
  declarations: [
    AlarmComponent
  ],
  exports:[
    AlarmComponent
  ],
  imports: [
    InitModule,
    ReferenceModule,
    CommonModule,
    FormsModule,
    
  ]
})
export class AlarmModule { }
