import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WindowlistbuttonsComponent } from './windowlistbuttons.component';



@NgModule({
  declarations: [
    WindowlistbuttonsComponent
  ],
  exports:[
    WindowlistbuttonsComponent
  ],
  imports: [
    CommonModule
  ]
})
export class WindowlistbuttonsModule { }
