import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilesRoutingModule } from './files-routing.module';
import { FilesComponent } from './files.component';
import { UploadfileModule } from '../uploadfile/uploadfile.module';


@NgModule({
  declarations: [
    FilesComponent
  ],
  exports:[
    FilesComponent
  ],
  imports: [
    CommonModule,
    FilesRoutingModule,
    UploadfileModule
  ]
})
export class FilesModule { }
