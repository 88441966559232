<section class="container">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <canvas baseChart
                                
                                [legend]="false"
                                [datasets]="chartData"
                                [labels]="chartLabels"
                                [options]="chartOptions"
                        >
                    </canvas>
                    <table class="table">
                        <thead>
                            <th>Data</th>
                            <th class="text-center"><i class="fa fa-arrow-circle-up text-success mr-2"></i>Carico</th>
                            <th class="text-center"><i class="fa fa-arrow-circle-down text-danger mr-2"></i>Scarico</th>
                            <th class="text-center">Giacenza</th>
                        </thead>
                        <tr *ngFor="let r of list">
                            <td>
                                <span (click)="openStock(r.date)">{{r.date | date:"dd/M/Y"}}</span>
                            </td>
                            <td class="text-center">{{r.load}}</td>
                            <td class="text-center">{{r.unload}}</td>
                            <td class="text-center" [class.text-success]="r.stock>0" [class.text-danger]="r.stock<=0"><strong>{{r.stock}}</strong></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>