import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode } from 'projects/core/src/include/structures';
import { Documents } from '../../../db/documents';
import { InventoriesDetails } from '../../../db/inventories';
import { ProductCategories } from '../../../db/productcategories';
import { InventoriesDetailsService } from '../../../services/inventoriesdetails.service';
import { ProductcategoriesService } from '../../../services/productcategories.service';
import { LoginventoryComponent } from '../../inventories/loginventory/loginventory.component';
import { ProductsDetailsComponent } from '../../products/products-details/products-details.component';

@Component({
  selector: 'app-documentinventories',
  templateUrl: './documentinventories.component.html',
  styleUrls: ['./documentinventories.component.css']
})
export class DocumentinventoriesComponent extends ModelList<InventoriesDetails> implements OnInit {

  @Input()
  ids_documents:any=[];
  filter_table:Filter=new Filter();
  filter_id_table:Filter=new Filter();
  filter_date:Filter=new Filter();
  filter_id_category:Filter=new Filter();
  categories:ProductCategories[]=[];

  isFilterSet:boolean=false;


  constructor( private fb1: UntypedFormBuilder,
    private invoicesService: InventoriesDetailsService,
    private productcategoriesService:ProductcategoriesService,

    
    )
    {
      super(invoicesService,fb1);
    }

  ngOnInit(): void {
    this.title = 'Documenti';
    super.ngOnInit.apply(this, arguments); 
    this.paging_count=999999999;

    
    this.productcategoriesService.getCategories().subscribe((items)=>{
      this.categories=items;
    });
    /*
    if(this.ids_documents.length>0){
      this.getItems();
    }*/

  }

  setFilters(){
    //imposta i filtri
    this.filter_table.mode=FilterMode.normal;
    this.filter_table.fields=[];
    this.filter_table.fields.push("i.table");
    this.filter_table.value="documents";
    this.filter.push(this.filter_table);

    this.filter_id_table.mode=FilterMode.contain;
    this.filter_id_table.fields=[];
    this.filter_id_table.fields.push("i.id_table");
    this.filter_id_table.value=this.ids_documents.join(",");
    this.filter.push(this.filter_id_table);

    this.filter_date.mode=FilterMode.date;
    this.filter_date.fields=[];
    this.filter_date.fields.push("i.date");
    this.filter.push(this.filter_date);

    this.filter_id_category.mode=FilterMode.contain;
    this.filter_id_category.fields=[];
    this.filter_id_category.fields.push("pc.id");
    this.filter_id_category.value="";
    this.filter.push(this.filter_id_category);
    this.isFilterSet=true;
  }
  
  ngOnChanges(){
    if(this.ids_documents.length>0){
      this.filter_id_table.value=this.ids_documents.join(",");
      this.updateList();
    }
  }

  updateList():void{
    if(!this.isFilterSet)
        this.setFilters();
  
    
   
  
    this.getItems();
  }



  openProduct(id_product){
    Globals.modal.showModal(ProductsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":id_product},
      
    ],()=>{

    });
  }

  openHistory(id_product){
    Globals.modal.showModal(LoginventoryComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id_product","value":id_product},
    ],()=>{
      
    })

  }

}
