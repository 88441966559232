import { Component, OnInit } from '@angular/core';
import { Helper } from 'projects/core/src/helper';
import { Modal } from 'projects/core/src/include/modal';
import { CashFlow } from '../../../db/cashflow';
@Component({
  selector: 'app-confirmcashflow',
  templateUrl: './confirmcashflow.component.html',
  styleUrls: ['./confirmcashflow.component.sass']
})
export class ConfirmcashflowComponent extends Modal implements OnInit  {

  constructor() { 
    super();
  }

  amount:number;
  paid:number;
  id_address:number;
  description:string;
  id_user:number;
  record:CashFlow;
  id_table:number;
  table:string;

  ngOnInit(): void {
    this.record={} as CashFlow;
    this.record.date=Helper.convertDateControl();
    this.record.amount=this.amount;
    this.record.paid=this.paid;
    this.record.description=this.description;
    this.record.id_user=this.id_user;
    this.record.id_address=this.id_address;
    this.record.method_payment="Contanti";
    this.record.id_table=this.id_table;
    this.record.table=this.table;
  }

  ngOnChanges(){
    
  }

}
