<ng-container *ngIf="!readonly">
    <div class="{{class}}">
        <div class="m-auto text-center p-2 pointer " style="width:90%; height:200px; color:#ccc; border: 1px dashed #ccc; border-radius:10px">
            <i class="fas fa-2x fa-bullseye mt-5"></i><br>
            <i>Trascina e rilascia <b>qui</b>.</i><br>
            <a class="btn btn-secondary btn-sm mt-4" (click)="fileToUpload.click()"><i class="fas fa-upload mr-2"></i>Carica file</a>
        </div>
        <input  type="file" style="display:block;opacity:0;width:90%;height:200px;margin:-200px auto 0;" #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files)">
    </div>
</ng-container>
<ng-container *ngIf="readonly">
    <div class="alert alert-info">Modalità di sola lettura</div>
</ng-container>