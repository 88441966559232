<div>
    <div class="input-group input-group-sm">
    
        <input  type="text"
                class="{{class}}"
                [(ngModel)]="value"
                (keyup)="update()"
                (focus)="onFocusEventAction()"  
                (blur)="onBlurEventAction()"/>
        
        <div class="input-group-append">
            <button class="btn btn-sm btn-default" (click)="toogleDropDown()">
                <i class="fa fa-angle-down"></i>
            </button>
        </div>
    </div>


    <div class="data-container" *ngIf="showDropDown">
        <table style="width: 100%;">
            <tr *ngFor="let data of dummyDataList; 
                        let i = index" class="data-list"
                (click)="updateTextBox(data)"
                [ngClass]="{highlight:checkHighlight(i)}">
                
                <td *ngFor="let f of columnName.split(';')">{{data[f]}}</td>  
            </tr>
        </table>
    </div>
   </div>