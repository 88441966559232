import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent  implements OnInit {
  tab=1;
  @Input()
  mode:string;

  @Input()
  modalWindow:any={};

  @Input()
  recordSelected:any={};
  
  ngOnInit(): void {
    
  }

  

}
