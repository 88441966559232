import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-template-search',
  templateUrl: './template-search.component.html',
  styleUrls: ['./template-search.component.css']
})
export class TemplateSearchComponent implements OnInit {

  constructor() { }

  templates=[];
  @Input()
  modelList:any;

  ngOnInit(): void {
    this.templates=this.modelList.loadTemplatesSearch();
  }

  close(){
    this['modalWindow'].close("cancel");
  }
  load(template){
    this.modelList.loadTemplateSearch(template);
    this.close();
    
  }
  delete(t){
    if(confirm("Confermi di voler eliminare questo criterio di ricerca?"))
      this.templates=this.modelList.deleteTemplateSearch(t,this.templates);

  }
  save(){
    let name=prompt("Inserisci un titolo del criterio di ricerca");
    if(name!=""){
      this.modelList.saveTemplateSearch(name);
      this.templates=this.modelList.loadTemplatesSearch();
    }
  }

}
