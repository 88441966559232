import { NgModule } from '@angular/core';
import { ContractsComponent } from './contracts.component';
import { ContractsDetailComponent } from './contracts-detail/contracts-detail.component';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PreviewnotificationModule } from '../../modules/previewnotification/previewnotification.module';
import { WindowlistModule } from 'projects/core/src/common/windowlist/windowlist.module';
import { PaginationModule } from 'projects/core/src/common/pagination/pagination.module';
import { ContractsRoutingModule} from './contracts-routing.module';
import { CitiesModule } from '../../modules/cities/cities.module';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
import { TypeModule } from '../../modules/type/type.module';
import { WindowModule } from 'projects/core/src/common/window/window.module';
import { CustomfieldsModule } from 'projects/core/src/common/customfields/customfields.module';
import { DeadlinesModule } from '../deadlines/deadlines.module';
import { InstallmentsComponent } from './installments/installments.component';
import { FilesModule } from '../../modules/files/files.module';
import { FormsValuesModule } from '../forms/forms.module';
import { Globals } from 'projects/core/src/globals';
import { FilterdateModule } from '../../modules/filterdate/filterdate.module';
import { DocumentsModule } from '../documents/documents.module';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        PreviewnotificationModule,
        CustomfieldsModule,
        WindowModule,
        WindowlistModule,
        ContractsRoutingModule,
        PaginationModule,
        CitiesModule,
        ToolbarmodelModule,
        TypeModule,
        DeadlinesModule,
        FilesModule,
        FilterdateModule,
        FormsValuesModule,
        DocumentsModule
      

    ],
   exports: [ContractsComponent,ContractsDetailComponent],
   declarations: [ContractsComponent,ContractsDetailComponent, InstallmentsComponent],
   providers: [],
})
export class ContractsModule{


    constructor(){
        Globals.parameters.loadModulesEvent.subscribe((result)=>{
            Globals.parameters.createParam(
                "contracts",
                "id_type_deadline",
                "ID Tipologia scadenza",
                "type"
              );

        });
    }
 }