import { Component, Input, OnInit, Output,EventEmitter, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { Extension } from 'projects/core/src/include/parameters';
import { DocumentMode } from '../../db/documents';
import { InventoriesDetails, InventoryMode } from '../../db/inventories';
import { Product } from '../../db/product';
import { InventoriesDetailsComponent } from '../../views/inventories/inventories-details/inventories-details.component';
import { LoadserialsComponent } from '../../views/inventories/loadserials/loadserials.component';
import { ProductsDetailsComponent } from '../../views/products/products-details/products-details.component';
import { MoveinventoryComponent } from '../moveinventory/moveinventory.component';
import { SearchproductComponent } from '../searchproduct/searchproduct.component';
import { ProductsComponent } from '../../views/products/products.component';
import { LoginventoryComponent } from '../../views/inventories/loginventory/loginventory.component';
import { TaxesService } from '../../services/taxes.service';
import { Toast } from 'ngx-toastr';
import { ToastMode } from 'projects/core/src/include/structures';
import { Address } from '../../db/address';

@Component({
  selector: 'app-goods',
  templateUrl: './goods.component.html',
  styleUrls: ['./goods.component.css']
})
export class GoodsComponent implements OnInit,OnChanges {
  @ViewChild(SearchproductComponent)
  searchproductComponent:SearchproductComponent;

  @Input()
  list:InventoriesDetails[]=[];

  @Output()
  listUpdate:EventEmitter<any>=new EventEmitter();

  @Input()
  locked:boolean=false;

  @Input()
  canAdd:boolean=true;

  @Input()
  canNewProduct:boolean=false;
  
  @Input()
  checkSN:boolean=true;

  @Input()
  showNotes:boolean=true;

  @Input()
  showSelect:boolean=false;

  @Input()
  id_address:number=0;

  @Input()
  id_document:number=0;

  @Input()
  canEdit:boolean=true;

  @Input()
  type:number=DocumentMode.sell;

  @Input()
  showPrice:boolean=true;
  
  @Input()
  autoSelect:boolean=false; //permette di selezionare un record in automatico se è il singolo della lista

  @Input()
  showOpenDocuments:boolean=false;

  @Input()
  showQuantity:boolean=true;

  @Input()
  descriptionReadonly:boolean=false;
  
  @Input()
  viewmode=1; // 1 = list ; 2 = table ; 3 = slim ;

  @Input()
  canExploreCatalog:boolean=true;
  @Output()
  onOpenDocuments:EventEmitter<any>=new EventEmitter();

  @Output()
  onSelected:EventEmitter<any[]>=new EventEmitter();

  @Input()
  address:Address=null;

  selected:any[]=[];
  net_total:number=0;
  tax_total:number=0;
  discount_total:number=0;
  total:number=0;
  total_paid:number=0;
  quantity:number=1;
  id_inventory:number=0; //inventario dell'utente corrente

  edit_row_all:boolean=false;

  module:Extension; 

  taxes=[];
  constructor(   
    private taxesService:TaxesService
    ) {
    this.module=Globals.parameters.get("salesbackend");
    this.id_inventory= Globals.user['shop']?Globals.user['shop']['id_inventory']:Globals.user.getParam("id_inventory",0);
   }

  ngOnInit(): void {
    this.taxesService.getTaxes().subscribe((items)=>{
      this.taxes=items;
    })

    if(!Globals.parameters.isModuleEnabled("products")){
      this.locked=true;
    }

  }

  ngOnChanges():void{
    let row=0;
    
    for(let i of this.list){
      i['row']=row;
      i['ordering']=row;
      let p:Product=new Product();
      Object.assign(p,i.product);
      i.product=p;

      this.verifyAvaible(i.product,i.quantity,i);
      row++;

      //verifica l'IVA
      i['taxItem']=this.getTax(i['id_tax']);
      
    }
  }

  add(product:Product){

    

    //verifica se il prodotto deve avere un seriale
    if(product.has_serials && this.checkSN){
      if(product.sn=="" || product.sn==undefined){
        Globals.modal.showModal(LoadserialsComponent,[{"name":"type","value":this.type==DocumentMode.buy?InventoryMode.load:InventoryMode.unload},{"name":"quantity","value":this.quantity},{"name":"id_product","value":product.id},{"name":"serials","value":product.serials}],(instance)=>{
          if(instance!=null){
            for(let s of instance['serials']){
              if(s.selected==true){
                product.sn=s.sn;
                this._addProduct(product);
              }
            }
            //se il prodotto non ha disponibilità puoi inserirlo anche senza seriali
            if(instance['serials'].length==0){
              if(product.stock<=0){
                this._addProduct(product);
              }
            }            
           

            
          }
        });
        return;
      }
    }




    this._addProduct(product);
    
  }

  _addProduct(product:Product,isGroupProducts=null){


    let groupProducts=this.module?this.module.getParam("groupProducts",true):false;

    if(isGroupProducts!=null){ //forza il raggruppamento
      groupProducts=isGroupProducts;
    } 

    //verifica se il prodotto è stato già aggiunto
    if(groupProducts){
      for(let i=0;i<this.list.length;i++){
        if(this.list[i].id_product==product.id){
          //se ha un numero seriale, verifica che non sia lo stesso
          if(product.has_serials && this.checkSN){
            if(this.list[i].sn==product.sn){
              return;
            }
          }else{
            this.list[i]['quantity']=this.list[i]['quantity']+this.quantity;
            //verifica se vi è disponibilità in questo magazzino
            if(this.verifyAvaible(product,this.list[i]['quantity'],this.list[i])){
              this.updateList();
              this.calculateTotal();
              return;
            }else{
              this.list[i]['no_avaible']=true;
              this.updateList();
              return;
            }
          }
        }
      }
    }

    let d:InventoriesDetails={} as InventoriesDetails;
    d.id=0;
    d.product=product;
    d.sn=product.sn;
    d.id_product=product.id;
    d.code=product.codeinternal;
    if(product.has_serials && this.checkSN)
      d.quantity=1;
    else{
      //verifica se è stato passato un barcode 
      d.quantity=product['quantity']?product['quantity']:this.quantity;
   
    }
    if(this.type==DocumentMode.buy)
      d.netprice=product.netcost;
   
    else
      d.netprice=product.price;
    //d.netprice=product.getPrice(d.quantity,this.type==DocumentMode.buy?InventoryMode.load:InventoryMode.unload,this.address);
    
    
    d.tax=this.type==DocumentMode.buy?product.taxcost:product.tax;
    
    if(d.tax==null || d.tax==undefined){

      d.tax=this.module?this.module.getParam("tax_default",22):22;
    }
    this.setTax(d);

    d.type=this.type==DocumentMode.buy?InventoryMode.load:InventoryMode.unload;
    d.unit=product.unit;
    if(product && product.id>0){
      d.description=(product.brand?product.brand+" ":"")+product.name;DocumentMode
    }
    d.gross=d.netprice*(1+d.tax/100);
    d.discount1=this.type==DocumentMode.buy?(product.discountcost1?product.discountcost1:0):0;
    d.discount2=this.type==DocumentMode.buy?(product.discountcost2?product.discountcost2:0):0;
    d.discount3=this.type==DocumentMode.buy?(product.discountcost3?product.discountcost3:0):0;
    d.id_inventory=this.id_inventory;

    d.date=Helper.convertDateControl();
    let t=new Date();
    d.time=t.getHours().toString()+":"+t.getMinutes().toString();
    d.id_user=Globals.user.id;

    if(!this.verifyAvaible(product,this.quantity,d)){
      d['no_avaible']=true;

    }
    this.list.push(d);
    this.updateList();
    this.calculateTotal();
  }

  emptyRow(){
    let emptyProduct:Product=new Product();
    emptyProduct.id=0;
    this._addProduct(emptyProduct,false);
  }
  verifyAvaible(product:Product,quantity:number,record:InventoriesDetails){
    if(!record)
      return false;
    record['is_quantizable']=true;
    record['no_avaible']=false;
    record['not_in_stock']=false;
    record['not_in_this_stock']=false;

    //verifica se il prodotto ha le quantità necessarie in tutti i magazzini
    if(product.stock==0 && product.stockable){
      record['is_quantizable']=false;
      record['no_avaible']=true;

      record['not_in_stock']=true;
      return false;
    }

    //verifica che il prodotto abbia le quantità necessarie in questo magazzino
    if(product.stocks && product.stockable){
      for(let s of product.stocks){
        if(s.id_inventory==this.id_inventory){
          if(s.stock<quantity){
            record['no_avaible']=true;

            record['not_in_this_stock']=true;
            record['max_quantity']=record.product.getStockInventory(this.id_inventory);
            if(record.product.stock>record['max_quantity']){
              record['in_other_stock']=true;
            }
            return false;
          }
        }
      }
    }

    return true;
  }

  remove(record){
    for(let i=0;i<this.list.length;i++){
      if(this.list[i].product.id==record.product.id){
        this.list.splice(i,1);
        this.calculateTotal();
        this.updateList();
        return;
      }

    }
  }

  calculateTotal(){




    this.total=0;
    this.net_total=0;
    this.tax_total=0;
    this.discount_total=0;
    for(let p of this.list){

      


      //p.netprice=p.product.getPrice(p.quantity,2);
      this.net_total=this.net_total+(p.netprice*p.quantity)*(1-Helper.getEquivalentDiscount(p.discount1,p.discount2,p.discount3)/100);
      this.discount_total=this.discount_total+(p.netprice*p.quantity)*(Helper.getEquivalentDiscount(p.discount1,p.discount2,p.discount3)/100);
      this.tax_total=this.tax_total+(p.netprice*p.quantity*(p.tax/100));
      this.total=this.total+(p.netprice*p.quantity)*(1-Helper.getEquivalentDiscount(p.discount1,p.discount2,p.discount3)/100);
    }

    let item_not_avaible=this.list.filter(x=>x['no_avaible']==true);
    let total_not_avaible=0;
    for(let i of item_not_avaible){
      total_not_avaible=total_not_avaible+(i.netprice*i.quantity)* (1-Helper.getEquivalentDiscount(i.discount1,i.discount2,i.discount3))*(1+i.tax/100);
    }

    
    this.total_paid=this.total-total_not_avaible;

    if(this.viewmode==2){
      this.updateList();
    }
  }


  calculateTotalRow(d,withTax=false){
    return d.netprice*d.quantity*(1-Helper.getEquivalentDiscount(d.discount1,d.discount2,d.discount3)/100)*(withTax==true?(1+d.tax/100):1);
    
  }

  editDetail(detail){
    detail['editMode']=true;

  }


  editGrossPrice(p,value){
    p.netprice=value.target.value/((1+p.tax/100)*(1-Helper.getEquivalentDiscount(p.discount1,p.discount2,p.discount3)/100));

  }

  confirmEditDetail(detail){
    detail['editMode']=false;

    //verifica che i prezzi non hanno uno sconto elevato
    if(this.type==DocumentMode.sell){
      for(let p of this.list)
      //effettua una verifica sugli sconti applicati
      if(p.netprice*(1-Helper.getEquivalentDiscount(p.discount1,p.discount2,p.discount3)/100)<p.product.getPrice(1)*(1-p.product.maxdiscount/100)){
        p.netprice=p.product.getPrice(1);
        p.discount1=0;
        p.discount2=0;
        p.discount3=0;
      }
    
      this.verifyAvaible(detail.product,detail.quantity,detail);
    }
    this.updateList();


  }

  deleteDetail(detail){
   
    for(let i=0;i<this.list.length;i++){
      //if(this.list[i].product.id==detail.product.id && this.list[i]['row']==detail['row']){
      if(this.list[i]==detail) {
        this.list.splice(i,1);
        this.updateList();

        return;
      }
    }
   
  }

  cloneDetail(detail){
    let new_detail:any={};
    Object.assign(new_detail,detail);
    new_detail.id=0;
    this.list.push(new_detail);
  }

  updateList(){
    if(this.viewmode==1)
      this.searchproductComponent.focusSearch();
    this.listUpdate.emit(this.list);
  }

  resetQuantity(r:InventoriesDetails){
    r.quantity=r.product.getStockInventory(this.id_inventory);
    r['no_avaible']=false;
    r['not_in_this_stock']=false;
    this.calculateTotal();
  }

  openDocuments(){
    this.onOpenDocuments.emit();
  }

  openProductDetail(product){
    Globals.modal.showModal(ProductsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":product.id}
    ],()=>{

    });
  }

  move(record){

    Globals.modal.showModal(MoveinventoryComponent,[{"name":"id_product","value":record.product.id},{"name":"createDDT","value":true}],(instance)=>{
      
    });

    
  }

  OnSelect(event){

    let checked=event.target.checked;
   
    let t=this.selected.filter(x=>x==event.target.value);

    if(checked){
      if(t.length==0)
        this.selected.push(event.target.value);
    }else{
      this.selected=this.selected.filter(x=>x!=event.target.value);
    }

    this.onSelected.emit(this.selected);

  }

  checkPrice(r){
    if(this.type==DocumentMode.sell){
      let pnetcost=r.product.netcost*(1-r.product.discountcost1/100)*(1-r.product.discountcost2/100)*(1-r.product.discountcost3/100);
      if(r.netprice<pnetcost){
        r.netprice=pnetcost;
        Globals.message.showToaster("Prezzo inserito inferiore al costo di acquisto "+pnetcost+" Euro",ToastMode.DANGER)
      }

      if(r.netprice<r.product.getPrice(1)*(1-r.product.maxdiscount/100)){
        r.netprice=r.product.getPrice(1)*(1-r.product.maxdiscount/100);
      }
    }
  }
  
  checkDiscount(r){

    // correggi la virgola con il punto
    r.discount1 = r.discount1.replace(",",".");
    r.discount2 = r.discount2.replace(",",".");
    r.discount3 = r.discount3.replace(",",".");

    if(this.type==DocumentMode.sell){
      //verifica se è  impostato lo sconto massimo
      if(r.discount1>r.product.maxdiscount){
        r.discount1=r.product.maxdiscount;
      }
    }
  }


  calculateNetDetail(event,r){

    let gross=event.target.value.replace(/,/g,".");
    gross=gross.replace(/[^0-9\.\-]/g, '');
    r.netprice=(gross/r.quantity)/((1+r.tax/100)*(1-Helper.getEquivalentDiscount(r.discount1,r.discount2,r.discount3)/100));
    if (!r.netprice) {
      r.netprice=0;
      Globals.message.showToaster("Errore nel calcolo. Riprovare",ToastMode.WARNING)
    } else {
      this.calculateTotal();
    }

  }


  newProduct(d){

    Globals.modal.showModal(ProductsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"name","value":d.description},
      {"name":"codeexternal","value":d.code},
      {"name":"netcost","value":d.netprice},
      {"name":"tax","value":d.tax},

    ],(instance)=>{
      if(instance!=null){
        d.product=instance['record'];
        d.id_product=d.product.id;
      }
    });
  }

  assignProduct(d){
    Globals.modal.showModal(ProductsComponent,[
      {"name":"mode","value":"modal"},
      
    ],(instance)=>{
      if(instance!=null){
        let p=instance['recordSelected'];
        d.product=p;
        d.id_product=d.product.id;
       
      }
    });
  
  }

  openInventoryDetail(id,record,type){
    Globals.modal.showModal(InventoriesDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":id}
    ],(instance)=>{
      if(instance!=null){

        //verifica se c'è stato un cambio di tipologia di movimento (carico / scarico)

        if(type==instance['record']['type']){

        }else{
          record['id_inventory_detail_unload']=0;
          record['id_inventory_detail_load']=0;

          if(instance['record']['type']==1)
            record['id_inventory_detail_load']=instance['record']['id'];
          else
            record['id_inventory_detail_unload']=instance['record']['id'];
          

        }

      }
    });
  }

  openStock(record){
    Globals.modal.showModal(LoginventoryComponent,[{"name":"id_product","value":record.id}],()=>{})
   
  }

  setTax(record,tax=null){

    if(tax==null){
      for(let t of this.taxes){
        if(t.tax==record['tax']){
          record['id_tax']=t.id;
          record['taxItem']=t;
          return;
        }
      }
    }else{
      record['tax']=tax['tax'];
      record['id_tax']=tax['id'];
    }

    
  }

  getTax(id_tax){
    for(let t of this.taxes){
      if(t.id==id_tax){
        return t;
      }
    }
  }


  array_move( old_index, new_index) {
    var arr=this.list;
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    this.list=arr;
    let o=0;
    for(let r of this.list){
      r['ordering']=o;
      o++;
    }


};

  
  calculateNetCost(d){
    return d.product.netcost*(1-Helper.getEquivalentDiscount(d.product.discountcost1,d.product.discountcost2,d.product.discountcost3)/100);
  }


  verifyChars(d){

    // sostituisce o elimina caratteri non ammessi nella fattura elettronica
    if(d.description){
      d.description = d.description.replace(/–/g,"-");
      d.description = d.description.replace(/’/g,"'");
      d.description = d.description.replace(/\t/g," ");
      d.description = d.description.replace(/[^\w\sáéíóúàèìòù!"£$%&/(){}=?^'°+@#,.;:-]/gi,"");
    }
    if(d.note){
      d.note = d.note.replace(/–/g,"-");
      d.note = d.note.replace(/’/g,"'");
      d.note = d.note.replace(/\t/g," ");
      d.note = d.note.replace(/[^\w\sáéíóúàèìòù!"£$%&/(){}=?^'°+@#,.;:-]/gi,"");
    }
  }


  checkSpecialProduct(row){
    if(row.description && row.description.substring(0,2)=="##"){
      return true;
    }

    return false;
  }


  expandAll(v) {
    for(let i of this.list){
      i['edit_row']=v;      
    }
    this.edit_row_all = !this.edit_row_all;
  }

 

  
}
