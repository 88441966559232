import { NgModule } from '@angular/core';
import { SelectTablesComponent } from './tables.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


@NgModule({
    imports: [CommonModule,FormsModule],
   exports: [SelectTablesComponent],
   declarations: [SelectTablesComponent],
   providers: [],
})
export class SelectTablesModule{ }