import { Injectable } from '@angular/core';
import {CustomFieldValues} from '../include/structures';
import {WSService} from '../include/service'

@Injectable({
  providedIn: 'root'
})
export class CustomfieldvaluesService extends WSService<CustomFieldValues> {

  constructor() {super("customfieldvalues") }
}
