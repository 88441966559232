import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'projects/core/src/globals';
import { Model } from 'projects/core/src/include/model';
import { UserService } from 'projects/core/src/lib/user.service';
import { DocumentDetail } from '../../../db/documents';
import { Project } from '../../../db/project';
import { AddressesService } from '../../../services/addresses.service';
import { DocumentsService } from '../../../services/documents.service';
import { ProjectsService } from '../../../services/projects.service';
import { AddressDetailsComponent } from '../../address/address-details/address-details.component';
import { AddressComponent } from '../../address/address.component';
import { DocumentlistComponent } from '../../documents/documentlist/documentlist.component';
import { DocumentsDetailsComponent } from '../../documents/documents-details/documents-details.component';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.css']
})
export class ProjectDetailComponent extends Model<Project> implements OnInit {

  
  @ViewChild("documentlist")
  documentList:DocumentlistComponent;


  @ViewChild("costlist")
  costList:DocumentlistComponent;

  modulename="project";
  @Input()
  id_address:number=0;

  shops=[];
  users=[];
  total:any;

  field_required=["id_address","date"];
  
  constructor(
    private route1: ActivatedRoute,
    private projectsService: ProjectsService,
    private userService:UserService,
    public addressesService:AddressesService,
    private documentsService:DocumentsService,

  ) { 
    super(route1,projectsService); 

    
  }

  ngOnInit(): void {
    super.ngOnInit.apply(this, arguments); 


    
    if(this.mode!="modal")
      this.route1.params.subscribe((params) =>{

      this.id = params.id;
      if(params.id_address>0)
        this.id_address=params.id_address;
      
      
      } );

      this.record=new Project();

      const default_address=Globals.parameters.get("general").getParam("default_address");

      

      this.userService.getUsers().subscribe((items)=>{
        this.users=items;
      });

      

      this.afterGetItem=()=>{
        
      }

     

      this.getItem();

    }


  selectAddress(){
    Globals.modal.showModal(AddressComponent,[
      {"name":"mode","value":"modal"},
      {"name":"title","value":"Seleziona un'anagrafica"}
      ],(instance)=>{
      let r=instance['recordSelected'];
      this.OnSelectAddress(r);
    });
  }


  OnSelectAddress(r){
    if(r){
      this.record.addressItem=r;
      this.record.id_address=r.id;
    }
  }

  OnViewAddress(r){
    Globals.modal.showModal(AddressDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":r.id}
    ],(instance)=>{
      if(instance!=null)
        this.OnSelectAddress(instance['record']);
    });
  }

  

  


  addDocument(type){

    //crea il dettaglio fattura
    let details:DocumentDetail[]=[];
   


    Globals.modal.showModal(DocumentsDetailsComponent,[
      {"name":"typedocument","value":1},
      {"name":"mode","value":"modal"},
      {"name":"id_type","value":type},
      {"name":"id_address","value":this.record.id_address},
      {"name":"description","value":"Progetto"},
      {"name":"details","value":details},
      {"name":"id_table","value":this.record.id},
      {"name":"table","value":"project"},
      {"name":"autoUnload","value":false},
      
    ],()=>{
      if(type==1)
        this.documentList.updateList();
      else
        this.costList.updateList();
    });
  }
  

  linkDocument(type){

    Globals.modal.showModal(DocumentlistComponent,[{"name":"mode","value":"modal"},{"name":"type","value":type}],(instance)=>{
      if(instance!=null){
        let d=instance['recordSelected'];
        // if(confirm("Attribuire il documento scelto a questo record?")){
          d.id_table=this.record.id;
          d.table="project";
          this.documentsService.save(d,()=>{
            if(type==1)
              this.documentList.updateList();
            else
              this.costList.updateList();
            
          });
        // }
        
      }
    });

  }
 

}
