<app-windowlist [model]="this">
    <div class="row selectPeriodCalendar">
        <div class="col-lg-12">
          <div class="p-2">
            <div class="row mb-2">
                <div class="col-3"></div>
                <div class="col-6">
                    <div class="btn-block btn-group" role="group" aria-label="Visualizzazione">
                        <button type="button" class="btn btn-xs btn-primary" [class.bg-light]="modeView==2" [class.active]="modeView==1" (click)="modeView=1">Mensile</button>
                        <button type="button" class="btn btn-xs btn-primary" [class.bg-light]="modeView==1" (click)="modeView=2;getItemsByMonth()">Annuale</button>
                    </div>
                </div>
            </div>
            <!-- <hr> -->
            <div class="row" *ngIf="modeView==2">
                <div class="col-12">
                    <div class="row">
                        <div class="col-3"></div>
                        <div class="col-6">
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text font-weight-bold bg-light">Anno</span>
                                </div>
                                <input class="form-control font-weight-bold text-center" type="number" min="2000" step="1" name="year" [(ngModel)]="year">
                                <div class="input-group-append">
                                    <button class="btn btn-secondary" (click)="getItemsByMonth()"><i class="fa fa-sync-alt mr-2"></i>Aggiorna</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table class="table table-sm table-striped ">
                        <thead>
                            <th class="text-center">Mese</th>
                            <th class="text-center">Scadenze</th>
                            <th class="text-right">Totale</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of list_month">
                                <td class="text-center" style="background-color: rgba(0,0,0,0.1);"><span class="text-lg">{{r.label}}</span></td>
                                <td class="">
                                    <ng-container>
                                            <table class="table table-sm mb-0">
                                                <tr *ngFor="let d of r.deadlines" (click)="monthClicked(d);" class="pointer" title="Visualizza lista">                                                    
                                                    <td>
                                                        <div class="badge badge-secondary mr-2">{{d.count}}</div>
                                                        <b>{{d.type}}</b>
                                                    </td>
                                                    <td  class="text-right"><small [class.text-danger]="d.total<-0.01">{{d.total | currency:"&euro;"}}</small></td>
                                                </tr>
                                            </table>
                                    </ng-container>
                                </td>
                                <td class="text-right" style="background-color: rgba(0,0,0,0.05);">
                                    <b [class.text-danger]="r.total<-0.01">{{r.total | currency:" &euro;"}}</b>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="bg-light">
                            <td class="text-center py-5" style="background-color: rgba(0,0,0,0.1);"><b class="text-lg">Anno {{year}}</b></td>
                            <td></td>
                            <td class="text-right py-5" style="background-color: rgba(0,0,0,0.05);"><b [class.text-danger]="total<0">{{total | currency:" &euro;"}}</b></td>
                        </tfoot>
                    </table>
                </div>
            </div>

            <div class="row" *ngIf="modeView==1">
                <div class="col-3">
                    <div class="btn btn-block btn-outline-success" mwlCalendarToday [view]="view" (viewDateChange)="handleEvent($event)" [(viewDate)]="viewDate"><i class="fas fa-calendar-day mr-2"></i>Oggi, {{today | date: 'EEEE dd MMMM yyyy':'':'It' }}</div>
                </div>
                <div class="col-6 date text-center">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <button type="button" class="btn btn-sm btn-secondary" mwlCalendarPreviousView [view]="view" (viewDateChange)="handleEvent($event)" [(viewDate)]="viewDate"><i class="fas fa-angle-left"></i></button>
                        </div>
                        <b class="form-control font-weight-bold text-center bg-light text-uppercase">{{ viewDate | calendarDate:(view + 'ViewTitle'):'It'}}</b>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-sm btn-secondary" mwlCalendarNextView [view]="view" (viewDateChange)="handleEvent($event)" [(viewDate)]="viewDate"><i class="fas fa-angle-right"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-3"></div>
                <div class="col-12 mt-2 calendarView" title="Visualizza dettagli">
                    <mwl-calendar-month-view-header ></mwl-calendar-month-view-header>
                    <mwl-calendar-month-view
                    [viewDate]="viewDate"
                    [events]="events"
                    [locale]="locale"
                    [cellTemplate]="customCellTemplate"
                    (dayClicked)="dayClicked($event.day)"  >
                    </mwl-calendar-month-view>
                </div>
            </div>
          </div>
        </div>
    </div>


    <ng-template  #customCellTemplate let-day="day" let-locale="locale">
            <span class="px-1 m-1 text-center text-lg">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            <div class="px-2">
                <ng-container *ngIf="day.events.length>0">
                    <div class="badge border pointer w-100" *ngFor="let e of day.events" [class.alert-danger]="e.meta.total<0">
                        <div class="badge badge-secondary mr-1" *ngIf="e.meta.count>1">{{e.meta.count}}</div>
                        <b>{{e.meta.type}}</b>
                        <small *ngIf="e.meta.total!=0" class="ml-2" [class.text-danger]="e.meta.total<0">{{e.meta.total | currency:"&euro;"}}</small>
                    </div>
                </ng-container>
            </div>
    </ng-template>

</app-windowlist>