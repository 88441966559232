<section id="hint" >
    <div id="hint-wrapper" class="animated bounceInUp">
      <img id="hint-header" class="d-block d-lg-none border-5 rounded-circle bg-white" src="assets/images/enrico_marchese.png"/>
      <button type="button" class="d-none d-lg-block btn bg-highlight icon-5 icon-close" style="position:absolute; z-index:9999; top:2rem; right:1rem;" (click)="close()"></button>
      <button type="button" class="d-block d-lg-none btn btn-circle bg-highlight icon-close" style="position:absolute; z-index:9999; top:-1rem; right:1.5rem;border: 2px solid var(--primary);" (click)="close()"></button>
      <div class="d-block d-lg-none col-12 mt-md-4 text-center">
        <h5 class="mb-0">Enrico Marchese</h5>
        <small>Responsabile Store - <b>Rende (CS)</b></small>
      </div>
     
    
      <div id="hint-content" class="row text-center mt-3"  style="height:70vh; overflow-y: auto;border-top:1px solid var(--light);">
          
          <div class="col-12 mt-2 mt-lg-0">
              <!-- <div class="icon mb-3" style="height: 7vh; background-image: url(icons/hint_01.svg);"></div> -->
            <div style="font-size: 1rem;">
              <ng-content #content></ng-content>
            </div>
          </div>

          <ng-container *ngIf="isConfirmDialog">
            <div class="col-3"></div>
            <div class="col-6 align-self-end">	
                <button type="button" class="btn btn-block btn-secondary mt-3 mb-5" (click)="confirm()">{{textConfirmBtn}}</button>
            </div>
            <div class="col-3"></div>
            </ng-container>
            <ng-container *ngIf="!isConfirmDialog">
              <div class="col-6 align-self-end">	
                  <button type="button" class="btn btn-block btn-secondary mt-2 mb-5" (click)="confirm()">{{textConfirmBtn}}</button>
              </div>
              <div class="col-6 align-self-end">	
                <button type="button" class="btn btn-block btn-secondary mt-2 mb-5" (click)="cancel()">{{textCancelBtn}}</button>
              </div>
            </ng-container>

      </div>

     

    </div>
</section>


  
