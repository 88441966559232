import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { ToastMode } from 'projects/core/src/include/structures';
import { Address } from '../../../db/address';
import { DocumentMode, Documents } from '../../../db/documents';
import { InventoriesDetails, InventoryMode } from '../../../db/inventories';
import { AddressesService } from '../../../services/addresses.service';
import { DocumentsService } from '../../../services/documents.service';
import { InventoriesDetailsService } from '../../../services/inventoriesdetails.service';
import { AddressDetailsComponent } from '../../address/address-details/address-details.component';
import { AddressComponent } from '../../address/address.component';
import {DocumentlistComponent} from '../../documents/documentlist/documentlist.component';
import { DocumentsComponent } from '../../documents/documents.component';

@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.css']
})
export class LoadComponent implements OnInit {
  
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {

    if(event.keyCode==113){ //F2
      this.goPrevious();
    }

    if(event.keyCode==115){ //F4
      this.goNext();
    }

    
  }

  get NextTitleBtn(){
    return this.step<(this.steps.length-1)?"Avanti":"Fine";
  }

  get showPrevious(){
    return this.step==0?false:true;
  }

  get showNext(){
    return this.step>-1?true:false;
  }

  @Input()
  saveDocumentLinked=true;

  @Input()
  type=InventoryMode.load;

//  steps_load=['1 - Seleziona un documento di carico','2 - Scansiona i prodotti ricevuti','3 - Controlla con il documento di carico','Finalizza']

//  steps_unload=['1 - Seleziona un documento di carico','2 - Scansiona i prodotti ricevuti','3 - Controlla con il documento di carico','Finalizza']

  steps_load=['1 - Scansiona i prodotti ricevuti','Finalizza']

  steps_unload=['1 - Scansiona i prodotti ricevuti','Finalizza']


  steps=['1 - Scansiona i prodotti ricevuti','Finalizza']
  step=0;
  documentSelected:any;
  textTosearch:string
  list:InventoriesDetails[]=[];
  listChecked:any[]=[];

  get steptitle(){
    return this.steps[this.step];
  }

  get isMinusQuantity(){
    return this.listChecked.filter(x=>x['check']==2).length>0?true:false;
  }

  get isPlusQuantity(){
    return this.listChecked.filter(x=>x['check']==1).length>0?true:false;
  }



  constructor(
    private inventoriesDetailsService:InventoriesDetailsService,
    private documentsService:DocumentsService,
    public addressesService:AddressesService
  ) { }

  ngOnInit(): void {
    
  }

  searchDocument(){
    Globals.modal.showModal(DocumentsComponent,[{"name":"mode","value":"modal"},{"name":"type","value":2}],(instance)=>{
      if(instance!=null){
        this.documentSelected=instance['recordSelected'];
        this.importFromDocument();
        this.goTo(1);
      }
    });
  }

  searchTextDocument(){
    this.documentsService.getDocument(this.textTosearch).subscribe((item)=>{
      if(item){
        this.documentSelected=item;
        this.goTo(1);
      }else{
        Globals.message.showToaster("Nessun documento trovato",ToastMode.DANGER);
      }
    })
  }


  validateStep(step,isNext=true){
    switch(step){
      case 1:
        
        if(isNext){
          if(this.documentSelected && this.documentSelected.id>0)
            return this.checkDocument();
          else{
            if(this.type==InventoryMode.unload){
              this.documentSelected=new Documents();
              this.documentSelected.id_type=DocumentMode.buy;
              this.listChecked=this.list;
            }
            this.goTo(3);
            return false;
          }
        }

        return true;
        break;
      case 2:
        if(this.list.length==0){
          Globals.message.showToaster("Inserire almeno un elemento nella lista");
          this.goTo(1);
          return false;
        }
          
      case 3:
        if(isNext){
          return this.confirm();
        }else{
          if(!this.documentSelected){
            this.goTo(1);
            return false;
          }
          
        }
        break;
     
        
    }

    return true;
  }


  goTo(step){
    this.step=step;
    
  }

  goNext(){
    if(this.validateStep(this.step))
      this.goTo(this.step+1);
  }

  goPrevious(){
    if(this.validateStep(this.step,false))
      this.goTo(this.step-1);
    
  }


  checkDocument(){
    this.listChecked  = Object.assign([], this.documentSelected['details']);

    //imposta a zero tutte le quantità ricercate
    for(let l of this.listChecked){
      //l['quantityRequired']=(l['quantity']==undefined?0:parseInt(l['quantity']))-(l['quantityPartial']==undefined?0:l['quantityPartial']);
      l['quantityRequired']=(l['quantity']==undefined?0:parseInt(l['quantityPartial']));
      
      l['quantityFinded']=0;
      l['check']=0; //OK
    }

    for(let i of this.list){

      //verifica se il prodotto esista o meno
      if(i.id_product==0){
        Globals.message.showToaster("Alcune voci non hanno un articolo registrato nel catalogo.",ToastMode.WARNING);
        return false;
      }



      //verifica che non ci siano nella listChecked
      let find=false;
      
      for(let lc of this.listChecked){
        if(lc.product.id==i.product.id){
          find=true;
          continue;
        }

      }
      if(!find){

        let c:any={};
        Object.assign(c,i);
        c['id_document']=this.documentSelected.id;
        c['quantity']=0; //in quanto non richiesto nell'ordine
        c['quantityRequired']=0;
        c['quantityFinded']=0;
        if(!c['quantityPartial'])
          c['quantityPartial']=0;
       
        c['check']=1; //prodotto in eccesso
        
       

        this.listChecked.push(c);
      }


      
    
    }

    //confronta le quantità
    for(let l of this.list){
      for(let lc of this.listChecked){
        if(l.product.id==lc.product.id){
          if(!lc['quantityFinded'])
            lc['quantityFinded']=parseInt(l.quantity.toString());
          else
            lc['quantityFinded']=parseInt(lc['quantityFinded'])+parseInt(l.quantity.toString());
          

        }
        if(lc['quantityFinded']==lc['quantityRequired']){
          lc['check']=0; //Ok
          lc['note']="";
        }

        if(lc['quantityFinded']>lc['quantityRequired']){
          lc['check']=1; //in eccesso
          lc['note']="Arrivate quantità superiori";
        }

        if(lc['quantityFinded']<lc['quantityRequired']){
          lc['check']=2; //in difetto
          lc['note']="Arrivate quantità inferiori";
        }


      }
    }

    return true;

  }


  confirm(){
    //verifica che il documento di carico sia stato definito
    if(this.documentSelected && this.documentSelected.id==0 && this.type==InventoryMode.unload){
      if(this.documentSelected.id_address>0){
        
      }else{
        alert("Selezionare un fornitore");
        return false;
      }

      if(!this.documentSelected.date){
        alert("Selezionare una data del documento");
        return false;
      }

      if(!this.documentSelected.reference){
        alert("Selezionare una numero del documento");
        return false;
      }

      if(!this.documentSelected.typedocument){
        alert("Selezionare una tipologia di documento");
        return false;
      }
    }


    //imposta tutte le quantità con l'inventario corretto
    for(let d of this.list){
      d.id=0;
      d.date=Helper.convertDateControl();
      d.time=Helper.currentTime();
      d.id_inventory=Globals.user['shop']?Globals.user['shop']['id_inventory']:Globals.user.getParam("id_inventory",0);
      d.type=this.type==InventoryMode.unload?1:2;
    }



    this.inventoriesDetailsService.saveMultiple(this.list,(result)=>{

      
      
      //compila il documento (se associato)
      if(this.documentSelected && this.saveDocumentLinked){
        for(let l of this.listChecked){
          l.quantityPartial=l['quantityFinded'];
        }
        this.documentSelected.details=this.listChecked;


        if(this.isMinusQuantity || this.isPlusQuantity){
          this.documentSelected.state=6; // parziale
        }else{
          this.documentSelected.state=2; // concluso
        }
        


        //crea un nuovo documento se la tipologia è diversa da quella selezionata
        if(this.documentSelected.type!=this.type){
          this.documentSelected.id=0; //crea un nuovo documento
        }
        
        //definisci se al salvataggio il documento sarà di carico o scarico
        this.documentSelected.id_type=this.type;

        this.documentsService.save(this.documentSelected,(id_document)=>{

          //aggiorna il documento nel carico/scarico
          this.inventoriesDetailsService.updateInventoryDetailsDocument(result,id_document,()=>{});

          this['modalWindow'].close('success');
        })


          
      }else{
        this['modalWindow'].close('success');
      }






      
    });

    return true;
  }

  closeWindow(){
    this['modalWindow'].close('success');
  }

  addAddress(){
    Globals.modal.showModal(AddressDetailsComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance!=null){
        this.documentSelected.addressItem=instance['record'];
        this.documentSelected.id_address=this.documentSelected.addressItem.id;
      }
    })
  }


  selectAddress(){
    Globals.modal.showModal(AddressComponent,[{"name":"mode","value":"modal"},{"name":"title","value":"Seleziona un'anagrafica"},{"name":"id_type","value":parseInt(Globals.parameters.get("documents").getParam("id_type_supplier"))}],(instance)=>{
      let r=instance['recordSelected'];

      this.OnSelectAddress(r);


      
      
    });
  }

  OnSelectAddress(r){

   
    this.documentSelected.addressItem=r;
      this.documentSelected.id_address=r.id;
      this.documentSelected.name=r['name'];
      this.documentSelected.address=r['address'];
      this.documentSelected.city=r['city'];
      this.documentSelected.zip=r['zip'];
      this.documentSelected.country=r['country'];
      this.documentSelected.vat_number=r['vat_number'];
      this.documentSelected.tax_code=r['tax_code'];

      this.documentSelected.name_destination=r['name'];
      this.documentSelected.address_destination=r['address'];
      this.documentSelected.city_destination=r['city'];
      this.documentSelected.zip_destination=r['zip'];
      this.documentSelected.country_destination=r['country'];
  }

  importFromDocument(){
    this.list=this.documentSelected.details;
  }
}
