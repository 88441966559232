import { Component, OnInit,Input } from '@angular/core';
import {CustomfieldsService} from '../../../lib/customfields.service';
import { SystemService } from 'projects/core/src/lib/system.service';
import { Globals } from 'projects/core/src/globals';
import { ToastMode } from 'projects/core/src/include/structures';



@Component({
  selector: 'app-import-customfields',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.sass'],
  
})
export class ImportCustomfieldsComponent implements OnInit {
  
  @Input()
  table="";

  parsing:any={};

  step=1;
  csv=[];
  separator=";";
  raw="";
  headers=[];

  structure_table=[];
  customfields=[];

  constructor(
    private customfieldsService: CustomfieldsService,
    private systemService:SystemService
    
  ){
    
   }

  ngOnInit(): void {
    this.parsing.table=this.table;
    this.parsing.field="";
    this.parsing.column_field="";
    this.parsing.correlation=[];

    this.systemService.getStructureTable(this.table).subscribe((result)=>{
      this.structure_table=result;
    });

    this.customfieldsService.getCustomFields(this.table,true).subscribe((result)=>{
      this.customfields=result;
    })
    
  }

  ngOnChange(){
    

  }

  public onChange(event:any): void {

    let fileList: FileList=event.target.files;
    let file = fileList[0];
    let fileReader: FileReader = new FileReader();
    let self = this;

    fileReader.onloadend = function(x) {
      self.raw=null;
      if(fileReader.result!=null){
        self.raw=fileReader.result.toString();
        
        self.step=2;
        self.loadCSV(self.raw);
      
      }
      
    }
    fileReader.readAsText(file);
  }

  loadCSV(raw:string){
   
    this.headers=[];
    let rows=raw.split("\n");

    this.csv=[];
    let max_col=0;
    for(let i=0;i<rows.length;i++){
      let r=rows[i]
      r=r.replace(/"/gi,"");
      let cols=r.split(this.separator);
      this.csv.push(cols);
      if(max_col<cols.length)
        max_col=cols.length;
  
    }

    for (let i=0;i<max_col;i++){
      this.headers.push({"key_field":false,"id_customfield":0});
      this.parsing.correlation.push({"id_customfield":0,"id_column":i})
    }

    
    

   

  }

  setKeyField(id_column,field){
    this.parsing.field=field.target.value;
    this.parsing.column_field=id_column;
  }

  setCustomField(id_column,field){
    if(field.target.selectedIndex>0){

    
      let id_customfield=this.customfields[field.target.selectedIndex-1].id;
      this.parsing.correlation[id_column].id_customfield=id_customfield;
    }
  }

  resetKeyField(){
    for(let h of this.headers){
      h.key_field=false;
    }
  }


  import(){
    this.customfieldsService.importCSV(this.raw,JSON.stringify(this.parsing),this.separator,(result)=>{
      if(result){
        Globals.message.showToaster("Importazione completata correttamente",ToastMode.SUCCESS);
        this.close();
      }else{
        Globals.message.showToaster("Errore durante l'importazione",ToastMode.DANGER);
        
      }
    });
  }

  close(){
    this['modalWindow'].close();
  }
}
