
<app-window [title]="title">
    <form role="form" id="detailForm" class="mt-1">
        <div class="card card-outline" >
            <div class="card-body">
                
                <ng-container *ngFor="let f of record.fields">
                    
                    
                    <ng-container *ngIf="f.type=='text'">
                        <div class="form-group row">
                            <div class="col-2"><label>{{f.label}}</label></div>
                            <div class="col-10"><input type="text" class="form-control form-control-sm" name="{{i}}_{{f.name}}" [(ngModel)]="f.value"></div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="f.type=='number'">
                        <div class="form-group row">
                            <div class="col-2"><label>{{f.label}}</label></div>
                            <div class="col-10"><input type="number" class="form-control form-control-sm" name="{{i}}_{{f.name}}" [(ngModel)]="f.value"></div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="f.type=='textarea'">
                        <div class="form-group row">
                            <div class="col-2"><label>{{f.label}}</label></div>
                            <div class="col-10"><textarea type="text" class="form-control form-control-sm" name="{{i}}_{{f.name}}" [(ngModel)]="f.value"></textarea></div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="f.type=='date'">
                        <div class="form-group row">
                            <div class="col-2"><label>{{f.label}}</label></div>
                            <div class="col-10"><input type="date" class="form-control form-control-sm" name="{{i}}_{{f.name}}" [(ngModel)]="f.value"></div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="f.type=='time'">
                        <div class="form-group row">
                            <div class="col-2"><label>{{f.label}}</label></div>
                            <div class="col-10"><input type="time" class="form-control form-control-sm" name="{{i}}_{{f.name}}" [(ngModel)]="f.value"></div>
                        </div>
                    </ng-container>
        
                    <ng-container *ngIf="f.type=='boolean'">
                        <div class="form-group row">
                            <div class="col-2"><label>{{f.label}}</label></div>
                            <div class="col-10">
                                <select class="form-control form-control-sm" name="{{i}}_{{f.name}}" [(ngModel)]="f.value">
                                    <option value="0">No</option>
                                    <option value="1">Si</option>
                                </select>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="f.type=='checkbox'">
                        <div class="form-group row">
                            <div class="col-2"><label>{{f.label}}</label></div>
                            <div class="col-10">
                                <input type="checkbox" class="form-control form-control-sm" name="{{i}}_{{f.name}}" [(ngModel)]="f.value">
                                
                            </div>
                        </div>
                    </ng-container>
        
                    <ng-container *ngIf="f.type=='separator'">
                        <div class="form-group row">
                            <h5 *ngIf="f.label">{{f.label}}</h5>
                            <hr/>
                        </div>
                    </ng-container>
        
                    <ng-container *ngIf="f.type=='select'">
                        <div class="form-group row">
                            <div class="col-2"><label>{{f.label}}</label></div>
                            <div class="col-10">
                                <select class="form-control form-control-sm" name="{{i}}_{{f.name}}" [(ngModel)]="f.value">
                                    <option value="{{o}}" *ngFor="let o of f.params.split(';')">{{o}}</option>
                                </select>
                            </div>
                        </div>
                    </ng-container>

                    

                </ng-container>
                
                <div class="form-group row">
                    <div class="col-2">
                        <label>Firma</label>
                    </div>
                    <div class="col-10">
                        <img src="{{ getSignatureImage()}}" class="img-thumbnail">
                    </div>
                </div>


            </div>
        </div>
    </form>
    <app-toolbarmodel [model]="this"></app-toolbarmodel>
</app-window>