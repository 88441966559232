import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'projects/core/src/authGuard';
import { AddressDetailsComponent } from './address-details/address-details.component';

import { AddressComponent } from './address.component';

const routes: Routes = [
  { path: '', component: AddressComponent,canActivate:[AuthGuard] },
  { path: 'address-details/:id', component: AddressDetailsComponent ,canActivate:[AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AddressRoutingModule { }
