import { Component, Input, OnInit } from '@angular/core';
import { ActivitiesService } from '../../../../services/activities.service';
import { Globals } from 'projects/core/src/globals';
import { ToastMode } from 'projects/core/src/include/structures';

@Component({
  selector: 'app-confirm-missing-values',
  templateUrl: './confirm-missing-values.component.html',
  styleUrls: ['./confirm-missing-values.component.css']
})
export class ConfirmMissingValuesComponent implements OnInit {

  @Input()
  list:any=[];

  @Input()
  item:any={};

  constructor(
    private activitiesService:ActivitiesService
  ) { }

  ngOnInit(): void {
    for(let i=0;i<this.list.length;i++){
      this.list[i].enable=true;
    }
  }

  ngOnChanges(): void {
    
  }

  confirm(){
    this.activitiesService.updateMissingValues(this.list).subscribe((result)=>{
      Globals.message.showToaster("Valori aggiornati nel database",ToastMode.SUCCESS);
      this.close();   
    });
    
  }

  close(){
    this['modalWindow'].close();
  }

  getFieldName(field){
    return "missingValues_"+field;
  }

}
