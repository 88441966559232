import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'projects/core/src/authGuard';
import { DeadlineDetailsComponent } from './deadline-details/deadline-details.component';

import { DeadlinesComponent } from './deadlines.component';

const routes: Routes = [
  { path: '', component: DeadlinesComponent,canActivate:[AuthGuard] },
  { path: 'deadline-details/:id', component: DeadlineDetailsComponent ,canActivate:[AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeadlinesRoutingModule { }
