<input type="checkbox" [(ngModel)]="showNotAssigned"><small>&ensp;Mostra prodotti non più assegnati al cliente</small>
<ul class="list-group">
    <ng-container *ngFor="let record of list">
        <li class="list-group-item border-bottom"  *ngIf="isShow(record)">

            <div class="row">
                <div class="col-md-10">
                    <small class="text-info">{{record.category}}</small><br/>
                    <h6><i *ngIf="record.icon" class="{{record.icon}}"></i> <i *ngIf="!record.icon" class="fa fa-cash-register"></i> &nbsp;{{record.brand}} {{record.model}} ({{record.type}}) 
                        <div class="badge badge-warning" *ngIf="id_shop!=record.current_id_shop && id_address!=record.current_id_address">
                            <i class="fa fa-exclamation"></i>&nbsp;Non più assegnato a questo cliente
                        </div>
                    </h6>
                    <b>{{record.sn}}</b><span *ngIf="record.matricola"> / {{ record.matricola }}</span><br/>
                    <small><i class="fa fa-store"></i>&nbsp; {{record.shopname}}</small>
                    <!--
                    <span class="badge badge-danger" *ngIf="record.status==3">Cessato</span>
                    <span class="badge badge-danger" *ngIf="record.status==4">Perso</span>
                    <span class="badge badge-danger" *ngIf="record.status==5">Chiuso</span>
                    -->
                </div>
                <div class="col-md-2">
                    <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1">
                        <i class="fas fa-pencil-alt"></i></button>
                </div>
            </div>
        </li>
    </ng-container> 
</ul>

<div class="row mt-2">
    <div class="col-12">
        <button class="btn btn-sm btn-success" (click)="addInstallation()"><i class="fa fa-plus"></i>&nbsp;Aggiungi prodotto</button>
    </div>
</div>