import { Component, OnInit} from '@angular/core';
import { Model } from 'projects/core/src/include/model';
import { ActivatedRoute } from '@angular/router';
import { FormValue } from 'projects/core/src/include/structures';
import { FormsValueService } from '../../../../services/formsvalue.service';
import { Globals } from 'projects/core/src/globals';


@Component({
  selector: 'app-form-detail',
  templateUrl: './form-detail.component.html',
  styleUrls: ['./form-detail.component.css']
})
export class FormDetailComponent extends Model<FormValue> implements OnInit {



constructor(
  private route1: ActivatedRoute,
  private formsService: FormsValueService,
  ) {
    super(route1,formsService); 
  
  
}


ngOnInit (): void {

  this.title = 'Dettaglio form';
  

  super.ngOnInit.apply(this, arguments); 
  
  if(this.mode!="modal")
    this.route1.params.subscribe((params) => this.id = params.id);

  this.getItem();
 

}

getSignatureImage(){
  return Globals.config.serverUrl+"/"+this.record.signature;
}






}
