import { NgModule } from '@angular/core';
import { TypeComponent } from './type.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';


@NgModule({
    imports: [FormsModule,CommonModule,ModalModule],
   exports: [TypeComponent],
   declarations: [TypeComponent],
   providers: [],
})
export class TypeModule{ }