import { Component, OnInit,Input, Output,EventEmitter } from '@angular/core';
import { ModelList, Page } from '../../include/modellist';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.sass']
})
export class PaginationComponent<T> implements OnInit {

  @Input()
  paging_count:number;

  @Input()
  pages:Page[];

  @Input()
  model:ModelList<T>=null;

  @Input()
  showStatus:boolean=true;
  
  

  constructor() { }

  


  ngOnInit(): void {
  }

  getItems(){
    
      this.model.getItems();
 
    
  }

}
