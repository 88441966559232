import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { User } from '../db/user';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends WSService<User> {

  constructor() { super("users"); }

  getGroups():Observable<any[]>{
    return Globals.ws.requestUrl<any[]>("users","getGroups",[]);
  }

  getUsers():Observable<any[]>{
    return Globals.ws.requestUrl<any[]>("users","getItems",[]);
  }


}
