<div class="modal-header">
    <b>Prenotazione #{{booking.code}}</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="close()" >
          <i class="fas fa-times"></i>
        </button>
    </div>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <table class="w-100 mb-2">
                <tr>
                    <td>Prenotazione</td>
                    <td class="text-right">{{booking.total | currency:"EUR"}}</td>
                </tr>
                <tr>
                    <td>Pagato</td>
                    <td class="text-right">{{booking.paid | currency:"EUR"}}</td>
                </tr>
                <tr>
                    <td>Da pagare</td>
                    <td class="text-right">{{booking.total-booking.paid | currency:"EUR"}}</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="form-group form-group-sm">
                <label>Importo</label>
                <div class="input-group input-group-sm">                   
                    <input type="number" class="form-control form-control-sm" [(ngModel)]="amount" (ngModelChange)="setStatus()">
                    <span class="input-group-append">
                        <span class="input-group-text input-group-text-sm">Euro</span>
                    </span>
                </div>
            </div>
            <div class="form-group form-group-sm">
                <label>Modalità di pagamento</label>
                <div class="input-group input-group-sm">                   
                    <select class="form-control form-control-sm"  [(ngModel)]="methodpayment">
                        <option *ngFor="let m of methodpayments" (value)="m.name">{{m.name}}</option>
                    </select>
                    <span class="input-group-append input-group-append-sm">
                        <button class="btn btn-sm btn-default" (click)="methodpayment='Contanti'">Contanti</button>
                        <button class="btn btn-sm btn-default" (click)="methodpayment='POS'">POS</button>
                        <button class="btn btn-sm btn-default" (click)="methodpayment='Omaggio'">Omaggio</button>
                    </span>
                </div>
            </div>
            <div class="form-group form-group-sm">
                <label>Stato della prenotazione</label>
                <select  class="form-control form-control-sm" name="state" [(ngModel)]="booking.state">
                    <option value="1">In attesa del pagamento</option>
                    <option value="2">Confermato</option>
                    <option value="6">Confermato (fornitore esterno)</option>
                    <option value="3">Annullato</option>
                   
                </select>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button class="btn btn-success" [disabled]="amount<=0" (click)="confirm();">Conferma</button>
    <button class="btn btn-secondary" (click)="close();">Annulla</button>
</div>