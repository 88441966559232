import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'projects/core/src/lib/user.service';
import { User } from '../../db/user';

@Component({
  selector: 'app-itemuserinfo',
  templateUrl: './itemuserinfo.component.html',
  styleUrls: ['./itemuserinfo.component.css']
})
export class ItemuserinfoComponent implements OnInit {


  user_created:User;
  user_lastupdate:User;

  @Input()
  record:any;


  constructor(
    private userService:UserService
  ) { }

  ngOnInit(): void {
    


  }

  ngOnChanges(){
    
  }

  updateUser(){
    if(this.record.created_by){
      this.userService.getItem(this.record.created_by).subscribe((item)=>{
        this.user_created=item;
      });
    }
    if(this.record.lastupdate_by){
      this.userService.getItem(this.record.lastupdate_by).subscribe((item)=>{
        this.user_lastupdate=item;
      });
    }
  }

  

}
