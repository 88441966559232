import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Model } from 'projects/core/src/include/model';
import { CustomFunction } from 'projects/core/src/include/structures';
import { CustomfunctionsService } from 'projects/core/src/lib/customfunctions.service';

@Component({
  selector: 'lib-customfunctionsdetail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class CustomFunctionsDetailComponent  extends Model<CustomFunction> implements OnInit {

  constructor( 
    private route1: ActivatedRoute,
    private customfunctionService: CustomfunctionsService,
  ) {
    super(route1,customfunctionService);
   }

   
  ngOnInit(): void {

    this.title = 'Funzione personalizzata';
    this.record=new CustomFunction();

    this.getItem();

  }

}
