import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.css']
})
export class WindowComponent implements OnInit {

  @Input()
  title:string="";

  @Input()
  model:any=null;

  constructor() { }

  LoadedComplete(){
    if(this.model){
      return this.model.record!=null?true:false;
    }

    return true;
  }

  ngOnInit(): void {
  }

}
