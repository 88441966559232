import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



import { AddressComponent } from '../../views/address/address.component';
import { ShopsComponent } from '../../views/shops/shops.component';
import { DocumentlistComponent } from '../../views/documents/documentlist/documentlist.component';
//import { InstallationsComponent } from '../../views/installations/installations.component';
import { ContractsComponent } from '../../views/contracts/contracts.component';
import { SerialListComponent } from '../../views/products/serials/list/list.component';
import { ListComponent as BookingListComponent } from '../../views/booking/list/list.component';
import { CalendarComponent } from '../../views/booking/calendar/calendar.component';
import { ActivitiesListComponent } from '../../views/activities/list/list.component';
import { ProjectsComponent } from '../../views/projects/projects.component';
import { ListComponent as AdsListComponent} from 'projects/modules/src/app/ads/views/list/list.component';
import { DossierComponent } from 'projects/modules/src/app/dossier/views/dossier/dossier.component';
import { DeadlinesComponent } from '../../views/deadlines/deadlines.component';
import { ListComponent as TicketsComponent } from 'projects/modules/src/app/bustickets/views/list/list.component';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class InitModule { 
  static componentInstances: Map<string, any> = new Map();
  constructor() {
    InitModule.setComponent('addresses', AddressComponent);
    InitModule.setComponent('shops', ShopsComponent);
    InitModule.setComponent('documents_sell', DocumentlistComponent);
    InitModule.setComponent('documents_buy', DocumentlistComponent);
    InitModule.setComponent('documents', DocumentlistComponent);
    InitModule.setComponent('installations', SerialListComponent);
    InitModule.setComponent("contracts",ContractsComponent);
    InitModule.setComponent("activities",ActivitiesListComponent);
    InitModule.setComponent("deadlines",DeadlinesComponent);

    InitModule.setComponent("booking",BookingListComponent);
    InitModule.setComponent("project",ProjectsComponent);
    InitModule.setComponent("ads",AdsListComponent);
    InitModule.setComponent("dossier",DossierComponent);
    InitModule.setComponent("tickets",TicketsComponent);
    
  }

  static setComponent(name: string, component: any): void {
    InitModule.componentInstances.set(name, component);
  }

  static getComponent(name: string) {
    return InitModule.componentInstances.get(name);
  }
}
