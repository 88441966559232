import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProductitemComponent } from './productitem.component';
import { StatedocumentModule } from '../statedocument/statedocument.module';
import { MoveinventoryModule } from '../moveinventory/moveinventory.module';


@NgModule({
    imports: [FormsModule,CommonModule,StatedocumentModule,MoveinventoryModule],
   exports: [ProductitemComponent],
   declarations: [ProductitemComponent],
   providers: [],
})
export class ProductitemModule{ }