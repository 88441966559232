<div class="modal-header bg-light">
    <b>Invio guidato documenti allo SdI</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="close()" >
          <i class="fas fa-times"></i>
        </button>
    </div>
</div>

<div class="modal-body" >
    <div class="float-left p-2">
        <i class="fas fa-file-invoice fa-4x text-primary"></i>
    </div>
    <div class="float-left px-2 pt-4 text-primary">
        <span *ngIf="documents.length>1">Hai selezionato <b>{{documents.length}}</b> documenti.</span>
        <span *ngIf="documents.length==0">Hai selezionato un documento.</span>
        <br>
        <span *ngIf="documentToSend==0">Nessun documento è idoneo all'invio al Sistema di Interscambio.</span>
        <span *ngIf="documentToSend==1">Un documento è idoneo all'invio al Sistema di Interscambio.</span>
        <span *ngIf="documentToSend>1"><b>{{documentToSend}}</b> documenti sono idonei all'invio al Sistema di Interscambio.</span>
    </div>
    <ng-container *ngIf="documents.length>0">
        <table class="table table-sm" style="height:5rem; max-height:60vh" >
            <thead class="text-primary">
                <th>Tipo</th>
                <th>Data</th>
                <th>Rif</th>
                <th>Anagrafica</th>
                <th>Importo</th>
                <th style="text-align:center">Stato SdI</th>
                <th>Verifica</th>
                <th></th>
            </thead>
            <tbody>
                <ng-container *ngFor="let record of documents">
                    <!-- <tr> -->
                    <tr *ngIf="(record.typedocument==1 || record.typedocument==2 || record.typedocument==12 || record.typedocument==3) && (record.sdi_status==0 || record.sdi_status==3 || record.sdi_status==-1)" [class.alert-danger]="record.sdi_message">
                        <td>
                            <span *ngIf="record.typedocument==1"  title="Fattura" class="badge bg-olive">FS</span>
                            <span *ngIf="record.typedocument==2"  title="Fattura Accompagnatoria" class="badge bg-teal" >FV</span>
                            <span *ngIf="record.typedocument==12" title="Fattura Acconto" class="badge bg-lime" >FA</span>
                            <span *ngIf="record.typedocument==3"  title="Nota di Credito" class="badge bg-marron">NC</span>
                        </td>
                        <td>{{record.date| date:"dd-MM-YYYY"}}</td>
                        <td>{{record.reference}} {{record.referenceprefix}}</td>
                        <td>{{record.addressItem.name}}</td>
                        <td>{{record.total | currency:"&euro; "}}</td>
                        <td style="text-align:center">
                            <i  title="da inviare" *ngIf="record.sdi_status==0 || record.sdi_status==-1" class="text-info fas fa-paper-plane"></i>
                            <i  title="Inviata, in attesa di risposta" *ngIf="record.sdi_status==1" class="text-warning fa fa-clock"></i>
                            <i  title="Consegnata" *ngIf="record.sdi_status==2" class="text-success fa fa-check"></i>
                            <i  title="In errore" *ngIf="record.sdi_status==3" class="text-danger fa fa-exclamation-triangle"></i>
                            <i  title="Consegnata ma con mancato recapito" *ngIf="record.sdi_status==4" class="text-success fa fa-check"></i>
                        </td>
                        <td style="max-width:16rem; overflow:hidden" >
                            <span *ngIf="record.sdi_message">
                                <small>{{record.sdi_message | json}}</small><br>
                                <button class="btn btn-outline-info btn-xs mt-1" *ngIf="record.filexml" (click)="downloadXML(record)">Apri file XML</button>
                            </span>
                            <span class="text-success" *ngIf="!record.sdi_message && VerifyDone ">
                                Ok!
                            </span>
                        </td>
                        <td>
                            <button title="Vedi/Modifica" class="btn btn-secondary btn-sm float-right" (click)="VerifyDone=false;openDetail(record)"><i class="fa fa-pencil-alt"></i></button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <div class="mt-4">
            <button [class.btn-outline-success]="VerifyDone" class="btn btn-outline-primary btn-sm" (click)="checkDocuments()">
                Verifica integrità documenti<b class="ml-3" *ngIf="VerifyDone">Ok <i class="fa fa-chevron-right"></i></b></button>
            <ng-container *ngIf="VerifyDone">
                <button *ngIf="VerifyDone && documentToSend>0" class="btn btn-primary btn-sm ml-2" (click)="sendToSdi()">
                    Invia {{documentToSend}} document<span *ngIf="documentToSend>1">i</span><span *ngIf="documentToSend==1">o</span> allo SdI                
                    <i *ngIf="!afterSend" class="fas fa-paper-plane ml-2"></i>
                    <b class="ml-3" *ngIf="afterSend">Ok <i class="fa fa-chevron-right"></i></b>
                </button>
                <span class="btn btn-warning btn-sm ml-2" *ngIf="!afterSend && documentToSend==0">Nessun documento da inviare!</span>
            </ng-container>
            <ng-container *ngIf="afterSend">
                <span class="btn btn-success btn-sm ml-2" *ngIf="total_success>0">
                    <span *ngIf="total_success>0">{{total_success}} documenti inviati correttamente allo SdI</span>
                    <span *ngIf="total_success==0">un documento inviato correttamente allo SdI</span>
                    <i class="fas fa-check ml-1"></i></span>
                <span class="btn btn-danger btn-sm ml-2" *ngIf="total_errors>0">
                    Attenzione: Ci sono {{total_errors}} document<span *ngIf="total_errors>1">i</span><span *ngIf="total_errors==1">o</span> che presentano errori nell'invio allo SdI
                    <i class="fas fa-exclamation mr-1"></i></span>
            </ng-container>
        </div>
    </ng-container>
</div>
<div class="modal-footer">
    <button class="btn btn-sm btn-outline-secondary" (click)="close()">Annulla</button>
</div>