<div class="modal-header">
    Procedura di importazione file CSV
</div>
<div class="modal-body">
    <ng-container *ngIf="step==1">
        <div class="form-group">
            <label>File del percorso (CSV)</label>
            <input type="file"  class="form-control form-control-sm"   (change)="onChange($event)">
        </div>
    </ng-container>
    <ng-container *ngIf="step==2">
        
        <div class="form-group">
            <label>Contenuto del file</label>
            <textarea class="form-control form-control-sm" [(ngModel)]="raw"></textarea>
            
        </div>
        <div class="form-group">
            <label>Separatore di campo</label>
            <div class="input-group input-group-sm">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="separator" (change)="loadTrack(track_raw)">
                <div class="input-group-append">
                    <button class="btn btn-default" (click)="loadCSV(raw)">Aggiorna</button>
                </div>
            </div>
        </div>
        
        <div class="form-group" style="overflow-y:scroll;height: 150px;">
            <label>Dati estratti</label>
            <table class="table table-sm" >
                <thead>
                    <th *ngFor="let h of headers;let j=index">
                        <div class="input-group input-group-sm">
                            <select *ngIf="h.key_field" class="form-control form-control-sm" (change)="setKeyField(j,$event)">
                                <option  value=""></option>
                                <option *ngFor="let f of structure_table" [value]="f.Field">{{f.Field}}</option>
                            </select>
                            
                            <select *ngIf="!h.key_field" class="form-control form-control-sm" (change)="setCustomField(j,$event)">
                                <option  value="0"></option>
                                <option *ngFor="let c of customfields" [value]="c.id">{{c.id}} - {{c.label}}</option>
                            </select>
                            <div class="input-group-append">
                                <button class="btn btn-sm" [class.btn-secondary]="h.key_field" (click)="resetKeyField();h.key_field=!h.key_field">RIF</button>
                            </div>
                        </div>
                        
                        
                    </th>
                </thead>
                <tbody >
                    <tr *ngFor="let r of csv;let i=index">
                        <td *ngFor="let c of r">{{c}}</td>
                    </tr>
                </tbody>
            </table>
            
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-success" (click)="import()">Importa</button>
            </div>
        </div>
    </ng-container>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary" (click)="close()">Chiudi</button>
</div>