import { Component, OnInit,Input, Output } from '@angular/core';
import { ModelList } from '../../include/modellist'
import { UntypedFormBuilder } from '@angular/forms';
import { Filter,FilterMode,CustomField, ToastMode } from '../../include/structures'
import { CustomfieldsService } from '../../lib/customfields.service';
import { Globals } from '../../globals';

@Component({
  selector: 'app-customfieldssettings',
  templateUrl: './customfields-settings.component.html',
  styleUrls: ['./customfields-settings.component.sass']
})
export class CustomfieldssettingsComponent extends ModelList<CustomField> implements OnInit {

  @Input()
  id_address:number=0;

  option:string;
  condition_id:string;
  condition_field:string;

  
 
  
  filter_customfields:Filter=new Filter();
  selectedCustomField:CustomField;

  customfieldsList:CustomField[];
 
  constructor(
    private customfieldservice: CustomfieldsService,
    private fb1: UntypedFormBuilder,

    
  ) {
    super(customfieldservice,fb1);
   }


  ngOnInit(): void {
    this.title='Campi personalizzati';
    
    super.ngOnInit.apply(this, arguments); 
    
    this.filter_customfields.mode=FilterMode.normal;
    this.filter_customfields.fields=[];
    this.filter_customfields.fields.push("c.table");
    this.filter_customfields.value="";
    this.filter.push(this.filter_customfields);

    this.paging_count=10000;
    

    this.ordering.field="c.ordering";

    this.getItems();

    

  this.customfieldservice.getCustomFields("").subscribe((items)=>{
    this.customfieldsList=items;
  })
    
  }

  addCustomField(){
    let r:CustomField={} as CustomField;
    r.status=1;
    r.type="text";
    if(this.filter_customfields.value!="")
      r.table=this.filter_customfields.value;
    this.selectedCustomField=r;
  }



  setCustomField(record:CustomField){
    
    this.selectedCustomField=Object.assign({}, record);
    if(this.selectedCustomField.type=="select")
      try{
        this.selectedCustomField.option=JSON.parse(this.selectedCustomField.option);
      }catch{
        this.selectedCustomField.option=[];
      }
  }

  confirmOptionField(){
    this.customfieldservice.save(this.selectedCustomField,(id)=>{
      this.getItems();
    });
  }

  changeType(event){
    if(this.selectedCustomField.type=="select"){
      this.selectedCustomField.option=[];
    }
  }
  
  confirm_delete(id){
    confirm("Eliminare l'elemento?")?this.delete(id):false;
  }

  resetAllValueCustomField(id_customfield){
    if(confirm("Confermi di voler resettare tutti i valori del campo personalizzato?\nQuesta operazione non è reversibile!")){
      this.customfieldservice.resetAllValueCustomField(id_customfield).subscribe((result)=>{
        Globals.message.showToaster("Valori resettati correttamente",ToastMode.SUCCESS);
      })
    }
  }
}
