import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StripeComponent } from 'projects/core/src/common/stripe/stripe.component';
import { TakephotoComponent } from 'projects/core/src/common/takephoto/takephoto.component';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { Model } from 'projects/core/src/include/model';
import { Extension } from 'projects/core/src/include/parameters';
import { ToastMode, User } from 'projects/core/src/include/structures';
import { UploadService } from 'projects/core/src/lib/upload.service';
import { UserService } from 'projects/core/src/lib/user.service';
import { VerifyFiscalCodeService } from 'projects/core/src/lib/verify-fiscal-code.service';
import { InsertpasswordComponent } from 'projects/hitinero-site/src/app/modules/insertpassword/insertpassword.component';
import { RegistrationService } from 'projects/hitinero-site/src/app/services/registration.service';
import { Address } from '../../../db/address';
import { Booking, BookingDetail } from '../../../db/booking';
import { Image } from '../../../db/image';
import { Product } from '../../../db/product';
import { RentalPrice } from '../../../db/rentalprice';
import { Shops } from '../../../db/shops';
import { Calendar, Tour } from '../../../db/tour';
import { ReceiptComponent } from '../../../modules/receipt/receipt.component';
import { AddressesService } from '../../../services/addresses.service';
import { BookingService } from '../../../services/booking.service';
import { DocumentsService } from '../../../services/documents.service';
import { MethodpaymentsService } from '../../../services/methodpayments.service';
import { ProductsService } from '../../../services/products.service';
import { ShopsService } from '../../../services/shops.service';
import { ToursService } from '../../../services/tours.service';
import { VerifyVATNumberService } from '../../../services/verify-vatnumber.service';
import { AddressDetailsComponent } from '../../address/address-details/address-details.component';
import { AddressComponent } from '../../address/address.component';
import { CheckinComponent } from '../checkinbooking/checkinbooking.component';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.css']
})
export class WizardComponent extends Model<Booking> implements OnInit {
  
  @ViewChild("searchCode")
  searchCode:ElementRef;

  @ViewChild("modalOtherMetodPayments")
  modalOtherMetodPayments:TemplateRef<any>;
  modalOtherMetodPaymentsRef;

  @ViewChild("modalRentalDetails")
  modalRentalDetails:TemplateRef<any>;
  modalRentalDetailsRef;

  @ViewChild("modalProductDetails")
  modalProductDetails:TemplateRef<any>;
  modalProductDetailsRef;
  productSelected:any;

  @ViewChild("modalTourDetails")
  modalTourDetails:TemplateRef<any>;
  modalTourDetailsRef;
  tourSelected:any;

  @ViewChild("modalInsertPassword")
  modalInsertPassword:TemplateRef<any>;
  modalInsertPasswordRef;

  @ViewChild("modalUserExist")
  modalUserExist:TemplateRef<any>;
  modalUserExistRef;
  
  @ViewChild("modalThankyou")
  modalThankyou:TemplateRef<any>;
  modalThankyouRef;

  @ViewChild("modalConditions")
  modalConditions:TemplateRef<any>;
  modalConditionsRef;

  @ViewChild("modalNoPricelistSelected")
  modalNoPricelistSelected:TemplateRef<any>;
  modalNoPricelistSelectedRef;

  goToCheckin:boolean = false;

  get config():any{
    return Globals.config;
  }

  constructor(
    private route1: ActivatedRoute,
    private bookingService: BookingService,
    private methodpaymentsService:MethodpaymentsService,
    public addressesService:AddressesService,
    private shopsService:ShopsService,
    private registrationService:RegistrationService,
    private verifyFiscalCode:VerifyFiscalCodeService,
    private verifyVATNumberService:VerifyVATNumberService,
    private productService:ProductsService,
    private documentService:DocumentsService,
    private tourService:ToursService,
    private uploadService:UploadService,
    private modalService:NgbModal,

  ) { 
    super(route1,bookingService);
  }

  @Input()
  mode:string="modal";

  @Input()
  record:Booking=null;

  @Input()
  tour_selected:any;

  @Input()
  only_in_stock=false; //ricerca solo i prodotti che hanno disponibilità di magazzino

  @Input()
  view_stock=true; //mostra la disponibilità di prodotto da prenotare

  @Input()
  show_only_product_inventory=false; //se true verranno visualizzati solo i prodotti del magazzino del luogo di partenza

  @Input()
  type_booking=0; //definisce la modalità di prenotazione (se noleggio o tour) 0= seleziona tra noleggio e tour 1= noleggio 2= tour

  @Input()
  autocompileRent=false;

  @Output()
  pricelistSelected:EventEmitter<any>=new EventEmitter();
  @ViewChild(StripeComponent)
  stripeComponent:StripeComponent;
  
  //gestione modale
  confirmPaymentModal=false;
  payModal=false;
  checkpassword:boolean=false;
  modalPricelistSelected:boolean=false;
  priceList=null; //tariffa corrente
  allPriceList=[];
  defaultPriceList=null;
  typeBikeRental=2;
  tax_code_error:string="";
  vat_number_error:string="";
  foreign=false;
  clicked=false;
  typedocument=1;
  password="";

  paid=0;
  @Input()
  type="bike";

  @Input()
  id_address:number=0;

  @Input()
  methodpayment:string="Contanti";

  @Input()
  state:number=1;
  
  step=-1;
  only_available=true;
  id_product_tour=0;
  
  module:Extension=null;
  shops:Shops[]=[];
  products:Product[]=[];
  categories=[];
  payment_method_id;
  user:User=new User();
  user_agent:User=new User();
  isUsernameUsed:boolean=false;
  
  currentProduct:Product;
  currentTour:Tour=new Tour();
  currentCalendar:Calendar=new Calendar();
  tours:Tour[]=[];
  methodpayments=[];
  
  total_gross:number;

  exclude_categories="";

  search="";

  icon_rent="";
  icon_tour="";
  fileToUpload: File = null;
  passengerSelected:any;

  access_username="";

  loading_stripe=false;

  get isLogin(){
    return Globals.isLogin;
  }

  get currentUser(){
    return Globals.user;
  }

  

  requestparamspartecipants=[];

 

  booking:Booking;
  
  

  ngOnInit(): void {
    if(this.record==null)
      this.record=new Booking(this.type=='bike'?1:2);
    this.record.state=this.state;

    if(!Globals.navigation.skipLocationChange)
      window.history.replaceState("","","/rent");


    let module_tour=Globals.parameters.get("tours");
    this.module=Globals.parameters.get("booking");
    let module_rent=Globals.parameters.get("installations");
    let id_default_address=this.module.getParam("id_address",0);
    this.typeBikeRental=this.module.getParam("type_rental",0);
    if(module_rent && module_rent.icon)
      this.icon_rent=module_rent.icon;
    if(module_tour && module_tour.icon)
      this.icon_tour=module_tour.icon;
    this.requestparamspartecipants=this.module.getParam("requestparamspartecipants",[]);

    this.id_product_tour=this.module.getParam("id_product_tour",0);
    this.record.methodpayment=this.methodpayment;

    


    if(this.currentUser.role=="guest"){
      this.record.methodpayment="stripe";
    }

    this.newAddresses();
    
    //ottieni i punti hitinero
    this.shopsService.getItemsByAddress(id_default_address).subscribe((items)=>{
      this.shops=items;
      if(this.shops.length>0){
        if(Globals.user['shop']){
          this.record.shop_start=this.shops.filter(x=>x.id==Globals.user['shop'].id)[0];
          this.record.shop_end=this.shops.filter(x=>x.id==Globals.user['shop'].id)[0];
        }
        this.getTours()

         //se è stato scelto un tour da un altro modulo
        if(this.tour_selected){
          this.type_booking=2;
          
          //crea la voce tour da inserire
          this.addTour(this.tour_selected);
          return;
          

        }
        if(this.type_booking==0)
          this.step=0;
      }

    });

    this.methodpaymentsService.getMethods().subscribe((items_m)=>{
      this.methodpayments=items_m;

      
    });
    
    //verifica se bisogna entrare in una modalità di booking specifica
    if(this.type_booking==1){
      this.step=1;
    }

    if(this.autocompileRent)
      this.goToStep2();
    
    
  }

  /** Ottiene i prodotti da noleggio */
  getRentalProducts(){
    this.bookingService.getRentalProducts(this.only_in_stock,this.show_only_product_inventory?this.record.shop_start.id_inventory:0,this.exclude_categories).subscribe((items)=>{
      this.categories=items;
     
      for(let c of this.categories){
        
        for(let i=0;i<c.products.length;i++){
          let product=new Product();
          Object.assign(product,c.products[i]);
          c.products[i]=product;
        }
      }
      
      
    });
  }

  /** ottiene l'elenco dei tour disponibili in base ai criteri scelti */
  getTours(){
    this.tours=[];
    this.tourService.getTours(this.record.date_start,this.record.adults,this.record.children,this.record.id_shop_start).subscribe((items)=>{
      
      for(let i of items){
        let tour=new Tour();
        Object.assign(tour,i);
        this.tours.push(tour);
      }
      
      console.log(this.tours);
     
    })
  }

  /** Cerca un prodotto da catalogo */
  searchProduct(){
    this.productService.getProduct(this.search).subscribe((item:Product)=>{
      if(item){
        let p:Product=new Product();
        Object.assign(p,item);
        this.addProduct(p);
       
      }else{
        Globals.message.showToaster("Nessun prodotto trovato",ToastMode.DANGER);
        
      }
      this.search="";
      this.focusSearch();
    });
  }

  /** Aggiunge un prodotto al carrello */
  addProduct(p:Product,price=null,quantity=1,onlyDescription=false,type="rental"){

    

    //cerca se il prodotto è stato già aggiunto
    for(let dp of this.record.details){
      if(dp.id_product==p.id){
        if(this.increseBookingDetail(dp,1)){
          this.addProductCorrelation(p);
        }
        
        return;
      }
    }

    let detail=new BookingDetail();
    detail.id_product=p.id;
    detail.quantity=quantity;
    detail.max_quantity=p.stockable?p.stock:999;
    
    
    detail.product=p;
    detail.id_inventory=this.record.shop_start.id_inventory;
    detail.description=p.title==""?p.brand+" "+p.name:p.title;
    detail['type']=type;

    let priceByPricelist=this.getPrice(p.tags?p.tags:"");
    if(priceByPricelist && !onlyDescription){
      detail.tax=priceByPricelist.tax?priceByPricelist.tax:Globals.parameters.get("general").getParam("default_tax",0);
      detail.price=priceByPricelist.value;
      
      if(this.priceList.typeprice==2){
        detail.price=detail.price*this.record.duration;
      }

      detail.gross=detail.price*(1+detail.tax/100);
    }else{
      detail.tax=Globals.parameters.get("general").getParam("default_tax",0);
      detail.price=0;
      detail.gross=0;
    }

    detail['onlyDescription']=onlyDescription;

    
    this.record.details.push(detail);
    this.addProductCorrelation(p);
    this.calculateTotal();
    
    
    

  }

  /** Aggiunge automaticamente i prodotti correllati al carrello */
  addProductCorrelation(p:Product){
    if(p.correlations){
      if(p.correlations.length>0){
        for(let c of p.correlations){
          let product=new Product();
          Object.assign(product,c);
          this.addProduct(product);
        }
      }
    }
  }

    /** Aggiunge un tour al carrello */
  addTour(t:Tour){
    
    this.exclude_categories=this.module.getParam("ids_categories_exclude_tour","");
         
    
    //verifica se sono stati già inseriti altri tour
    if(this.record.id_tour>0){
      Globals.message.showToaster("Non si possono inserire più di un tour",ToastMode.DANGER);
      return;
    }

    //verifica se il tour parte prima di ora
    //verifica che non è possibile prenotare prima di oggi
    var today=new Date();
    if(new Date(t.dates[0].date+" "+t.dates[0].time_start)<today){
      Globals.message.showToaster("Non è possibile effettuare una prenotazione prima di ora", ToastMode.DANGER);
      return;
    }


    //carica il prodotto "servizio tour";
    this.productService.getItem(this.id_product_tour,false).subscribe((item)=>{
      if(item){

        //imposta il record di prenotazione per il tour selezionato
        this.record.type=2; // TOUR
        this.record.id_tour=t.id;
        this.record.date_start=t.dates[0].date;
        this.record.date_end=t.dates[0].date;
        this.record.time_start=t.dates[0].time_start;
        this.record.time_end=t.dates[0].time_end;
        this.record.shop_start=this.shops.filter(x=>x.id==t.dates[0].id_shop_start)[0];
        this.record.shop_end= this.record.shop_start;
        this.record.id_shop_start=this.record.shop_start.id;
        this.record.id_shop_end=this.record.shop_end.id;
        this.record.location_start=t.dates[0].location_start;
        this.record.location_end=this.record.shop_end.name;
        
        this.record.adults=t.dates[0].adults;
        this.record.children=t.dates[0].children;
        

        //inserisci nel carrello il dettagli del tour suddiviso per adulti e bambini
        if(t.dates[0].adults>0){
          this.addProductTour(item,t,t.dates[0].adults,t.dates[0].price_adult,"adults");
        }

        if(t.dates[0].children>0){
          this.addProductTour(item,t,t.dates[0].children,t.dates[0].price_children,"children");
        }
        
        this.calculateTotal();

        this.goToStep2();



      }
    })
    
  }


  addProductTour(item,t,quantity,price,type="adults"){
    let product=new Product();
    Object.assign(product,item);




    product.name=t.title+" per il "+Helper.convertDateControl(Helper.convertString2Date(t.dates[0].date))+" ore "+t.dates[0].time_start+" ("+(type=='adults'?"adulti":"bambini")+")";
    product.brand="";
    product['tour']=t;
    product['images']=t.images;
    let detail=new BookingDetail();
    detail.id_product=this.id_product_tour;
    detail.quantity=quantity;
    detail.description=product.name;
    detail.product=product;
    detail.id_inventory=this.record.shop_start.id_inventory;
    detail.price=price;
    detail.max_quantity=t.dates[0].quantity;
    detail.tax=0;
    detail.gross=detail.price;
    this.record.details.push(detail);
      //prendi i prodotti inclusi nel tour e li inserisce nel carrello
    for(let p of t.details){
      this.productService.getItem(p.id_product,false).subscribe((item)=>{
        if(item){
          let product=new Product();
          Object.assign(product,item);
          product.parseImages();
          
          this.addProduct(product,0,p.quantity*quantity,true,"tour");
        }

        
      });
    }
  }

  /** Rimuove un prodotto dal carrello */
  removeProduct(detail){
    for(let i=0;i<this.record.details.length;i++){
      if(this.record.details[i]==detail){
        this.record.details.splice(i,1);
        this.calculateTotal();
        return;
      }
    }
  }

  removeTour(tour){
    //elimina tutti le voci correlate
    for(let i=0;i<this.record.details.length;i++){
      if(this.record.details[i]==tour){
        this.record.details.splice(i,1);
      }

      if(this.record.details[i]['type']=="tour"){
        this.record.details.splice(i,1);
      }

     


    }
    this.calculateTotal();




  }
/** Incrementa di una quantità un prodotto già presente nel carrello */
  increseBookingDetail(b:BookingDetail,delta=1){
    let result=false;
    if(b.product.stock){
      if((parseInt(b.quantity.toString())+delta)<=parseInt(b.product.stock.toString())){
        b.quantity=parseInt(b.quantity.toString())+delta;
        result=true;
      }else{
        Globals.message.showToaster("Non vi sono abbastanza prodotti in giacenza",ToastMode.DANGER);
      }
    }
      
    //elimina il record
    if((parseInt(b.quantity.toString()))==0){
      for(let i=0;i<this.record.details.length;i++){
        if(this.record.details[i].id_product==b.id_product){
          this.record.details.splice(i,1);
          this.calculateTotal();
          return false;
        }
      }
    }

    this.calculateTotal();
    return result;
  }

  /** Calcola i totali della prenotazione */
  calculateTotal(){

    this.record.amount=0;
    this.record.tax=0;
    this.record.taxWithoutDiscount=0;
    this.total_gross=0;
    this.record.discountValue=0;
    let total_net=0;
    
      for(let d of this.record.details){
        d.discount=this.record.discount; //associa lo sconto su ogni oggetto selezionato
        let net=d.price*d.quantity;
        this.record.amount= this.record.amount+net;
        this.record.discountValue=this.record.discountValue+net*(d.discount/100);
        this.record.tax=this.record.tax+net*(1-d.discount/100)*(d.tax/100);
        this.record.taxWithoutDiscount=this.record.taxWithoutDiscount+net*(d.tax/100);
        

        //verifica se le quantità massime sono state rispettate
        if(d.quantity<0)
          d.quantity=1;

        if(d.quantity>d.max_quantity)
          d.quantity=d.max_quantity;

        

      }

      this.total_gross=parseFloat((this.record.amount-this.record.discountValue+this.record.tax).toFixed(2));

      this.paid=this.total_gross;
      
      
   
  }

  /** Ottiene un prezzo di vendita da un prodotto */
  getPrice(tag){
    //trova il prezzo giusto
    if(this.priceList){
      let prices=JSON.parse(this.priceList.prices);
      this.record.typeprice=this.priceList.typeprice;
      for(let p of prices){
        if(tag.includes(p.tag)){
        
          return p;
        }
      }
    }
    return null;


  }

  /*******   PAYMENT STRIPE *********/

  confirmPayment(transaction:any){
    console.log(transaction);
  }

  errorCard(error:any){
    alert(error);
    Globals.setLoading(false);
  }

  invalidPayment(invalid:any){
    Globals.message.showToaster("Dati invalidi per il pagamento. Ricontrollare",ToastMode.WARNING);
  }

  OpenPayModal(){

    //verifica l'email del cliente (solo se guest)
    if(Globals.isLogin){
      this.payModal=true;
      return;
    }else{
      this.verifyUsername((result)=>{
        if(result){
          Globals.setLoading(false);
          this.checkpassword=true;
          return;
        }else{
         
          this.payModal=true;
  
        }
      });
    }

    
  }
  
  pay(){

    //verifica il numero di adulti e bambini per il noleggio bici
    if(this.record.id_tour==0){
    for(let d of this.record.details){
      if(d.product.tags.includes("adults"))
        this.record.adults=this.record.adults+1;
      if(d.product.tags.includes("children"))
        this.record.children=this.record.children+1;
        
    }
    }
   
   
   



    //verifica il metodo di pagamento

    if(Globals.user && Globals.user.role=="agent"){
      //this.payModal=false; //chiudi la modal del pagamento
      if(this.record.methodpayment!="omaggio"){
        this.confirmPaymentModal=true; //apri la modal per la conferma della ricezione del pagamento (contanti, carta, etc)
      }else{
        this.beforeBookNow();
      }
    }else{
      Globals.setLoading(true);
      this.loading_stripe=true;
      this.stripeComponent.pay();
    }


     
   


  }


  createPaymentMethod(payment_method_id){
    this.payment_method_id=payment_method_id;

    if(payment_method_id!="")
      this.confirm(payment_method_id);
    else{
      this.loading_stripe=false;
      this.clicked=false;    
    }
  }


  beforeBookNow(){
    if(this.record.methodpayment=="stripe"){
      Globals.setLoading(true);
      this.stripeComponent.pay();
    }else{
      this.bookNow();
    }
  }

  bookNow(){
    Globals.setLoading(true);

    //verifica che lo sconto non sia negativo
    for(let d of this.record.details){
      if(d.discount<0){
        d.price=d.price*(1+(-1)*d.discount/100);
        d.discount=0;
      }
    }

    //imposta la tariffa scelta
    if(this.priceList)
      this.record.id_rentalprice=this.priceList.id;
    


    //se è un tour inserisci i prodotti inclusi
    if(this.record.id_tour>0){
      for(let d of this.record['tour']['details']){
        this.addProduct(d['product'],0,d.quantity);
      }
    }



    //inserisci nel record i dati del cliente
    this.record['user']=JSON.stringify(this.record.addressItem);
    this.record['total_gross']=this.total_gross;
    this.confirmPaymentModal=false; //chiudi la modal di pagamento (se aperta)

    //verifica se la prenotazione è fatta da un agente
    let isAgent=false;
    if(Globals.isLogin && Globals.user.role=="agent")
      isAgent=true;


    this.bookingService.saveAndPay(this.record,this.payment_method_id,isAgent,(result)=>{
      if(result['error']){

        switch (result['code']){
          case 1:
            Globals.modal.showModal(InsertpasswordComponent,[{"name":"username","value":this.user.username}],(instance)=>{
              if(instance!=null){
                this.record['user']=JSON.stringify(Globals.user);
              }
            });
            return;
            break;

          case 100: //richiede autorizzazione 3DS
              this.stripeComponent.stripeService.handleCardAction(
                result['payment_intent_client_secret']
              ).subscribe((result2:any)=>{
                if(result2.error){
                  Globals.loading=false;
                  alert("Errore nella fase di acquisto. Riprovare");
                }else{
                  this.record.id=result['id_booking'];
                  this.bookingService.StripeConfirmPayment(result['id_booking'],result2.paymentIntent.id).subscribe(
                    (result3)=>{
                      this.StripeResponseServer(result3,result['id_booking']);
                    },(error)=>{
                      alert("Errore nella fase di acquisto. Riprovare");
                      Globals.loading=false;
                    });
                }
                
                
              },(error)=>{
                Globals.loading=false;
                alert("Errore nella fase di acquisto. Riprovare");
              });
            

            break;

          default:
            Globals.message.showToaster(result['message'],ToastMode.DANGER);
            break;
        }
      }
      
      else{
        
        this.paymentConfirmed(result);
       

        
      }
      
      Globals.setLoading(false);
    });
  }

  paymentConfirmed(booking){

    if(this.currentUser.role=='guest'){ //apri la modal di ringraziamento se sei utente guest
      this.openModalThankyou();
    }else{
      this['modalWindow'].close("success");
    }

    if (this.goToCheckin){ // passa direttamente al check-in
      Globals.modal.showModal(CheckinComponent,[{"name":"booking","value":booking}],(instance)=>{

      });

    }

    
    Globals.setLoading(false);

    if(this.typedocument==6 && booking['id_document']>0){
      this.documentService.getItem(booking['id_document']).subscribe((item)=>{
        Globals.modal.showModal(ReceiptComponent,[
          {"name":"mode","value":"modal"},
          {"name":"document","value":item}
        ],(instance)=>{
          if(!instance){
            //imposta la prenotazione "da pagare"
            this.record.state=1;
            this.bookingService.save(this.record,()=>{

            });

          }
        })
      });
    }

    


  }

  StripeResponseServer(result:any,id_booking){
   
    if(result['success']){
      this.paymentConfirmed(result['booking']);
      
    }else if(result['requires_action']){

      this.stripeComponent.stripeService.handleCardAction(
        result['payment_intent_client_secret']
      ).subscribe((result2:any)=>{

        this.bookingService.StripeConfirmPayment(id_booking,result2.paymentIntent.id).subscribe((result3)=>{
          this.StripeResponseServer(result3,id_booking);
        },(error)=>{
          alert("Errore nella fase di acquisto. Riprovare");
          Globals.loading=false;
        });
      },(error)=>{
        Globals.loading=false;
      });


      
    }else{
      alert("Errore nella fase di acquisto");
     
    }
  }

   /*******   END PAYMENT STRIPE *********/


    /*******  VERIFY USERNAME AND REGISTRATION *********/


  verifyUsername(complete=null,username=""){

    if(username==""){
      this.access_username=this.record.addressItem['username'];
    }else{
      this.access_username=username;
    }



    if(this.access_username=="" || this.access_username==undefined)
      return;
    
    if(!Globals.isLogin || this.currentUser.role!="guest"){
      this.registrationService.verifyUsername(this.access_username).subscribe((result)=>{
        
        this.isUsernameUsed=result;
        if(complete)
          complete(result);
        else
          if(this.currentUser.role=="guest")
            this.openModalPassword();
          else
            this.setAddressItem(result['id']);
      });
    } else {
      if(complete)
       complete(false);
    }


  }

  findUsername(){
    if(Globals.user && Globals.user.role=="agent"){
      //verifica se l'email è già registrata
      this.verifyUsername((result)=>{
        if(result){
          this.setAddressItem(result['id']);
        }
      });
    }
  }

  onAfterVerificationPassword(){
    if(Globals.isLogin){
      //verifica se l'utente non è un agente
      if(Globals.user.role=="agent"){
        this.user_agent=Globals.user;
        this.user=new User();
        this.record.addressItem['username']="";
        this.step=6;
      }else{
        this.user=Globals.user;
        this.setAddressItem(this.user.id);
        this.step=7;

      }

      

    }
    this.checkpassword=false;
  
  }

  setAddressItem(id_user){
     //prendi i dati del cliente
     this.addressesService.getAddressByUser(id_user).subscribe((item)=>{
      if(item){
        //const username=this.record.addressItem['username'];
        this.record.addressItem=item;
        //this.record.addressItem['username']=username;
        this.record.id_address=this.record.addressItem.id;
      }
    });
  }

  login(){

    if(this.record.addressItem['username']=="" || this.record.addressItem['username']==undefined){
      Globals.message.showToaster("Indica il tuo indirizzo email che hai usato in fase di registrazione.",ToastMode.WARNING);
      return;
    }


    this.verifyUsername((result)=>{
      if(result){ //se è presente l'utente apri l'inserimento password
        Globals.setLoading(false);
        this.checkpassword=true;
      }else{
        Globals.message.showToaster("Il tuo indirizzo email non è presente nei nostri archivi. Continua la procedura d'acquisto per essere registrato automaticamente.",ToastMode.WARNING);
      }
    });

    
  }

  closeLogin(){
    this.checkpassword=false;
  }

  logout(){
    Globals.access.logout(false);
    this.record.id_address=0;
    this.record.addressItem=new Address();
  }

  
  /** Ricerca l'anagrafica per codice fiscale o partita iva */
  searchAddress(text:string){
    if(text=="" || text==null || text==undefined)
      return;
  
    if(this.record.id_address>0) //non ricercare se l'utente è stato già trovato
      return;

    this.addressesService.searchByFiscal(text).subscribe((items)=>{
      
     
      if(items && items.length>0){



        


        if(this.currentUser.role=='guest'){

          for(let item of items){ //cicla tutte le anagrafiche e considera solo quella che ha una username
            if(item.username!="" && item.username!=null && item.username!=undefined){
              let a:Address=new Address();
              Object.assign(a,item);
              
              this.verifyUsername(null,a.username);
              return;
            }
          }

        }else{

          let a:Address=new Address();
          Object.assign(a,items[0]);

          this.record.addressItem=a;
          this.record.id_address=a.id;
          a.findUsername();
          this.record.addressItem.username=a.username;
        }


      }else{
        Globals.message.showToaster("Nessun cliente trovato. Inserire i dati per crearne uno nuovo",ToastMode.DANGER);
      }
    })

  }
  searchAddresses(){
    Globals.modal.showModal(AddressComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance){
        this.handleSelectAddress(instance['recordSelected']);

      }
    });
  }

  handleSelectAddress(address){
    this.record.addressItem=address;
        this.record.id_address=this.record.addressItem.id;

        for(let c of this.record.addressItem.contacts){
          if(c.id_type==7){
            this.record.addressItem['username']=c.value;
          }

          if(c.id_type==6){
            this.record.addressItem['phone']=c.value;
          }
        }
  }

  editAddress(){
    Globals.modal.showModal(AddressDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":this.record.id_address},
      
    ],(instance)=>{
      if(instance){
        this.handleSelectAddress(instance['record']);
      }
    })
  }


  newAddresses(){
    this.record.addressItem=new Address();
    this.record.id_address=this.id_address;

    if(this.record.id_address>0){
      this.addressesService.getItem(this.record.id_address).subscribe((item)=>{
        Object.assign(this.record.addressItem,item);

        //ricerca la username
        this.record.addressItem.findUsername();
        this.record.username=this.record.addressItem.username;

      })
    }

  }

  resetAddress(){
    this.record.addressItem=new Address();
    this.record.id_address=0;

  }


/*******  END VERIFY USERNAME AND REGISTRATION *********/



  /** Ottiene l'elenco dei prezzi di noleggio a listino */
  getPriceListRental(oncomplete){
    
    //prendi il listino prezzi del luobo di partenza
    this.bookingService.getPricelistRental(this.record.id_shop_start).subscribe((items)=>{
      let allPriceList=[];
      for(let i of items){
        let rp:RentalPrice =new RentalPrice();
        Object.assign(rp,i);
        rp.parseImage();
        allPriceList.push(rp);
      }

      oncomplete(this.handleSetPriceList(allPriceList,this.priceList));
    });
  }

  /*** Definisce quale listino prezzi utilizzare in base ai parametri di ricerca */
  handleSetPriceList(list,defaultPriceList=null){

    var time_start = this.toDate(this.record.time_start,"h:m").getTime();
    var time_end = this.toDate(this.record.time_end,"h:m").getTime();

    let diffHrs =this.record.durationHours;
    //let diffDays = Math.floor(diffMs / 86400000); // days
    
    //let today=new Date(Helper.convertDateControl());

    //se la pricelist è stata già definita
    if(defaultPriceList){
      for(let p of list){
        if(p.id==defaultPriceList.id){
          return p;
        }
      }
    }else{ //..altrimenti ricercala
      //verifica in quale condizioni ci troviamo
      for(let p of list){
        let ds=new Date(this.record.date_start);
        let ps=new Date(p.published_start);
        let pe=new Date(p.published_end);

        //verifica che il listino prezzi si può applicare in questo periodo
        if(ds>=ps &&  ds<= pe){
          
          if(p.type!=this.typeBikeRental) //escludi se ti trovi in una modalità diversa da quella selezionata (a tempo o a consumo)
            continue;
          //verifica che tipo di controllo effettuare
          switch(p.type){ 
            case 1: //controllo sugli orari
              //verifica se ti trovi nell'orario giusto

              var ptime_start=this.toDate(p.time_start,"h:m").getTime();
              var ptime_end=this.toDate(p.time_end,"h:m").getTime();

              if(time_start>=ptime_start && time_end<=ptime_end){
                if(diffHrs>=p.duration && (diffHrs<=p.max_duration || p.max_duration==0)){ //verifica se è garantita la durata minima
                  if((this.record.adults+this.record.children)>=p.min_partecipants){ //verifica se il numero di parteci panti supera il minimo
                    if(this.record.adults>=p.min_adults){ //verifica se il numero minimo di adulti è sufficiente
                      if(this.record.children>=p.min_children){ //verifica se il numero minimo di bambini è sufficiente
                        
                        return p; //applica questo listino
                      }
                    }
                  }
                }
              }
              break;
            case 2: //controllo sulla durata
              if(diffHrs>=p.duration && (diffHrs<=p.max_duration || p.max_duration==0)){ //verifica se è garantita la durata minima
                if((this.record.adults+this.record.children)>=p.min_partecipants){ //verifica se il numero di parteci panti supera il minimo
                  if(this.record.adults>=p.min_adults){ //verifica se il numero minimo di adulti è sufficiente
                    if(this.record.children>=p.min_children){ //verifica se il numero minimo di bambini è sufficiente
                      return p; //applica questo listino
                      
                    }
                  }
                }
              }
              break;

          }
        
        }
      }
    }

    return this.defaultPriceList;

    
  }


  checkProductByTag(product){
    //verifica se il prodotto appartiene ad una delle voci del listino (confronto tra tag)
    if(this.priceList){
      let prices=JSON.parse(this.priceList.prices);
      for(let pl of prices){
        if(product.tags.indexOf(pl.tag)>-1){
          return true;
        }
      }
    }

    return false;

  }


  toDate(dStr,format) {
    var now = new Date();
    if (format == "h:m") {

      let t=dStr.split(":");

       now.setHours(t[0]);
       now.setMinutes(t[1]);
       now.setSeconds(0);
       now.setMilliseconds(0);
       return now;
    }else 
      return null;
  }


  onChangeAmount(event,detail){
    //calcola lo sconto
    const currentGross=parseFloat(event.target.value);
    
    detail.price=(currentGross/detail.quantity)/(1+detail.tax/100);
    
    this.calculateTotal();
    
  }

  calculateTourEvent(d){
    if(d.price_children==0)
      d.price_children=d.price_adult;
   let total= parseFloat(d.price_adult)*parseInt(this.record.adults.toString())+parseFloat(d.price_children)*parseInt(this.record.children.toString());
    return total;
  }


  onChangeShopStart(){
    this.record.shop_end=this.record.shop_start;
  }

  onChangeDateStart(){
    this.record.date_end=this.record.date_start;
    this.getTours();
  }
  

  printInvoice(){
    this.bookingService.printInvoice(this.record.id).subscribe((uri_template)=>{
      window.open(uri_template,"_blank");
    })
  }



  checkPriceList(){
    this.priceList=this.handleSetPriceList(this.allPriceList);
    if(this.priceList!=this.defaultPriceList){
      
      this.modalPricelistSelected=true;
      setTimeout(()=>{
        this.modalPricelistSelected=false;
      },3000)
    }
    if(this.priceList==null)
      this.priceList=this.defaultPriceList;
    this.pricelistSelected.emit(this.priceList);

  }


  verifyTaxCode():boolean{
    let v:boolean=false;
    this.tax_code_error="";
    if(this.record.addressItem.tax_code!=undefined){
      v=this.verifyFiscalCode.validate(this.record.addressItem.tax_code);
      if(!v)
        this.tax_code_error=this.verifyFiscalCode.error_message;
    }
    return v;
  }

  verifyVATNumber():boolean{
    let v:boolean=false;
    this.vat_number_error="";
    if(this.record.addressItem.vat_number!=undefined){
      v=this.verifyVATNumberService.validate(this.record.addressItem.vat_number);
      if(!v)
        this.vat_number_error=this.verifyVATNumberService.error_message;
    }
    return v;
  }




  onChangeNation(nation){
    this.record.addressItem.nation=nation;
    if(nation!='IT'){
      this.foreign=true;
      this.record.addressItem.country="EE";
      this.record.addressItem.zip="00000";
      this.record.addressItem.sdi="XXXXXXX";
      this.record.addressItem.vat_number="XXXXXXXXXXX";
      this.record.addressItem.tax_code="XXXXXXXXXXX";
    }else{
      this.foreign=false;
      this.record.addressItem.country="";
      this.record.addressItem.zip="";
      this.record.addressItem.sdi="0000000";
      this.record.addressItem.vat_number="";
      this.record.addressItem.tax_code="";
    }
  }


  confirm(payment_method_id=""){
    this.record['typedocument']=this.typedocument;

    this.record['total_gross']=this.paid; //importo realmente pagato
    this.bookingService.saveAndPay(this.record,payment_method_id,false,(result)=>{
      
      if(result['error']){

        switch (result['code']){
          case 1:
            Globals.modal.showModal(InsertpasswordComponent,[{"name":"username","value":this.user.username}],(instance)=>{
              if(instance!=null){
                this.record['user']=JSON.stringify(Globals.user);
              }
            });
            return;
            break;

          case 100: //richiede autorizzazione 3DS
              this.stripeComponent.stripeService.handleCardAction(
                result['payment_intent_client_secret']
              ).subscribe((result2:any)=>{
                if(result2.error){
                  Globals.loading=false;
                  alert("Errore nella fase di acquisto. Riprovare");
                  this.deleteBooking(result2['id_booking']);
                }else{
                  this.record.id=result['id_booking'];
                  this.bookingService.StripeConfirmPayment(result['id_booking'],result2.paymentIntent.id).subscribe(
                    (result3)=>{
                      this.StripeResponseServer(result3,result['id_booking']);
                    },(error)=>{
                      alert("Errore nella fase di acquisto. Riprovare");
                      Globals.loading=false;
                      this.deleteBooking(result['id_booking']);
                    });
                }
                
                
              },(error)=>{
                Globals.loading=false;
                alert("Errore nella fase di acquisto. Riprovare");
                this.deleteBooking(result['id_booking']);
              });
            

            break;

          default:
            Globals.message.showToaster(result['message'],ToastMode.DANGER);
            break;
        }
      }else{
        this.paymentConfirmed(result)
      }


    });
  }

  deleteBooking(id_booking){
    this.bookingService.delete([id_booking]).subscribe(()=>{

    });
  }
  
  closeWindow(){
    if(this['modalWindow'])
      this['modalWindow'].close('success');
  }

  

  focusSearch(){
    this.searchCode.nativeElement.focus();
  }

  goToStep1(isPrev=false){
      if(this.type_booking==1){
        this.step=1;
      }
      if(this.type_booking==2){
        this.step=99;
      }
    


  }

  goToStep2(){
    this.getRentalProducts();
    if(this.type_booking==1){
      this.getPriceListRental(
        (item)=>{

        
        

            this.priceList=item;
            
            this.record.confirmDetail={};
            let passengers={};
            passengers['passengers']=[];

            //imposta i partecipanti
            for(let i=0;i<(this.record.adults+this.record.children);i++){
              let p={};
              p['label']="";
              p['value']="";
              p['id']=0;
              p['images']=[];
              p['requestparams']={};
              
              //imposta i parametri
              for(let rpp of this.requestparamspartecipants){
                p['requestparams'][rpp.name]="";
              }


              passengers['passengers'].push(p);

            

            this.record.confirmDetail=passengers;
          
          }

          if(item==null){ //se non è stata trovata nessuna tariffa apri una modale per una tariffa personalizzata
            this.modalNoPricelistSelectedRef=this.modalService.open(this.modalNoPricelistSelected);
          }else{
            this.record.id_rentalprice=item.id;
            this.step=2;
          }


          setTimeout(()=>{
            this.focusSearch();
          },500);
        
        }
      );
    }
    if(this.type_booking==2){
      this.step=2;
    }
  }


  goToStep3(isPrev=false){

      if(isPrev){
        if(this.currentUser.role=="guest"){
          this.step=2;
        }else{
          this.step=3;
        }
      }else{
        //se l'utente è guest vai direttamente alla registrazione utente
        // if(this.currentUser.role=="guest"){
        //   this.step=4;
        // }else{
          this.step=3;

          this.record.confirmDetail={};
          let passengers={};
          passengers['passengers']=[];

          //imposta i partecipanti
          for(let i=0;i<(this.record.adults+this.record.children);i++){
            let p={};
            p['label']="";
            p['value']="";
            p['id']=0;
            p['images']=[];
            p['requestparams']={};
          
            //imposta i parametri
            for(let rpp of this.requestparamspartecipants){
              p['requestparams'][rpp.name]="";
            }
            passengers['passengers'].push(p);

          // }

          this.record.confirmDetail=passengers;
        }
      }

  }

  goToStep5(){
    
      //verifica se l'utente è gia registrato o meno
      this.verifyUsername((result)=>{
        if(result){

          if(this.currentUser.isGuest()){
            this.openModalPassword();
          }else{
            if(this.record.id_address==0)
              this.openModalUserExist();
            else
              this.step=5;
          }

            
        }else{
          this.step=5;
        }
      });

   
  }

  openTours(){
    
  }



  handleFileInput(files: FileList) {
    Globals.setLoading(true);
    this.fileToUpload = files.item(0);
    this.uploadService.uploadFile(this.fileToUpload,"personalid",(result)=>{

      let i:Image=new Image();
      i.file=result;
      this.passengerSelected.images.push(i);
      Globals.setLoading(false);
     
    },true);
}


takephoto(passenger:any){
  Globals.modal.showModal(TakephotoComponent,[],(instance)=>{
    if(instance!=null){
      
      this.uploadService.uploadImage64(instance.imageCaptured,"personalid",(result)=>{
        let i:Image=new Image();
        i.file=result;
        
        passenger.images.push(i);
      },true);
    }
  }); 
}




  openModalPassword(){
    this.modalInsertPasswordRef=this.modalService.open(this.modalInsertPassword);
  }

  closeModalPassword(){
    this.modalInsertPasswordRef.close("success");
  }

  access(){
    //verifica se le credenziali siano corrette
    Globals.access.login(this.access_username,this.password,(islogin)=>{
      
      if(islogin){
        this.registrationService.verifyUsername(this.access_username).subscribe((result)=>{
          if(result){
            this.setAddressItem(result['id']);
            this.closeModalPassword();
          }
        });


        
      }else{
        
      }
    });

    
  }

  resendPassword(){
    this.registrationService.recoverPassword(this.access_username).subscribe((result)=>{
      if(result){
        Globals.message.showToaster("È stata inviata sulla email "+this.access_username+" le tue credenziali d'accesso",ToastMode.SUCCESS);
  
      }else{
        Globals.message.showToaster("Errore nell'invio della email con il riepilogo delle  tue credenziali d'accesso",ToastMode.DANGER);
  
      }
    })
  }

  openModalUserExist(){
    this.modalUserExistRef=this.modalService.open(this.modalUserExist);
  }

  closeModalUserExist(){
    this.modalUserExistRef.close("success");
  }

  openModalOtherMetodPayments(){
    this.modalOtherMetodPaymentsRef=this.modalService.open(this.modalOtherMetodPayments);
   }

  openModalRentalDetails(){
    this.modalRentalDetailsRef=this.modalService.open(this.modalRentalDetails);
   }

   openModalProductDetails(product){
    this.productSelected=product;
    this.modalProductDetailsRef=this.modalService.open(this.modalProductDetails);
   }

   openModalTourDetails(tour){
     this.tourSelected=tour;
    this.modalTourDetailsRef=this.modalService.open(this.modalTourDetails);
   }

  openModalThankyou(){
    this.modalThankyouRef=this.modalService.open(this.modalThankyou);
  }

  openModalCondition(){
    this.modalConditionsRef=this.modalService.open(this.modalConditions);
   }

  // closeModalThankyou(){
  //   this.modalThankyouRef.close("success");
  //   this['modalWindow'].close("success");
  // }

  removeImage(passenger:any,image:Image){
    for(let i of passenger.images){
      if(i.file==image.file){
        passenger.images.splice(i,1);
        return;
      }

    }
  }

}
