import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Observable } from 'rxjs';
import { RentalPrice } from '../db/rentalprice';

@Injectable({
  providedIn: 'root'
})
export class RentalpriceService extends WSService<RentalPrice> {

  constructor() {super("rentalprices") }

  getOffers():Observable<any[]>{
    return Globals.ws.requestUrl<any[]>("rentalprices","getOffers",[]);
  }
 
}
