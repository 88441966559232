<ul class="list-group">
    <ng-container *ngTemplateOutlet="node;context: {$implicit:tree}"></ng-container>
</ul>
<ng-template #node let-nodetree>
    <ng-container *ngFor="let t of nodetree">           
        <ng-container *ngIf="t.children.length>0;else elseBlock">
            <li [class.active]="t.selected" class="list-group-item pointer"  (click)="select(t)">{{t.name}} ({{t.children.length}})</li>
            <ul class="list-group pl-2">
                <ng-container *ngTemplateOutlet="node;context: {$implicit:t.children} "></ng-container>
            </ul>
        </ng-container>        
        <ng-template #elseBlock>
            <li [class.active]="t.selected" class="list-group-item pointer" (click)="select(t)"><span *ngIf="t.id_parent && t.id_parent!=0">&#10551; </span>{{t.name}}</li>
        </ng-template>
    </ng-container>
</ng-template>