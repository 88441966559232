import { NgModule } from '@angular/core';
import { CitiesComponent } from './cities.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';


@NgModule({
    imports: [FormsModule,CommonModule,ModalModule],
   exports: [CitiesComponent],
   declarations: [CitiesComponent],
   providers: [],
})
export class CitiesModule{ }