import { NgModule } from '@angular/core';
import { AdvancedsearchComponent } from './advancedsearch.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';


@NgModule({
    imports: [FormsModule,CommonModule,ModalModule],
   exports: [AdvancedsearchComponent],
   declarations: [AdvancedsearchComponent],
   providers: [],
})
export class AdvancedsearchModule{ }