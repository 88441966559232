import { Injectable } from '@angular/core';
import {CustomField} from '../include/structures';
import {WSService} from '../include/service'
import { Observable, Observer } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class CustomfieldsService extends WSService<CustomField> {

  static customfields=[];

  constructor() {super("customfields") }

  getCustomFields(table:string,excludeSeparator:boolean=false):Observable<CustomField[]>{
    
    let customfields=[];
    let cfound=false;
    for(let t of CustomfieldsService.customfields ){
      if(t.table==table){
        customfields=t.customfields;
        cfound=true;
      }
    }

    if(!cfound || excludeSeparator){
      Globals.ws.requestUrl<CustomField[]>("customfields","getItems",[(table!=""?"c.table='"+table+"' AND ":"")  +"c.status=1"+(excludeSeparator==true?" AND c.type!='separator'":""),"ordering"],true,0,1000).subscribe((items:any)=>{
        if(!excludeSeparator){
          let found=false;
          for(let i=0;i<CustomfieldsService.customfields.length;i++ ){
            if(CustomfieldsService.customfields[i].table==table){
              CustomfieldsService.customfields[i].customfields=items;
              found=true;
            }
          }

          if(!found){
            let t:any={};
            t.table=table;
            t.customfields=items;
            CustomfieldsService.customfields.push(t);

          }
        }
        
        
      });
      return Globals.ws.requestUrl<CustomField[]>("customfields","getItems",[(table!=""?"c.table='"+table+"' AND ":"")  +"c.status=1"+(excludeSeparator==true?" AND c.type!='separator'":""),"ordering"],true,0,1000);

    }else{

      function sequenceSubscriber(observer: Observer<any[]>) {

        for(let i=0;i<CustomfieldsService.customfields.length;i++ ){
          if(CustomfieldsService.customfields[i].table==table){
            observer.next(CustomfieldsService.customfields[i].customfields);
          }
        }
       
        observer.complete();
        return {unsubscribe() {}};
      }
      
        return new Observable(sequenceSubscriber);
    }

   // return  Globals.ws.requestUrl<CustomField[]>("customfields","getItems",[(table!=""?"c.table='"+table+"' AND ":"")  +"c.status=1"+(excludeSeparator==true?" AND c.type!='separator'":""),"ordering"],true,0,1000);
    
  }

  delete(ids: string[]): Observable<CustomField> {
    CustomfieldsService.customfields=[];
    return super.delete(ids);
}

save(item: CustomField, return_id: Function, checkdeadlinesandactions?: boolean, showAfterMessage?: boolean): void {
  CustomfieldsService.customfields=[];  
    return super.save(item,return_id,checkdeadlinesandactions,showAfterMessage);
}

  importCSV(raw_csv,parsing,delimiter_csv,oncomplete){
    Globals.ws.send<any>("customfields","importCSV",{"raw":raw_csv},[parsing,delimiter_csv],oncomplete);
    
  }

  resetAllValueCustomField(id_customfield):Observable<any>{
    return  Globals.ws.requestUrl<any>("customfields","resetAllValueCustomField",[id_customfield]);
    
  }

  
}

